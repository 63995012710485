const UPLOAD_ABORTERS = {};

class UnableToFetchStoredVideo extends Error {}

class Uploader {
  static cancel(url) {
    const aborter = UPLOAD_ABORTERS[url];
    if (aborter) {
      aborter.abort();
      aborter.wasAborted = true;
    }
  }

  static wasCanceled(url) {
    return Boolean(UPLOAD_ABORTERS[url] && UPLOAD_ABORTERS[url].wasAborted);
  }

  // Elm can't consume Blob, so we need to support ObjectUrl strings: "blob:http://localhost:5050/4998f0ed-452f-4d09-85e2-f343c48ea24b"
  // We can easily convert these back to a real Blob in JS.
  static toBlob(uploadable) {
    if (typeof uploadable !== "string") {
      // Already a blob!
      return Promise.resolve(uploadable);
    }

    if (uploadable.startsWith("blob:")) {
      return fetch(uploadable)
        .then(resp => resp.blob())
        .catch(err => {
          if (err.message === "Failed to fetch") {
            throw new UnableToFetchStoredVideo();
          } else {
            throw err;
          }
        });
    }

    return Promise.resolve(uploadable);
  }

  static upload(url, uploadable) {
    if (url.match(/fake%20video%20279.mov/) && window.environment === "development") {
      console.log("In development environment without upload configuration, not uploading");
      // immediately resolve as if the video upload completed
      return Promise.resolve();
    }

    const startTime = Date.now();

    return Uploader.toBlob(uploadable).then(blob => {
      global.logger.eventReceived(
        {
          message: "Web Video Upload Started",
          data: {
            uploadSize: blob.size
          }
        },
        "javascript"
      );

      // Request to upload to the same URL -- probably a dupe so let's automatically cancel
      Uploader.cancel(url);

      const aborter = new AbortController();
      UPLOAD_ABORTERS[url] = aborter;

      return fetch(url, {
        signal: UPLOAD_ABORTERS[url].signal,
        body: blob,
        headers: {
          "content-type": blob.type
        },
        method: "PUT"
      }).then(response => {
        if (response.ok) {
          console.log("[Video Recording] Finished video upload");
          global.logger.eventReceived(
            {
              message: "Web Video Upload Completed",
              data: {
                uploadSize: blob.size,
                durationInSeconds: (Date.now() - startTime) / 1000
              }
            },
            "javascript"
          );
          return response.text();
        } else {
          console.log("Got a failure! ", response);
          return response.text().then(body => {
            throw new Error(`Upload error! Status: ${response.status}, body: ${body}`);
          });
        }
      });
    });
  }
}

Uploader.UnableToFetchStoredVideo = UnableToFetchStoredVideo;

export default Uploader;

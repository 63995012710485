import Sentry from "javascript/Sentry/Wrapper";
import Loggable from "javascript/Loggable";
import * as Helpers from "javascript/Learnosity/helpers";

export default class LearnosityElmPorts extends Loggable {
  constructor({ ports, app, onExitQuiz }) {
    super("learnosity - elm ports");

    this.ports = ports;
    this.app = app;
    this.onExitQuiz = onExitQuiz;
  }

  subscribe = () => {
    this.ports.gotoLearnosityQuestion.subscribe(this.goToQuestionHandler);
    this.ports.validateLearnosityAnswerV2.subscribe(this.validateQuestionHandler);
    this.ports.retryLearnosityQuestionV2.subscribe(this.retryQuestionHandler);
    this.ports.advanceToNextLearnosityQuestionV2.subscribe(this.goToNextQuestionHandler);
    this.ports.submitLearnosityAssessmentV2.subscribe(this.submitAssessmentHandler);
    this.ports.exitQuizV2.subscribe(this.exitQuizHandler);
  };

  unsubscribe = () => {
    if (
      window.LearnosityItems &&
      window.LearnosityItems.reset &&
      typeof window.LearnosityItems.reset === "function"
    ) {
      try {
        window.LearnosityItems.reset();
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            component: "Learnosity",
            caught: "true"
          }
        });
      }
    }

    this.ports.gotoLearnosityQuestion.unsubscribe(this.goToQuestionHandler);
    this.ports.validateLearnosityAnswerV2.unsubscribe(this.validateQuestionHandler);
    this.ports.retryLearnosityQuestionV2.unsubscribe(this.retryQuestionHandler);
    this.ports.advanceToNextLearnosityQuestionV2.unsubscribe(this.goToNextQuestionHandler);
    this.ports.submitLearnosityAssessmentV2.unsubscribe(this.submitAssessmentHandler);
    this.ports.exitQuizV2.unsubscribe(this.exitQuizHandler);
  };

  // V1
  goToQuestionHandler = index => this.app.items().goto(index);

  validateQuestionHandler = currentQuestionRef => {
    let question = Helpers.questionByRef(this.app, currentQuestionRef);

    if (question === null || typeof question == "undefined") {
      question = Helpers.currentQuestion(this.app);
    }

    Helpers.setResponseValidationStateValidated(question);
    Helpers.showQuizQuestionResults(question);
  };

  retryQuestionHandler = () => {
    const question = Helpers.currentQuestion(this.app);

    question.resetValidationUI();
    Helpers.setResponseValidationStateUnanswered(question);
    question.enable();
  };

  goToNextQuestionHandler = () => this.app.items().next();

  submitAssessmentHandler = () => {
    this.app.submit({
      success: ids => this.log("Learnosity assessment submitted successfully!!!!!!", { ids }),
      error: error => this.log("Error with submission", { error })
    });
  };

  exitQuizHandler = () => {
    if (this.onExitQuiz) this.onExitQuiz();

    this.unsubscribe();
  };
}

import Loggable from "javascript/Loggable";
import * as WindowScroll from "javascript/WindowScroll";
import * as Helpers from "javascript/Learnosity/helpers";
import * as c from "javascript/Learnosity/util/constants";
import { elemExists } from "javascript/util/dom";
import { stopIfPlaying } from "./assessmentTTS";

export default class LearnosityEvents extends Loggable {
  constructor(app) {
    super("learnosity - events");

    this.app = app;
    this.listen = this.listen.bind(this);
  }

  listen({ ports, scrollAndResizeEventHandler, studentActivityId, ttsClickHandler }) {
    this.app.on("item:load", this.createOnItemLoadHandler(ports, scrollAndResizeEventHandler));
    this.app.on("item:setAttemptedResponse", this.createOnItemSetAttemptedResponseHandler());
    this.app.on("item:changed", this.createOnItemChangedHandler());
    this.app.on("item:unload", this.createonItemUnloadHandler(scrollAndResizeEventHandler));
    this.app.on("test:save:success", this.createOnTestSaveSuccessHandler());
    this.app.on("test:save:error", this.createOnTestSaveErrorHandler());
    this.app.on("test:submit:success", this.createOnTestSubmitSuccessHandler(scrollAndResizeEventHandler)); // prettier-ignore
    this.app.on("test:submit:error", this.createOnTestSubmitErrorHandler({ ports, studentActivityId, scrollAndResizeEventHandler })); // prettier-ignore

    ifAppStillExists(this.app, app => {
      const questions = Object.values(app.questions());
      questions.forEach(question => {
        question.on("change", this.createOnQuestionChangeHandler.bind(question)(app));
        question.on("changed", this.createOnQuestionChangedHandler.bind(question)(app, ports));
        question.on(
          "validated",
          this.createOnQuestionValidatedHandler.bind(question)(app, ttsClickHandler)
        );
      });
    });
  }

  createOnItemLoadHandler = (ports, scrollAndResizeEventHandler) => () => {
    Helpers.synchronizeLearnosityQuizStateToElm(this.app, ports);
    WindowScroll.addEventListeners(scrollAndResizeEventHandler);
    Helpers.addClickListenerForDragAndDropElements();
    window.requestAnimationFrame(() => {
      Helpers.loadQuizQuestionResultsIfAnswered(this.app);
      window.requestAnimationFrame(() => {
        Helpers.adjustLearnosityButtonHeight(this.app.getCurrentItem);
        Helpers.hideQuizLoader();
        Helpers.formatMathMathematicalExpressionsInAssessment();
      });
    });
  };

  createOnItemSetAttemptedResponseHandler = () => () => {
    window.requestAnimationFrame(() => {
      Helpers.adjustLearnosityButtonHeight(this.app.getCurrentItem);
    });
  };

  createOnItemChangedHandler = () => () => {
    Helpers.formatMathMathematicalExpressionsInAssessment(); // do we need this one?
  };

  createonItemUnloadHandler = scrollAndResizeEventHandler => () => {
    WindowScroll.removeEventListeners(scrollAndResizeEventHandler);
    Helpers.removeClickListenerForDragAndDropElements();
  };

  createOnTestSaveSuccessHandler = () => () => {
    Helpers.formatMathMathematicalExpressionsInAssessment();
    Helpers.adjustLearnosityButtonHeight(this.app.getCurrentItem);
  };

  createOnTestSaveErrorHandler = () => error => {
    this.log("Error while saving assessment.", "error", ...error);
  };

  createOnTestSubmitSuccessHandler = scrollAndResizeEventHandler => () => {
    WindowScroll.addEventListeners(scrollAndResizeEventHandler);
  };

  createOnTestSubmitErrorHandler = ({
    ports,
    studentActivityId,
    scrollAndResizeEventHandler
  }) => error => {
    WindowScroll.removeEventListeners(scrollAndResizeEventHandler);
    ports.learnosityErrorOnResubmissionV2.send(null);
    global.logger.eventReceived({
      message: "Learnosity submit error",
      err: error,
      student_activity_id: studentActivityId
    });
    this.log("Error while submittingn assessment.", "error", ...error);
  };

  createOnQuestionChangeHandler(app) {
    return () => {
      window.requestAnimationFrame(() => {
        Helpers.adjustLearnosityButtonHeight(app.getCurrentItem);
      });
    };
  }

  createOnQuestionChangedHandler(app, ports) {
    return () => {
      Helpers.handleQuestionStateChanged(this, app, ports);
      window.requestAnimationFrame(() => {
        Helpers.adjustLearnosityButtonHeight(app.getCurrentItem);
      });
    };
  }

  createOnQuestionValidatedHandler(app, ttsClickHandler) {
    return () => {
      Helpers.formatMathMathematicalExpressionsInAssessment();
      // you'll get a error notice (won't break but console errors) from learnosity
      // if you try to save an assessment that is in review (vs. initial or resume)
      if (Helpers.assessmentState(app) !== "review") {
        app.save();
      }
      window.requestAnimationFrame(() => {
        const rationaleElement = document.getElementById(c.ID.DISTRACTOR_RATIONALE);
        if (elemExists(rationaleElement)) {
          const tts = ttsClickHandler(rationaleElement);

          // TTS call below is for the rationale when it scrolls into view after MathJax is formatted
          document.addEventListener(
            "mathJaxFormatEnded",
            () => {
              WindowScroll.scrollContainerTo(rationaleElement.offsetTop);
              // autoplay text on load
              const rationaleElementTextWrapper = document.querySelector(
                `${c.RATIONALE_TEXT_SELECTOR} span`
              );
              if (Helpers.rationaleAudioEnabled() && elemExists(rationaleElementTextWrapper)) {
                let rationaleTextToReadAloud = Helpers.getTextContentExcludingTTSIgnore(
                  rationaleElementTextWrapper
                );
                rationaleTextToReadAloud = global.MathJax
                  ? Helpers.sanitizeLaTex(rationaleTextToReadAloud)
                  : rationaleTextToReadAloud;
                stopIfPlaying(tts);
                window.requestAnimationFrame(() => {
                  if (!tts.isCurrentlyPlaying) {
                    rationaleElement.classList.add(c.TTS_ACTIVE_CLASS);
                    tts.speak({ text: rationaleTextToReadAloud }).then(() => {
                      rationaleElement.classList.remove(c.TTS_ACTIVE_CLASS);
                    });
                  }
                });
              }
            },
            {
              once: true,
              passive: true
            }
          );
        }
      });
    };
  }
}

const ifAppStillExists = (app, callback) => {
  if (app.getCurrentItem && typeof app.getCurrentItem === "function" && app.getCurrentItem()) {
    callback(app);
  }
};

import loadjs from "loadjs";
import Sentry from "javascript/Sentry/Wrapper";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

export default function setupDeviceCheckPorts(ports) {
  ports.performAndLogDeviceCheck.subscribe(() => {
    const studentSessionData = Boolean(window.localStorage.getItem("v5-session-debug-data"))
      ? JSON.parse(window.localStorage.getItem("v5-session-debug-data"))
      : {};
    const studentInfo = studentSessionData.hasOwnProperty("user") ? studentSessionData.user : null;
    performEsparkResourceCheck(studentInfo);
    performSystemCheck(studentInfo);
  });
}

let apiAccessUrls = {
  core: "https://mc.esparklearning.com",
  client: "https://student.esparklearning.com"
};

if (window.location.hostname === "student-wip.esparklearning.com") {
  apiAccessUrls.core = "https://wip.esparklearning.com";
  apiAccessUrls.client = "https://student-wip.esparklearning.com";
} else if (window.location.hostname === "localhost") {
  apiAccessUrls.core = "http://localhost:3000";
  apiAccessUrls.client = "http://localhost:5050/student";
} else if (window.location.hostname === "student-demo.esparklearning.com") {
  apiAccessUrls.core = "https://demo.esparklearning.com";
  apiAccessUrls.client = "https://student-demo.esparklearning.com";
} else if (window.location.hostname === "student-preview.esparklearning.com") {
  apiAccessUrls.core = "https://preview.esparklearning.com";
  apiAccessUrls.client = "https://student-preview.esparklearning.com";
} else if (window.location.hostname === "student-qa.esparklearning.com") {
  apiAccessUrls.core = "https://qa.esparklearning.com";
  apiAccessUrls.client = "https://student-qa.esparklearning.com";
} else if (window.location.hostname === "student-testing.esparklearning.com") {
  apiAccessUrls.core = "https://testing.esparklearning.com";
  apiAccessUrls.client = "https://student-testing.esparklearning.com";
}

const ESPARK_API_ENDPOINTS = [
  apiAccessUrls.core,
  apiAccessUrls.client,
  "https://production-espark-client-tts-new.s3.amazonaws.com/fdce93c0c1e46370cbf9c5afa6f10123.mp3",
  "https://cdn.esparklearning.com/assets/Rocky_EmptyStateRocky-fb62fd4074473c58347897cb899b36d634cc21a5e40c2ed8e0aca79808116569.png",
  "https://espark-cdn.esparklearning.com/1457b100346b7f4b21a352a72967fb4a/1457b100346b7f4b21a352a72967fb4a.mp4",
  "https://media.esparklearning.com/health"
];

function performEsparkResourceCheck(studentInfo) {
  return Promise.all(
    ESPARK_API_ENDPOINTS.map(endpoint => {
      loadAndVerifyApiAccess(endpoint, studentInfo);
    })
  );
}

export function performSystemCheck(studentInfo) {
  const studentId =
    Boolean(studentInfo) && studentInfo.hasOwnProperty("id") ? studentInfo.id : null;
  const canvas = document.createElement("canvas");
  const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  global.logger.eventReceived(
    {
      message: "WebGL Check",
      student_id: studentId,
      webGLAvailable: Boolean(gl),
      event_type: "device_check"
    },
    "javascript"
  );
}

// This checks whether we can read from a given URL
export function loadAndVerifyApiAccess(url, studentInfo) {
  // We always want to resolve from the promise, even if the fetch fails
  let studentId = Boolean(studentInfo) && studentInfo.hasOwnProperty("id") ? studentInfo.id : null;
  return new Promise(function(resolve, reject) {
    return fetch(url)
      .then(function(response) {
        return response.blob();
      })
      .then(function() {
        const successMsg = "Network able to access resource";
        const loadAndVerifyApiAccessTestDetails = {
          message: successMsg,
          student_id: studentId,
          resource: url,
          event_type: "device_check"
        };

        global.logger.eventReceived(loadAndVerifyApiAccessTestDetails, "javascript");
        resolve({ result: true, name: "resources", url: url });
      })
      .catch(function() {
        let msg = "Network unable to access resource";
        Sentry.captureMessage(msg, {
          level: "error",
          extra: { url, studentInfo: studentInfo }
        });

        global.logger.eventReceived(
          {
            message: msg,
            student_id: studentId,
            resource: url,
            event_type: "device_check"
          },
          "javascript"
        );

        console.warn(`Network unable to access resource: ${url}`);

        resolve({
          result: false,
          name: "resources",
          url: url,
          message: msg
        });
      });
  });
}

export function loadAndVerifyScriptAccess(scriptData, studentInfo) {
  let studentId = Boolean(studentInfo) && studentInfo.hasOwnProperty("id") ? studentInfo.id : null;
  function successCallback() {
    if (
      Boolean(scriptData.scriptObjName) &&
      has(window, scriptData.scriptObjName) &&
      !isEmpty(window[scriptData.scriptObjName])
    ) {
      const successMsg = "Tool successfully loaded and verified";
      const loadAndVerifyScriptAccessTestDetails = {
        message: successMsg,
        student_id: studentId,
        tool: scriptData.src,
        event_type: "device_check"
      };

      global.logger.eventReceived(loadAndVerifyScriptAccessTestDetails, "javascript");
      return { result: true, name: "tools", scriptData: scriptData };
    } else {
      return invalidCallback();
    }
  }
  function invalidCallback() {
    const msg = `Tool not found: ${scriptData.name}`;
    Sentry.captureMessage(msg, {
      level: "error",
      extra: { src: scriptData.src, studentInfo: studentInfo }
    });

    global.logger.eventReceived(
      {
        message: msg,
        student_id: studentId,
        tool: scriptData.src,
        event_type: "device_check"
      },
      "javascript"
    );

    console.warn(msg);

    return { message: msg, result: false, name: "tools", scriptData: scriptData };
  }
  function errorCallback() {
    const error_msg = `Tool throws an error trying to load: ${scriptData.name}`;
    Sentry.captureMessage(error_msg, {
      level: "error",
      extra: { src: scriptData.src, studentInfo: studentInfo }
    });

    global.logger.eventReceived(
      {
        message: error_msg,
        student_id: studentId,
        tool: scriptData.src,
        event_type: "device_check"
      },
      "javascript"
    );

    console.warn(error_msg);

    return {
      result: false,
      name: "tools",
      scriptData: scriptData,
      message: error_msg
    };
  }
  return loadjs(scriptData.src, {
    returnPromise: true
  })
    .then(() => {
      return successCallback();
    })
    .catch(() => {
      return errorCallback();
    });
}

import Loggable from "javascript/Loggable";
import AuthoredSourceElement from "./AuthoredSourceElement";

export default class Option extends Loggable {
  constructor(data) {
    super("learnosity - option");

    this._authoredSourceElement = new AuthoredSourceElement(data.label);
    this._value = data.value;
  }

  getAuthoredSourceElement() {
    return this._authoredSourceElement;
  }

  getValue() {
    return this._value;
  }
}

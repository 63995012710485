import localforage from "localforage";

const keyPrefix = "student-video-";

const buildKey = studentQuestId => `${keyPrefix}${studentQuestId}`;
const extractStudentQuestId = key => key.replace(keyPrefix, "");

// These are not filtered by student -- even on a shared iPad we'd want to upload all stored
// videos.
export default {
  getStoredVideos: () => {
    return localforage.keys().then(keys => {
      return keys.filter(k => k.match(keyPrefix)).map(extractStudentQuestId);
    });
  },

  storeVideo: (studentQuestId, blob) => {
    return localforage.setItem(buildKey(studentQuestId), blob);
  },

  getVideo: studentQuestId => {
    return localforage.getItem(buildKey(studentQuestId));
  },

  removeVideo: studentQuestId => {
    return localforage.removeItem(buildKey(studentQuestId));
  }
};

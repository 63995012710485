import loadjs from "loadjs";

export default function setupQRCodeReaderPorts(ports) {
  // 2020/06/05 AHK: the QR code package uses static class properties, which I absolutely cannot
  // get Webpack to work with. Hence loading from the web server, as Sade originally set it up to load from S3.
  // Using this package https://github.com/mebjas/html5-qrcode
  // Currently on version 1.2.1 on S3
  loadjs("/html5-qrcode.min.js", "html5-qrcode");
  /* Keep track of whether the qrcode scanner has been initialized already. If we try
   * to initialize it more than once, then we get multiple video elements on the screen.
   */
  const moduleScope = self;
  moduleScope.initializedQRCodeScanner = false;

  let scanner;

  ports.stopQRCodeReader.subscribe(() => {
    if (!scanner) {
      return;
    }
    moduleScope.initializedQRCodeScanner = false;
    scanner
      .stop()
      .then(() => {
        scanner.clear();
        ports.qrCodeReaderStopped.send();
        console.log("Successfully stopped QRCode Scanner");
      })
      .catch(err => {
        console.warn("Error stopping scanner", err);
      });
  });

  ports.startQRCodeReader.subscribe(() => {
    loadjs.ready("html5-qrcode", {
      success: () => {
        if (moduleScope.initializedQRCodeScanner) {
          return;
        }
        moduleScope.initializedQRCodeScanner = true;
        Html5Qrcode.getCameras()
          .then(cameras => {
            if (cameras.length == 0) {
              moduleScope.initializedQRCodeScanner = false;
              return ports.qrCodeReadError.send("Unable to access camera");
            }

            const cameraId = cameras[0].id; // should provide a drop-down if more than one camera
            scanner = new Html5Qrcode("qr-scanner");
            const onSuccessfulScan = qrCodeMessage => {
              ports.qrCodeReadSuccess.send(qrCodeMessage);
            };
            ports.qrCodeReaderStarted.send();
            scanner.start(
              cameraId,
              { fps: 10, qrbox: 300 },
              onSuccessfulScan,
              // the QR code library is very noisy, so don't send messages to Elm
              () => {}
            );
          })
          .catch(e => {
            moduleScope.initializedQRCodeScanner = false;
            console.warn(e);
          });
      },
      error: () => {
        moduleScope.initializedQRCodeScanner = false;
        ports.qrCodeReadError.send("Unable to load QR library");
      }
    });
  });
}

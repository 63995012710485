import { isAllowedOrigin } from "../util/IframeCommunication";

interface EsparkIframeUpdatePorts {
  activateEsparkIframe: PortToElm<string>;
  esparkIframeNavigateToActivity: PortToElm<string>;
  esparkIframeCompleteActivity: PortToElm<string>;
  // We use the basic object here since we are not manipulating the data provided by Elm, merely
  // passing it on. This allows us to avoid defining it again here in Typescript, which can (has)
  // fallen out of date.
  updateEsparkIframeWithQuestData: PortFromElm<[string, object]>;
}

/* TODO: if we start receiving other messages via postMessage, move this to a generalized handler in Elm */
export default function setupTeacherTourIframeUpdatePorts(ports: EsparkIframeUpdatePorts): void {
  window.addEventListener("message", function(event: MessageEvent): void {
    if (!isAllowedOrigin(event.origin) || !event.data?.message) {
      return;
    }

    const activityId: string | undefined = event.data.activityId;

    switch (event.data.message) {
      case "ActivateEsparkIframe":
        console.log("Activating eSpark iframe communication");
        ports.activateEsparkIframe.send(event.origin);
        break;
      case "NavigateToActivity":
        if (!activityId) {
          console.warn("No student activity ID provided in iframe call to navigate");
          return;
        }
        console.log("Navigating to student activity with ID", activityId);
        ports.esparkIframeNavigateToActivity.send(activityId);
        break;
      case "CompleteActivity":
        if (!activityId) {
          console.warn("No activity ID provided in iframe call to complete");
          return;
        }
        console.log("Completing student activity with ID", activityId);
        ports.esparkIframeCompleteActivity.send(activityId);
        break;
    }
  });

  ports.updateEsparkIframeWithQuestData.subscribe(([origin, data]): void => {
    // if this is not in a frame, window.parent == window
    console.log("Posting data to ", origin, data);
    window.parent.postMessage({ message: "Teacher Tour Update", ...data }, origin);
  });
}

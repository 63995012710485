import * as c from "./constants";

/*
  Utilities for selecting learnosity DOM content.

  Functions in this file should be general and not specific to a single use case.
 */

export const selectItem = ref => document.querySelector(itemScopeSelector(ref)); // prettier-ignore
export const selectInItem = (ref, childSelector) => document.querySelector(itemScopeSelector(ref, childSelector)); // prettier-ignore
export const selectAllInItem = (ref, childSelector) => document.querySelectorAll(itemScopeSelector(ref, childSelector)); // prettier-ignore

export const selectQuestion = id => document.getElementById(id);
export const selectInQuestion = (id, childSelector) => selectQuestion(id).querySelector(childSelector) // prettier-ignore
export const selectAllInQuestion = (id, childSelector) => selectQuestion(id).querySelectorAll(childSelector) // prettier-ignore

export const selectResponseOptionsInQuestion = questionId => document.getElementById(questionId).querySelector(c.RESPONSE_WRAPPER_SELECTOR) //prettier-ignore
export const selectResponseOptionInQuestion = (questionId, optionValue) =>
  document
    .getElementById(questionId)
    .querySelector(`input[value="${optionValue}"] ~ label .lrn-possible-answer`);

export const selectOptionForValue = queryScope => optionValue =>
  queryScope.querySelector(`input[value="${optionValue}"] ~ label .lrn-possible-answer`);

export const selectContentForResponseOption = optionElement =>
  optionElement.querySelector(c.LEARNOSITY_GENERAL_CONTENT_WRAPPER_SELECTOR);

// Scopes a dom query to, or within a learnosity item's DOM tree.
function itemScopeSelector(ref, childSelector) {
  const itemSelector = `.learnosity-item[data-reference="${ref}"]`;

  return [itemSelector, childSelector].filter(Boolean).join(" ");
}

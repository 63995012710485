/**
 * Manages parsing audioclips from the epub file
 */
import { AudioClip } from "./AudioHighlighter";
import { PageData } from "./EpubManager";

interface AudioSetupResult {
  rawAudioUrl: string;
  pageAudio: Array<AudioClip>;
  canonical: string;
}
export interface EpubAudio {
  audio: Array<AudioClip>;
  srcDir: string;
  rawUrl: string;
}
export default class EpubAudioParser {
  handleError: (errorMsg: string, isCritical: boolean) => void;
  constructor(onError: (errorMsg: string, isCritical: boolean) => void) {
    this.handleError = onError;
  }
  async parseAudioFromEpub(pages: PageData[]): Promise<EpubAudio> {
    if (pages.length < 2) {
      console.error("ERROR: Audio parser given invalid page data");
      this.handleError("Audio parser given invalid page data, pages length less than 2.", true);
      return {
        audio: [],
        srcDir: "",
        rawUrl: ""
      };
    }

    const firstPageSectionIndex = pages[0].index;
    const secondPageSectionIndex = pages[1].index;

    const audioContentFirstPage: AudioSetupResult | undefined = this.createAudioForSection(
      pages[0]
    );

    let audioContentSecondPage: AudioSetupResult | undefined;

    //if there is a single page, the location.start will equal location.end
    if (secondPageSectionIndex != firstPageSectionIndex) {
      audioContentSecondPage = this.createAudioForSection(pages[1]);
    }
    if (audioContentFirstPage || audioContentSecondPage) {
      return this.setupAudioForAllPages(audioContentFirstPage, audioContentSecondPage);
    } else {
      return {
        audio: [],
        srcDir: "",
        rawUrl: ""
      };
    }
  }
  /**
   * Validate the overlay url is correct for the page
   * @param overlaySrc
   * @param pageSrc
   * @returns
   */
  overlayIsValid(overlaySrc: string, pageSrc: string) {
    let overlay = overlaySrc
      .substring(overlaySrc.lastIndexOf("/") + 1, overlaySrc.length)
      .split(".")[0];
    let page = pageSrc.substring(pageSrc.lastIndexOf("/") + 1, pageSrc.length).split(".")[0];
    if (overlay !== page) {
      return false;
    }
    return true;
  }
  createAudioForSection(page: PageData) {
    if (!page.iframe || page.iframe.contentDocument == null) {
      console.error("ERROR: iframe for this section not found:" + page.index);
      this.handleError("iframe for this section not found: " + page.index + ".", true);
      return;
    }

    //overlay is the name of the smil file, mediaOverlay is the object returned from a HTTP request
    //if the page has an audio SMIL file, but nothing was loaded, then we have an error condition.
    if (!page.section.mediaOverlay) {
      if (page.section.overlay) {
        console.error(page.section.overlay);
        console.error("ERROR:Media overlay was not parsed correctly.");
        this.handleError(
          "Media overlay was not parsed correctly for this overlay: " + page.section.overlay + ".",
          false
        );
      }
      return;
    }

    if (!this.overlayIsValid(page.section.overlay.href, page.section.href)) {
      console.error("ERROR: Media overlay does not match page.");
      return;
    }

    const audioTags = this.parseAudioFromOverlay(page.section.mediaOverlay);
    if (audioTags.length < 1) {
      console.error("ERROR: Didn't find any audio tags");
      this.handleError("Did not parse any audio tags from overlay.", false);
      return;
    }
    //audio urls should be the same for all tags, per Capstone standard
    const audioUrl = audioTags[0].getElementsByTagName("audio")[0].getAttribute("src");
    const audio = this.createAudioClips(audioTags, page.iframe.contentDocument);
    if (audio.length != audioTags.length) {
      console.error("ERROR: Mismatch of audio during parse.");
      this.handleError("Mismatch of audio clips length after parse.", false);
      return;
    }
    return {
      rawAudioUrl: audioUrl ? audioUrl : "",
      pageAudio: audio,
      canonical: page.section.canonical
    };
  }
  /**
   * Parses the data from epub.js to generate audio tags
   * Dependant on the change made to the library to load the mediaOverlay
   * @param sectionIndex
   * @returns
   */
  parseAudioFromOverlay(mediaOverlay: string): HTMLElement[] {
    let parser = new DOMParser();
    let overlayDom: Document = parser.parseFromString(mediaOverlay, "application/xml");
    const errorNode = overlayDom.querySelector("parsererror");
    if (errorNode) {
      // parsing failed
      console.error("ERROR on parsing audio:" + errorNode.textContent);
      console.error(mediaOverlay);
      this.handleError(
        "Error on parsing audio:" + errorNode.textContent + ", overlay:" + mediaOverlay + ".",
        false
      );
      return [];
    }
    /**
             * using par tags is not part of the EPUB3 definition, but it's a standard within Capstone books.
             * Other book types may have another way of wrapping the audio tags.
             * We can't just grab the audio tags because we need the corresponding text information.
             * 
             * E.g.
             * <par id="id-2">
                <text src="../page0001.xhtml#word2"/>
                <audio clipBegin="8.921" clipEnd="9.731" src="../audio/abo_winve_f20_masteraudio.mp3"/>
                <!--Inventions-->
              </par>
             */

    return Array.from(overlayDom.querySelectorAll("par")) as HTMLElement[];
  }
  /**
   * given the HTML tags parsed from the media overlay, generate the objects representing the words for highlighting
   * sectionIndex is the page index
   * @param {*} audioTags
   */
  createAudioClips(audioTags: HTMLElement[], doc: Document): Array<AudioClip> {
    let pageAudio: Array<AudioClip> = [];
    if (audioTags.length <= 0 || !doc) {
      return pageAudio;
    }
    audioTags.forEach((par: HTMLElement) => {
      //this is the text with the id to match within the iframe
      const text: SVGTextElement | null = par.getElementsByTagName("text")[0]; //should only be one per word
      if (!text) {
        return;
      }
      const textId = (text.getAttribute("src") || "").split("#")[1]; //text source format is page.xhtml#word
      const audio = par.getElementsByTagName("audio")[0]; //should only be one word
      const textRef = doc.getElementById(textId);
      if (!textRef) {
        console.error("ERROR: audio not found for" + par);
        this.handleError("Audio not found for " + par + ".", false);
        return;
      }
      //we're caching the text reference to make highlighting faster/smoother
      pageAudio.push({
        textId: textId,
        text: textRef,
        clipBegin: parseFloat(
          audio.getAttribute("clipBegin") || audio.getAttribute("clipbegin") || "0"
        ),
        clipEnd: parseFloat(audio.getAttribute("clipEnd") || audio.getAttribute("clipend") || "0"),
        duration: null
      });
    });
    //don't calculate duration if the page audio onError during parsing
    if (pageAudio.length <= 0) {
      return pageAudio;
    }
    /**
     * The SMIL audio definitions are done manually, but it seems for Capstone the begin/end is exact for each word and does not include a pause.
     * Sometimes there will be a pause between words, for example chapter titles.   In those cases the highlighting needs to pause the right amount of time.
     * We do this by calculating the difference between the start of a word and the next, and this is the duration.
     *
     * May remove this if the live calculation using currentTime is working well.
     */
    for (let i = 0; i < pageAudio.length - 1; i++) {
      pageAudio[i].duration = 1000 * (pageAudio[i + 1].clipBegin - pageAudio[i].clipBegin);
    }
    pageAudio[pageAudio.length - 1].duration =
      1000 * (pageAudio[pageAudio.length - 1].clipEnd - pageAudio[pageAudio.length - 1].clipBegin);
    return pageAudio;
  }
  /**
   * This parses the URL from the audio elements to setup the audio element on the page.  There is only one element per page.
   * Then it appends both page audio together and initializes audio to the beginning.
   * @param audioContentFirstPage
   * @param audioContentSecondPage
   * @returns
   */
  setupAudioForAllPages(
    audioContentFirstPage: AudioSetupResult | undefined,
    audioContentSecondPage: AudioSetupResult | undefined
  ): EpubAudio {
    let canonical =
      audioContentFirstPage && audioContentFirstPage.canonical
        ? audioContentFirstPage.canonical
        : audioContentSecondPage?.canonical;
    let rawAudioUrl =
      audioContentFirstPage && audioContentFirstPage.rawAudioUrl
        ? audioContentFirstPage.rawAudioUrl
        : audioContentSecondPage?.rawAudioUrl;

    if (!canonical || !rawAudioUrl) {
      console.error("ERROR: No canonical or audio url source found.");
      this.handleError("No canonical or audio url source found.", false);
      return {
        audio: [],
        srcDir: "",
        rawUrl: ""
      };
    }
    //Assuming both pages have the same audio because otherwise we have to track which page we're on and connect the audio players to each page
    let sectionDir = canonical.substring(0, canonical.lastIndexOf("/"));

    let audioAllPages = [
      ...(audioContentFirstPage?.pageAudio || []),
      ...(audioContentSecondPage?.pageAudio || [])
    ];
    return {
      audio: audioAllPages,
      srcDir: sectionDir,
      rawUrl: rawAudioUrl
    };
  }
}

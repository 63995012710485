import { AssessmentViewer, EvaluateParentMessage } from "@espark/evaluate-client";
import { FetchFunctionType, TTS } from "./commonTypes";

let instance: EvaluateComponent | null = null;
const COMPONENT_RETRY_LIMIT = 5;
const MAX_COMPONENT_LOAD_TIME = 10000;

export default class EvaluateComponent {
  fetchFunction: (input: string, init?: Record<string, any> | undefined) => Promise<Response>;
  onEventPortCall: (toSent: object) => void;
  audioEnabled: boolean;
  tts: TTS;
  studentActivityId: number;
  studentId: number;
  componentRetryCount = 0;
  retryCallbackId: ReturnType<typeof window.setTimeout> | null = null;
  errorIfNotLoadedCallbackId: ReturnType<typeof window.setTimeout> | null = null;

  constructor(
    fetchFunction: FetchFunctionType,
    studentId: number,
    studentActivityId: number,
    onEventPortCall: (toSend: object) => void,
    tts: TTS,
    audioEnabled: boolean
  ) {
    this.fetchFunction = fetchFunction;
    this.onEventPortCall = onEventPortCall;
    this.studentActivityId = studentActivityId;
    this.studentId = studentId;
    this.audioEnabled = audioEnabled;
    this.tts = tts;
    if (instance) {
      this.removeInstance();
    }

    instance = this;
    this.componentRetryCount = 0;
    this.displayEvaluateComponent();
  }

  static returnInstance() {
    return instance;
  }

  removeInstance() {
    // This is called in two situations:
    // 1) before navigating away from the component page (see componentRemove port call),
    //    in which case we need to clean up any DOM side effects before navigating.
    // 2) when we get a second call to instantiate the component (see the constructor above),
    //    in which case we want to remove the old one.
    const existingEvaluateContainer = document.querySelector(".evaluate-container");

    if (existingEvaluateContainer) {
      existingEvaluateContainer.remove();
    }
    if (instance && instance.retryCallbackId) {
      // shut down any timed retry callbacks on this instance
      clearTimeout(instance.retryCallbackId);
    }
    instance = null;
  }

  displayEvaluateComponent(): void {
    const componentContainer = document.querySelector(".component-container");

    if (!componentContainer) {
      if (this.componentRetryCount < COMPONENT_RETRY_LIMIT) {
        this.retryCallbackId = (window.setTimeout(
          () => this.displayEvaluateComponent(),
          100 + this.componentRetryCount * 100
        ) as unknown) as ReturnType<typeof window.setTimeout>; // fixes bug in setTimeout type definition
        this.componentRetryCount++;
      } else {
        this.onEventPortCall({
          studentActivityId: this.studentActivityId,
          componentType: "evaluate",
          eventType: "EvaluateError",
          eventMessage: "No container found",
          eventData: {}
        });
      }
      return;
    }

    let evaluateContainer = document.createElement("div");

    evaluateContainer.setAttribute("class", "evaluate-container");
    evaluateContainer.setAttribute("style", "min-height: inherit;");
    componentContainer.appendChild(evaluateContainer);

    new AssessmentViewer({
      target: evaluateContainer,
      props: {
        studentId: this.studentId,
        studentActivityId: this.studentActivityId,
        fetchFunction: this.fetchFunction,
        parentMessageConsumer: this.onEvent,
        tts: this.tts,
        audioEnabled: this.audioEnabled
      }
    });
    this.errorIfNotLoadedCallbackId = (window.setTimeout(
      () =>
        this.onEventPortCall({
          studentActivityId: this.studentActivityId,
          componentType: "evaluate",
          eventType: "EvaluateError",
          eventMessage: `Component not loaded after ${MAX_COMPONENT_LOAD_TIME} ms`,
          eventData: {}
        }),
      MAX_COMPONENT_LOAD_TIME
    ) as unknown) as ReturnType<typeof window.setTimeout>; // fixes bug in setTimeout type definition
    // Helpers.hideQuizLoader();
  }

  onEvent = (event: EvaluateParentMessage): void => {
    if (event.type === "EvaluateError") {
      this.clearErrorIfNotLoaded();
      this.onEventPortCall({
        studentActivityId: this.studentActivityId,
        componentType: "evaluate",
        eventType: event.type,
        eventMessage: event.message,
        eventData: event
      });
    } else if (event.type === "AssessmentCompleted") {
      this.onEventPortCall({
        studentActivityId: this.studentActivityId,
        componentType: "evaluate",
        eventType: event.type
      });
    } else if (event.type === "AssessmentLoaded") {
      this.clearErrorIfNotLoaded();
    }
  };

  clearErrorIfNotLoaded = () => {
    if (this.errorIfNotLoadedCallbackId) {
      clearTimeout(this.errorIfNotLoadedCallbackId);
      this.errorIfNotLoadedCallbackId = null;
    }
  };
}

import Sentry from "javascript/Sentry/Wrapper";
import Loggable from "javascript/Loggable";
import * as c from "javascript/Learnosity/util/constants";

// want to have this implement HTMLElement -- need typescript

export default class AuthoredSourceElement extends Loggable {
  constructor(rawMarkup) {
    super(`learnosity - authored source`);

    const div = document.createElement("div");
    this.element = div;

    if (rawMarkup) {
      Sentry.addBreadcrumb({
        message: "Decoding markup",
        category: "js",
        data: { markup: rawMarkup },
        level: "info"
      });
      // replace % that is not part of an encoded-entity, such as when it is part of a percentage100%, with %25,
      // so that decodeURIComponent doesn't fail.
      rawMarkup = rawMarkup.replace(/%(?=\W|$)/g, "%25");
      div.innerHTML = decodeURIComponent(rawMarkup);
    }
  }

  get innerHTML() {
    return this.element.innerHTML;
  }

  get outerHTML() {
    return this.element.outerHTML;
  }

  querySelector(selector) {
    return this.element.querySelector(selector);
  }

  querySelectorAll(selector) {
    return this.element.querySelectorAll(selector);
  }

  hasAudioOverride() {
    return Boolean(this.element.querySelector(c.AUDIO_OVERRIDE_SRC_SELECTOR));
  }
}

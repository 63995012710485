import Loggable from "javascript/Loggable";
import Option from "./Option";
import AuthoredSourceElement from "./AuthoredSourceElement";

export default class Question extends Loggable {
  constructor(question) {
    super("learnosity - question");

    const questionData = question.getQuestion();
    this._id = questionData.response_id;
    this._itemRef = questionData.metadata.sheet_reference;
    this._format = questionData.type;
    this._authoredSourceElement = new AuthoredSourceElement(questionData.stimulus);
    this._options = (questionData.options || []).map(optionData => new Option(optionData));
  }

  getId() {
    return this._id;
  }

  getItemRef() {
    return this._itemRef;
  }

  getFormat() {
    return this._format;
  }

  getAuthoredSourceElement() {
    return this._authoredSourceElement;
  }

  getOptions() {
    return this._options;
  }
}

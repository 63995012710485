// See https://developer.mozilla.org/en-US/docs/Web/API/Notification
export default function setupNotificationPorts(ports) {
  ports.requestNotificationPermissions.subscribe(() => {
    ifSupported(() => {
      const callback = function(result) {
        // this is canonically known by the browser, but is still useful to know in Elm
        ports.notificationPermissionUpdate.send(Notification.permission);
      };
      // Safari takes the callback as a callback, every other browser returns a promise 🤨
      const permissionRequest = Notification.requestPermission(callback);
      if (permissionRequest && permissionRequest.then) {
        permissionRequest.then(callback);
      }
    });
  });

  ports.sendNotification.subscribe(contentJSON => {
    ifSupported(() => {
      const { title, body, icon, image, buttonText } = JSON.parse(contentJSON);

      if (Notification.permission == "granted") {
        const timeSent = Date.now();

        // We could generate custom action buttons like this:
        // actions: [{title: "title", name: "name", icon, action}],
        // but those are only supported if we generate the notification from a ServiceWorkerRegistration
        const notification = new Notification(title, {
          body: body || "",
          icon,
          image,
          // persist until the student interacts with it
          requireInteraction: true
        });
        notification.addEventListener("click", () => {
          // when the notification is clicked, return to the page
          window.focus();
          notification.close();
          // for now, we only use notifications for prompting students to return, hence the name of
          // the port below -- in the future we might have other kinds, at which point we would
          // differentiate the callback appropriately.
          ports.studentReturnedViaNotification.send([contentJSON, Date.now() - timeSent]);
        });

        function hideNotification() {
          notification.close();
          ports.hideNotification.unsubscribe(hideNotification);
        }
        ports.hideNotification.subscribe(hideNotification);

        ports.returnNotificationSent.send(contentJSON);
      }

      // always make sure Elm is up to date on the permission state
      ports.notificationPermissionUpdate.send(Notification.permission);
    });
  });
}

// https://caniuse.com/#search=Notification
function ifSupported(callback) {
  if (window.Notification) {
    callback();
  } else {
    console.log("Notification api not supported.");
  }
}

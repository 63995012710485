/**
 * Manages the DOM elements for the ereader
 */

import { EpubAudio } from "./EpubAudioParser";

export default class AudioDOMManager {
  buttonId: string;
  audioElementId: string;
  handleError: (errorMsg: string, isCritical: boolean) => void;
  /**
   *
   * @param button
   * @param document
   * @returns
   */
  constructor(
    button: string,
    audioElement: string,
    onError: (errorMsg: string, isCritical: boolean) => void
  ) {
    this.buttonId = button;
    this.audioElementId = audioElement;
    this.handleError = onError;
  }
  getAudioButton() {
    return document.getElementById(this.buttonId) as HTMLButtonElement;
  }
  getAudioElement() {
    return document.getElementById(this.audioElementId) as HTMLAudioElement;
  }
  /**
   * disable audio in cases where there is an error in setup or for between navigation states
   */
  disableAudio() {
    const audioButton = this.getAudioButton();
    if (audioButton) {
      audioButton.style.visibility = "hidden";
    }
  }

  /**
   * allows the audio button to be pressed
   */
  enableAudio() {
    const audioButton = this.getAudioButton();
    if (audioButton) {
      audioButton.style.visibility = "visible";
      audioButton?.classList.remove("es-icon-pause");
      audioButton?.classList.add("es-icon-play");
      audioButton.disabled = false;
    }
  }
  /**
   * this is visible when audio is available for the page but before the audio element is finished loading the file
   */
  setAudioLoading() {
    const audioButton = this.getAudioButton();
    if (audioButton) {
      audioButton.style.visibility = "visible";
      audioButton?.classList.remove("es-icon-play");
      audioButton?.classList.add("es-icon-loading");
      audioButton.disabled = true;
    }
  }
  /**
   * set the play/pause button to pause
   */
  setAudioPlaying() {
    const audioButton = this.getAudioButton();
    audioButton?.classList.remove("es-icon-play");
    audioButton?.classList.add("es-icon-pause");
  }
  /**
   * set the play/pause button to play
   */
  setAudioPaused() {
    const audioButton = this.getAudioButton();
    audioButton?.classList.remove("es-icon-pause");
    audioButton?.classList.add("es-icon-play");
  }

  /**
   * Uses the relative URL to create the canonical URL for Safari, and creates an audio element for the page.
   *
   * @param rawAudioUrl
   * @param audioBaseUrl
   * @returns
   */
  setAudioUrl(rawAudioUrl: string, audioBaseUrl: string) {
    const audioElement = this.getAudioElement();
    if (!audioElement) {
      console.error("Audio element has not been defined, trying to set URL");
      this.handleError("Audio element has not been defined, trying to set URL.", true);
      return null;
    }
    if (rawAudioUrl) {
      const newAudioUrl = audioBaseUrl + "/" + rawAudioUrl.replace(/\.\.\//g, ""); //this is to remove any ../ relative
      audioElement.setAttribute("src", newAudioUrl);
    }
    return audioElement;
  }
  playAudio(): Promise<boolean> {
    const audioElement = this.getAudioElement();
    return audioElement.play().then(() => {
      this.setAudioPlaying();
      return !audioElement?.paused;
    });
  }
  pauseAudio() {
    const audioElement = this.getAudioElement();
    audioElement?.pause();
    //change the button to show the next action is play
    this.setAudioPaused();
    return audioElement?.paused;
  }
  setCurrentTime(time: number) {
    const audioElement = this.getAudioElement();
    if (audioElement) {
      audioElement.currentTime = time;
    }
  }
  getCurrentTime() {
    const audioElement = this.getAudioElement() as HTMLAudioElement;
    return audioElement?.currentTime;
  }
  loadAudio(
    epubAudio: EpubAudio,
    onLoaded: (event: Event) => void,
    onCanPlay: (event: Event) => void
  ) {
    //Assuming both pages have the same audio because otherwise we have to track which page we're on and connect the audio players to each page
    const audioElement = this.getAudioElement();
    if (audioElement) {
      this.setAudioUrl(epubAudio.rawUrl, epubAudio.srcDir);
      this.setAudioLoading();
      audioElement.addEventListener("loadedmetadata", onLoaded, { once: true });
      audioElement.addEventListener("canplaythrough", onCanPlay, { once: true });
    }
  }
  highlightText(text: Element) {
    text.classList.add("highlight-text");
  }
  clearHighlight(text: Element) {
    text.classList.remove("highlight-text");
  }
}

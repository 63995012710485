import Loggable from "../../Loggable";
import ResponseDom from "./ResponseDom";
import StimulusDom from "./StimulusDom";
import * as c from "javascript/Learnosity/util/constants";

export default class AssessmentDom extends Loggable {
  constructor(assessment) {
    super("learnosity - assessment dom");

    this.assessment = assessment;
  }

  prepare() {
    this._removeAudioPlayers();

    const questions = this.assessment.questions;
    questions.forEach(question => {
      const stimulus = new StimulusDom(question);
      const response = new ResponseDom(question);

      stimulus.prepare();
      response.prepare();
    });
  }

  _removeAudioPlayers() {
    Array.from(document.querySelectorAll(c.SELECTOR.LRN_AUDIO_PLAYER), player => player.remove());
  }
}

import { isAllowedOrigin } from "../util/IframeCommunication";

interface EsparkIframeUpdatePorts {
  trackPLEAvailable: PortToElm<string>;
  provideAuthInfoToPLE: PortFromElm<[string, string, object]>;
  accessoriesUpdate: PortToElm<[number]>;
}

/* TODO: if we start receiving other messages via postMessage, move this to a generalized handler in Elm */
export default function setupPLEPorts(ports: EsparkIframeUpdatePorts): void {
  window.addEventListener("message", function(event: MessageEvent): void {
    if (!isAllowedOrigin(event.origin) || !event.data?.message) {
      return;
    }

    switch (event.data.message) {
      case "PLEAvailable":
        ports.trackPLEAvailable.send(event.origin);
        break;
      case "AccessoriesUpdate":
        ports.accessoriesUpdate.send(event.data.equippedAccessories);
    }
  });

  ports.provideAuthInfoToPLE.subscribe(([iframeId, origin, authInfo]): void => {
    const iframe: HTMLIFrameElement | null = document.getElementById(
      iframeId
    ) as HTMLIFrameElement | null;
    if (!iframe || !iframe.contentWindow) {
      console.warn("Unable to communicate with the PLE! Iframe not found or not usable.", iframe);
      return;
    }

    console.log("Posting AuthToken data to to PLE at ", origin, authInfo);
    iframe.contentWindow.postMessage({ message: "eSpark Auth Info", authInfo }, origin);
  });
}

import UAParser from "ua-parser-js";

import AppTimerStorage from "./AppTimerStorage";
import IosNativeInterface from "./IosNativeInterface";
import * as LocalStorage from "./LocalStorage";

export const logPlatformData = platformData => {
  const studentSessionData = Boolean(window.localStorage.getItem("v5-session-debug-data"))
    ? JSON.parse(window.localStorage.getItem("v5-session-debug-data"))
    : {};
  logger.eventReceived(
    {
      message: "App Startup",
      student_id:
        studentSessionData.user && studentSessionData.user.hasOwnProperty("id")
          ? studentSessionData.user.id
          : null,
      browserUsesES6: browserUsesES6(),
      user_agent: window.navigator.userAgent,
      platformData: platformData,
      event_type: "client_init"
    },
    "javascript"
  );
};

export const browserUsesES6 = () => {
  "use strict";

  if (typeof Symbol == "undefined") return false;
  try {
    eval("class Foo {}");
    eval("var bar = (x) => x+1");
  } catch (e) {
    return false;
  }

  return true;
};

export const getPlatformData = () => {
  let decodedResumeURL = null;
  try {
    // this is a hacky workaround because Elm's Url package isn't available yet. Once 0.19 comes out
    // we can remove this code and do this all in Elm
    const resumeMatchData = document.location.pathname.match(/\/resume\/[0-9]+\/(.*)/);
    if (resumeMatchData) {
      decodedResumeURL = decodeURIComponent(resumeMatchData[1]);
    }
  } catch (e) {}
  const browserAgentParser = new UAParser();
  let basePlatformData = {
    serverBaseUrl: window.config.serverBaseUrl,
    clientBaseUrl: document.location.origin,
    pleBaseUrl: window.config.pleBaseUrl,
    choiceTextBaseUrl: window.config.choiceTextBaseUrl,
    mediaServerHealthCheckUrl: window.config.mediaServerHealthCheckUrl,
    os: browserAgentParser.getOS(),
    browser: getBrowser(browserAgentParser),
    device: browserAgentParser.getDevice(),
    isTablet: !!navigator.maxTouchPoints,
    environment: window.environment,
    inEsparkIosApp: IosNativeInterface.isEsparkIosApp(),
    deviceIdentifier: window.deviceIdentifier,
    esparkClientVersion: window.esparkClientVersion,
    supportsVideoRecording: Boolean(window.MediaRecorder),
    esparkAccessToken: window.esparkAccessToken,
    localStorage: JSON.stringify(LocalStorage.asObject()),
    // if there's a stored app timer on page load, immediately pass it over
    storedAppTimer: JSON.stringify(AppTimerStorage.getAppTimer()),
    appStartedAt: Date.now(),
    randomSeed: Math.random(),
    timezoneOffset: new Date().getTimezoneOffset(),
    decodedResumeURL: decodedResumeURL,
    ttsS3Bucket: window.config.aws.ttsS3Bucket,
    hostedAudioFileBaseUrl: window.config.aws.hostedAudioFileBaseUrl,
    ttsApiEndpoint: window.config.aws.ttsApiEndpoint,
    newVersionCheckUrl: window.newVersionCheckUrl,
    devFlags: window.devFlags,
    viewportHeight: window.innerHeight,
    viewportWidth: window.innerWidth,
    // We are currently using the timestamp of the build for our version number
    // as it is guaranteed to be increasing. In the app, we can then convert this
    // into a date/time and display it for debugging/versioning purposes.
    // In development, we tend not to do a full build, instead running server.js directly,
    // so we don't have this environment variable set. In this case, we default to 2010-01-01 (1262304000000)
    studentAppBuildTimestamp: parseInt(process.env.STUDENT_APP_BUILD_TIMESTAMP) || 1262304000000,
    studentAppBuildHash: window.SENTRY_RELEASE?.id || "unknown"
  };

  // for tests, we sometimes need to override these values. Sometimes we can easily do this, but
  // other times (because Cypress doesn't allow us to send custom headers) there's no easy way to
  // do that. So we allow for a special localStorage key with extra data.
  try {
    const rawTestSetup = localStorage.getItem("V5-TEST-SETTINGS");
    if (rawTestSetup) {
      const encodedTestSetup = JSON.parse(rawTestSetup);
      basePlatformData = { ...basePlatformData, ...encodedTestSetup };
    }
  } catch (e) {
    console.warn("Unable to initialize test setup", e);
  }

  return basePlatformData;
};

const getBrowser = browserAgentParser => {
  const originalBrowserInfo = browserAgentParser.getBrowser();
  const userAgent = browserAgentParser.getUA();
  if (!(originalBrowserInfo.version === undefined || originalBrowserInfo.version === null))
    return originalBrowserInfo;
  if (!!userAgent.match(/AppleWebKit.*Version\/15.6,/))
    return Object.assign(originalBrowserInfo, { version: "15" });
  return Object.assign(originalBrowserInfo, { version: "" });
};

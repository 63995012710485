/*
This file stores code used to log app events via Sentry breadcrumbs. This is a development feature
and can be enabled with the environment variable `LOG_APP_EVENTS=true`.
*/
const CATEGORY_COLOR_MAP = {
  nav: "#8d8f29",
  "ui.click": "#BA16AA",
  learnosity: "#dc220c",
  default: "#7F3862",
  elm: "#5a28a1",
  sentry: "#4427a7",
  xhr: "#2F83A3"
};

const LEVEL_COLOR_MAP = {
  debug: "#8d8f29",
  info: "#3583B7",
  warning: "#f4a61d",
  error: "#dc220c",
  fatal: "#ac2509"
};

const METHOD_COLOR_MAP = {
  get: "#0E6BA8",
  post: "#0A2472",
  put: "#001747",
  default: "#00072D"
};

export const logXhrBreadcrumb = breadcrumb => {
  console.log(
    "%c%s%c%s%c%s %c%s",
    ...categoryLog(breadcrumb),
    ...xhrStatusLog(breadcrumb),
    ...xhrMethodLog(breadcrumb),
    ...messageLog(breadcrumb.data.url)
  );
};

export const logNavBreadcrumb = breadcrumb => {
  console.groupCollapsed("%c[NAV]", `color: ${CATEGORY_COLOR_MAP.nav}; font-weight: bold;`);
  console.table(breadcrumb.data, Object.keys(breadcrumb.data));
  console.groupEnd();
};

export const logLearnosityErrorBreadcrumb = (code, msg) => {
  console.log(
    "%c%s%c%s %c%s",
    ...categoryLog({ category: "learnosity" }),
    ...levelLog({ level: "error" }),
    ...messageLog(`${code}: ${msg}`)
  );
};

// default
export const logBreadcrumb = (breadcrumb, hint) => {
  let override;
  let type = breadcrumb.data && Object.keys(breadcrumb.data).length ? "groupCollapsed" : "log";
  const isElmFailure =
    (breadcrumb.category || "") === "elm" &&
    breadcrumb.data &&
    breadcrumb.data.args &&
    breadcrumb.data.args.includes("Failure");
  if (isElmFailure) {
    type = "group";
    override = "error";
  }

  console[type](
    logTemplate(breadcrumb),
    ...categoryLog(breadcrumb, override),
    ...levelLog(breadcrumb),
    ...messageLog(breadcrumb.message)
  );
  if (type.includes("group")) {
    console.table(breadcrumb.data);
    console.groupEnd();
  }
};

// Internal

function codeToColor(code) {
  if (code < 299) return LEVEL_COLOR_MAP.debug;
  else if (code < 399) return LEVEL_COLOR_MAP.info;
  else if (code < 499) return LEVEL_COLOR_MAP.error;
  else if (code < 599) return LEVEL_COLOR_MAP.fatal;
  else return LEVEL_COLOR_MAP.warning;
}

function categoryLog(breadcrumb, override = "") {
  return [
    `color: ${LEVEL_COLOR_MAP[override] || CATEGORY_COLOR_MAP[(breadcrumb.category || "").toLowerCase()] || CATEGORY_COLOR_MAP.default}; font-weight: bold;`, // prettier-ignore
    `[${(breadcrumb.category || "").toUpperCase()}]`
  ];
}

function levelLog(breadcrumb, always = false) {
  const level = (breadcrumb.level || "debug").toLowerCase();
  if (!CATEGORY_COLOR_MAP[(breadcrumb.category || "").toLowerCase()] || always) {
    return [`color: ${LEVEL_COLOR_MAP[level]}; font-weight: bold;`, `[${level.toUpperCase()}]`];
  } else {
    return [];
  }
}

function messageLog(text) {
  return ["font-style: unset; color: unset; font-weight: normal;", text];
}

function logTemplate(breadcrumb) {
  if (!CATEGORY_COLOR_MAP[(breadcrumb.category || "").toLowerCase()]) {
    return "%c%s%c%s %c%s";
  } else {
    return "%c%s %c%s";
  }
}

function xhrStatusLog(breadcrumb) {
  return [
    `color: ${codeToColor(breadcrumb.data.status_code)}; font-weight: bold;`,
    `[${breadcrumb.data.status_code || "UNKNOWN"}]`
  ];
}

function xhrMethodLog(breadcrumb) {
  if (breadcrumb.data.method) {
    return [
      `color: ${METHOD_COLOR_MAP[breadcrumb.data.method.toLowerCase()] ||
        METHOD_COLOR_MAP.default}; font-weight: bold;`,
      `[${breadcrumb.data.method.toUpperCase()}]`
    ];
  }
}

import Loggable from "../../Loggable";
import * as s from "./../util/selectors";
import * as c from "javascript/Learnosity/util/constants";

export default class StimulusDom extends Loggable {
  constructor(question) {
    super("learnosity - stimulus dom");

    if (!question) { this.log("missing question data", { level: "warn" }); } // prettier-ignore

    this.question = question;
    this.node = s.selectInQuestion(question.getId(), c.STIMULUS_WRAPPER_SELECTOR);
  }

  prepare() {
    const stimulusContentElement = this.node.querySelector(c.STIMULUS_CONTENT_SELECTOR);
    const authoredSourceElement = this.question.getAuthoredSourceElement(); // this has the markup we need

    if (!stimulusContentElement) { return; } // prettier-ignore

    stimulusContentElement.innerHTML = authoredSourceElement.outerHTML;

    if (authoredSourceElement.hasAudioOverride()) {
      this._injectHumanAudio(stimulusContentElement, authoredSourceElement);
    }
  }

  _injectHumanAudio(stimulusContentElement) {
    s.selectQuestion(this.question.getId()).classList.add(c.HAS_HAO_STIMULUS_CLASS);

    Array.from(stimulusContentElement.querySelectorAll(c.AUDIO_OVERRIDE_SRC_SELECTOR), el => {
      const url = el.dataset.src;
      const parent = el.parentElement;

      parent.dataset.audioOverride = "true";
      parent.setAttribute("override", url);
    });
  }
}

import Loggable from "javascript/Loggable";
import Question from "javascript/Learnosity/models/Question";

export default class Assessment extends Loggable {
  constructor(app) {
    super("learnosity - assessment");

    const items = app.getItems();

    this.questions = Object.keys(items).map(itemId => {
      const item = items[itemId];
      const response_id = item.response_ids[0];
      return new Question(app.questions()[response_id]);
    });
  }
}

import Sentry from "javascript/Sentry/Wrapper";

/**
 * Storage is a slightly opaque object that requires special method calls
 * to get the data correctly. This function flattens all of that into a raw
 * object so that we can toss into Elm.
 *
 * There may be other items in the local storage unrelated to our application,
 * and they might not be JSON parseable. These aren't relevant to try to parse.
 * If there is a problem with one of our actual values being non-parseable,
 * then we will want to track it.
 * All our keys start with v5, so we will filter down to just those and
 * raise an exception if there is a problem parsing it.
 */
export function asObject(storage = window.localStorage) {
  const obj = {};
  for (let i = 0; i < storage.length; i++) {
    const key = storage.key(i);
    const value = storage.getItem(key);
    if (key.startsWith("v5-") || key.startsWith("espark-")) {
      try {
        obj[key] = JSON.parse(value);
      } catch (e) {
        Sentry.captureException(e, {
          extra: { key, value },
          tags: { component: "LocalStorage", caught: "true" }
        });
      }
    }
  }
  return obj;
}

/**
 * Corollary to the above, this saves a raw object by manually pulling out the
 * individual items and saving them separately.
 *
 * This only does inserts/replacements. Any key that's missing will not be touched.
 */
export function updateFromObject(obj, storage = window.localStorage) {
  for (const [key, value] of Object.entries(obj)) {
    storage.setItem(key, JSON.stringify(value));
  }
}

export function setupPorts(ports) {
  ports.updateLocalStorage.subscribe(updateFromObject);
}

const allowedOrigins: Array<RegExp> = [
  /https?:\/\/([a-z_\-]+\.)?esparklearning\.com(\/|$)/,
  /https?:\/\/([a-z_\-]+\.)?espark\.io(\/|$)/,
  /https?:\/\/localhost:([0-9]+)(\/|$)/,
  /http?:\/\/localhost\.charlesproxy\.com:([0-9]+)(\/|$)/
];

export function isAllowedOrigin(receivedOrigin: string): boolean {
  return Boolean(
    allowedOrigins.find(allowedOrigin => {
      return allowedOrigin.test(receivedOrigin);
    })
  );
}

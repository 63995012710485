export default function setupHotjarPorts(ports) {
  ports.enableHotjarTracking.subscribe(([studentId, organizationId]) => {
    addHotjarTracking();
    sendHotjarUserData(studentId, organizationId);
  });
}

function addHotjarTracking() {
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 2288994, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}
function sendHotjarUserData(studentId, organizationId) {
  console.log(
    "Sending data to Hotjar...student_id:(" + studentId + "), org_id:(" + organizationId + ")"
  );
  window.hj("identify", studentId, {
    organization_id: organizationId,
    student_id: studentId
  });
}

export default function setupHighfivePorts(ports) {
  function setupConfetti() {
    //confetti https://codepen.io/jacobgunnarsson/pen/pbPwga
    const Confettiful = function(el) {
      this.el = el;
      this.containerEl = null;

      this.confettiFrequency = 8;
      this.confettiColors = ["#fce18a", "#ff726d", "#b48def", "#f4306d"];
      this.confettiAnimations = ["slow", "medium", "fast"];

      this._setupElements();
      this._renderConfetti();
    };

    Confettiful.prototype._setupElements = function() {
      const containerEl = document.createElement("div");

      const elPosition = this.el.style.position;

      if (elPosition !== "relative" || elPosition !== "absolute") {
        this.el.style.position = "relative";
      }

      containerEl.classList.add("confetti-container");

      this.el.appendChild(containerEl);

      this.containerEl = containerEl;
    };

    Confettiful.prototype._renderConfetti = function() {
      this.confettiInterval = setInterval(() => {
        const confettiEl = document.createElement("div");
        const confettiSize = Math.floor(Math.random() * 3) + 7 + "px";
        const confettiBackground = this.confettiColors[
          Math.floor(Math.random() * this.confettiColors.length)
        ];
        const confettiLeft = Math.floor(Math.random() * this.el.offsetWidth) + "px";
        const confettiAnimation = this.confettiAnimations[
          Math.floor(Math.random() * this.confettiAnimations.length)
        ];

        confettiEl.classList.add("confetti", "confetti--animation-" + confettiAnimation);
        confettiEl.style.left = confettiLeft;
        confettiEl.style.width = confettiSize;
        confettiEl.style.height = confettiSize;
        confettiEl.style.backgroundColor = confettiBackground;

        confettiEl.removeTimeout = setTimeout(function() {
          confettiEl.parentNode.removeChild(confettiEl);
        }, 3000);

        this.containerEl.appendChild(confettiEl);
      }, 25);
    };

    window.confettiful = new Confettiful(document.querySelector(".js-container"));
    //confetti end
  }
  ports.highfive.subscribe(() => {
    //actions for on high five from Thom's codepen
    //https://codepen.io/thomvanderdoef/pen/mdKYzXR
    const hand = document.querySelector("#hand");
    const handHit = document.querySelector("#hand-hit");
    if (hand && handHit) {
      hand.style.visibility = "hidden";
      handHit.style.visibility = "visible";
    }

    const rocky = document.querySelector("#rocky");
    if (rocky) rocky.classList.remove("hovering");

    const rockyJetpack = document.querySelector(".rocky-jetpack");
    if (rockyJetpack) rockyJetpack.classList.add("rocky-fly-out");

    setupConfetti();
    const confetti = document.querySelector(".confetti-parent-container");
    if (confetti) confetti.classList.add("container-show");
    const spinner = document.querySelector(".spinner-container");
    if (spinner) spinner.style.visibility = "visible";
    const highFiveMark = document.querySelector("#hf-mark");
    if (highFiveMark) highFiveMark.classList.add("hf-mark-show");
  });
}

import * as dom from "./util/dom";

const FAVICON_32_PIXELS_SELECTOR = 'link[sizes="32x32"]';
const FAVICON_16_PIXELS_SELECTOR = 'link[sizes="16x16"]';
const WEB_ACTIVITY_WRAPPER_SELECTOR = ".is-activity-practice.webapp";
let esparkFaviconAnimationInterval;
export default function setupFaviconPorts(ports) {
  // setting a delay to ensure page loads
  setTimeout(() => {
    let onWebActivity = Boolean(document.querySelector(WEB_ACTIVITY_WRAPPER_SELECTOR));
    ports.addWebActivityInitiatedFaviconIfOnWebActivity.send(onWebActivity);
  }, 1500);

  ports.updateFavicon.subscribe(faviconInfo => {
    //stopping any infinite animation currently playing
    clearInterval(esparkFaviconAnimationInterval);
    // favicons can either be static or dynamic, if we detect there are multiple we know it is dynamic
    if (Object.prototype.hasOwnProperty.call(faviconInfo, "favicons")) {
      let currentFrameIndex = 0;
      let iterationCountIndex = 0;
      changeFavicon(
        faviconInfo.favicons[currentFrameIndex].small,
        faviconInfo.favicons[currentFrameIndex].large
      );

      if (faviconInfo.favicons.length > 1) {
        currentFrameIndex = currentFrameIndex + 1;
        esparkFaviconAnimationInterval = setInterval(() => {
          changeFavicon(
            faviconInfo.favicons[currentFrameIndex].small,
            faviconInfo.favicons[currentFrameIndex].large
          );

          if (currentFrameIndex === faviconInfo.favicons.length - 1) {
            // updating the iteration count and resetting animation
            currentFrameIndex = 0;
            iterationCountIndex = iterationCountIndex + 1;

            if (iterationCountIndex === faviconInfo.iterationCount) {
              // ending animation
              clearInterval(esparkFaviconAnimationInterval);
            }
          } else {
            currentFrameIndex = currentFrameIndex + 1;
          }
        }, faviconInfo.frameRate);
      }
    } else {
      changeFavicon(faviconInfo.small, faviconInfo.large);
    }
  });
}

const changeFavicon = (encodedSmallFavicon, encodedLargeFavicon) => {
  const faviconSize32 = document.querySelector(FAVICON_32_PIXELS_SELECTOR);
  const faviconSize16 = document.querySelector(FAVICON_16_PIXELS_SELECTOR);
  if (dom.elemExists(faviconSize32) && dom.elemExists(faviconSize16)) {
    const domHead = faviconSize32.parentNode;
    domHead.removeChild(faviconSize32);
    domHead.removeChild(faviconSize16);
    const newFaviconSize32 = createFaviconLinkDomElement(encodedSmallFavicon, faviconSize32.sizes);
    const newFaviconSize16 = createFaviconLinkDomElement(encodedLargeFavicon, faviconSize16.sizes);
    domHead.appendChild(newFaviconSize32);
    domHead.appendChild(newFaviconSize16);
  }
};

const createFaviconLinkDomElement = (encodedFaviconImage, sizes) => {
  let link = document.createElement("link");
  link.href = encodedFaviconImage;
  link.type = "image/ico";
  link.rel = "shortcut icon";
  link.sizes = sizes;
  return link;
};

const mediaOptions = {
  video: {
    facingMode: "front",
    // matches iOS: https://espark.slack.com/archives/CAD5LR49G/p1549644877018100
    width: 480,
    height: 360
  },
  frameRate: 15
};

export default function setupMediaCapturePermissionsPorts(ports) {
  ports.requestMediaCapturePermissions.subscribe(() => {
    ifSupported(() => {
      queryCameraPermission(permission => {
        updateElmWithPermission(permission);
        if (permission.state === "prompt") {
          return promptUserForPermission();
        }
      });
    });
  });

  function promptUserForPermission() {
    return navigator.mediaDevices
      .getUserMedia(mediaOptions)
      .then(stream => {
        return queryCameraPermission(updateElmWithPermission, true);
      })
      .catch(err => {
        return queryCameraPermission(updateElmWithPermission, false);
      });
  }

  function queryCameraPermission(handler, streamAccessSucceeded) {
    // 2021/09/30 AHK: Safari does not support querying for permissions, so we have to fake it
    if (navigator.permissions && navigator.permissions.query) {
      return navigator.permissions.query({ name: "camera" }).then(handler);
    } else {
      handler({ state: streamAccessSucceeded ? "Granted" : "Denied" });
    }
  }

  function updateElmWithPermission(permission) {
    ports.mediaCapturePermissionUpdate.send(permission.state);
  }

  function ifSupported(callback) {
    if (window.navigator && window.navigator.mediaDevices) {
      callback();
    } else {
      console.log("Video capture api not supported.");
    }
  }
}

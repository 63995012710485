export default class PortsManager {
  constructor(ports) {
    this.ports = ports;
    this.subscriptions = {};
  }

  subscribe(name, listener) {
    this.subscriptions[name] = listener;
    this.ports[name].subscribe(listener);
    return this;
  }

  unsubscribeAll() {
    for (const name in this.subscriptions) {
      const listener = this.subscriptions[name];
      this.ports[name].unsubscribe(listener);
    }
  }
}

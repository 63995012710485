import ePub, { Rendition, Location, Book } from "epubjs";
export interface BookSetupResult {
  rendition: Rendition;
}

export interface PageChangedUpdateData {
  newSectionIndex: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  studentActivityId: string;
  numberOfPagesDisplayed: number;
}
export interface PageErrorData {
  newSectionIndex: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  studentActivityId: string;
  numberOfPagesDisplayed: number;
  error: string;
  isCriticalError: boolean;
}
// 2022/10/31 AHK: for some reason the epubjs Section type a) doesn't define attributes that we use and b) doesn't want to import
// If in the future we import and use Section instead of this custom type, we can remove it.
export interface EpubSection {
  mediaOverlay: string;
  overlay: any;
  canonical: string;
  href: string;
}
export interface PageData {
  index: number;
  section: EpubSection;
  iframe: HTMLIFrameElement | null;
}
/**
 * Manages the ebook creation and rendition to the screen
 */
export default class EpubManager {
  /**
   * Initializes the epub.js Book and Rendition objects, loads the activity OPF via the activityUrl, and sets up the relocation event handling
   * @param activityUrl
   * @returns
   */
  async init(
    activityUrl: string,
    clientUrl: string,
    rendered: () => void
  ): Promise<BookSetupResult> {
    /*the epub.js book can be defined with a .epub or .opf file.  
            The difference is the .opf will load just the table of contents and then each page when navigating. 
            Loading a .epub will load the entire book all at once, which causes Safari to crash when loading from AWS.
          
            A Book represents the object loaded from parsing the .epuf/.opf files.
            */
    const book: Book = ePub(activityUrl);
    /*
            See epub.js for more settings.  Here we are setting the view to be 2 pages in landscape mode, 
            and ensuring to allow scripted content for the iframes.
          
            Rendition is the view manager.  Any resize, rotate, navigation event is handled by the rendition.  It draws the page(s) to iframes and adds them to the page.
            */
    const rendition: Rendition = book.renderTo("ereader-viewer", {
      flow: "paginated",
      width: "100%",
      height: "100%",
      snap: true,
      allowScriptedContent: true
    });
    rendition.hooks.content.register(function(contents: any) {
      return contents.addStylesheet(clientUrl + "/ebook_assets/book_theme.css");
    });
    rendition.on("rendered", function() {
      rendered();
    });
    rendition.on("orientationchange", (orientation: number) => {
      this.handleOrientationChange(rendition, orientation);
    });
    rendition.on("layout", (layout: any) => {
      this.handleResizeChange(rendition, layout);
    });
    return book.loaded.navigation.then(async function() {
      return await book.opened.then(async function() {
        // after the OPF has been loaded into the book object
        rendition.display();

        return { rendition };
      });
    });
  }
  getPageSide(rendition: Rendition, pageNum: number): String {
    return rendition.book.spine.get(pageNum).properties[0];
  }
  resetLandscape(rendition: Rendition) {
    // we have to handle right and left pages differently
    // if the orientation went from profile to landscape and you are viewing a "page-spread-right" page,
    // move to the left-hand page and redisplay
    // otherwise, just refresh the existing layout
    const isRightPage =
      this.getPageSide(rendition, rendition.location.end.index) == "page-spread-right";
    if (isRightPage && !rendition.location.atStart) {
      rendition.display(rendition.location.end.index - 1);
    } else {
      rendition.layout(null);
    }
  }
  handleResizeChange(rendition: Rendition, layout: any) {
    if (!rendition || !rendition.location) {
      return;
    }
    if (
      layout.flow == "paginated" &&
      layout.divisor >= 2 &&
      this.numberOfPagesDisplayed(rendition) < 2
    ) {
      this.resetLandscape(rendition);
    }
  }
  handleOrientationChange(rendition: Rendition, orientation: number) {
    // 2022/11/1 AHK: in Chrome, changing orientation from portrait to landscape won't refresh the layout, such
    // that it will continue displaying only one page instead of two. Calling `layout` may (or may not be)
    // an expensive way of doing this. If removing this and rotating back and forth in Chrome on an iPad-sized
    // views shows both pages, we can remove this.
    if (!rendition) {
      return;
    }
    if (
      (Math.abs(orientation) == 90 || Math.abs(orientation) == 270) &&
      this.numberOfPagesDisplayed(rendition) < 2
    ) {
      this.resetLandscape(rendition);
    }
  }
  pageChangeData(rendition: Rendition, studentActivityId: string): PageChangedUpdateData {
    // 2022/10/27 AHK: the types don't seem to be right for views() so we have to cast it to `any`
    const currentSection = (rendition.views() as any).first().section;

    return {
      newSectionIndex: rendition.location.start.index,
      isFirstPage: !currentSection.prev(),
      isLastPage: !currentSection.next(),
      studentActivityId,
      numberOfPagesDisplayed: this.numberOfPagesDisplayed(rendition)
    };
  }
  pageErrorData(
    rendition: Rendition,
    studentActivityId: string,
    errorMsg: string,
    isCriticalError: boolean
  ): PageErrorData {
    let renditionData: PageChangedUpdateData = {
      newSectionIndex: 0,
      isFirstPage: true,
      isLastPage: true,
      studentActivityId: studentActivityId,
      numberOfPagesDisplayed: 0
    };
    if (rendition) {
      try {
        renditionData = this.pageChangeData(rendition, studentActivityId);
      } catch (e) {
        console.warn("Unable to generate page data.");
      }
    }
    return { ...renditionData, error: errorMsg, isCriticalError: isCriticalError };
  }

  numberOfPagesDisplayed(rendition: Rendition): number {
    return rendition.location.end.index - rendition.location.start.index + 1;
  }
  setOnRelocation(rendition: Rendition, callback: Function) {
    rendition.on("relocated", () => {
      callback(rendition);
    });
  }
  getPageData(rendition: Rendition): PageData[] {
    const firstPage: PageData = {
      index: rendition.location.start.index,
      section: (rendition.book.spine.get(rendition.location.start.index) as unknown) as EpubSection,
      iframe: document.querySelector(`.epub-view[ref='${rendition.location.start.index}']>iframe`)
    };
    const secondPage: PageData = {
      index: rendition.location.end.index,
      section: (rendition.book.spine.get(rendition.location.end.index) as unknown) as EpubSection,
      iframe: document.querySelector(
        ".epub-view[ref='" + rendition.location.end.index + "']>iframe"
      )
    };
    return [firstPage, secondPage];
  }
  destroy(rendition: Rendition) {
    rendition.book.destroy();
    rendition.destroy();
  }
}

import Sentry from "javascript/Sentry/Wrapper";

const TARGET_COLOR_MAP = {
  app: "#48197c",
  assessment: "#E25B69",
  item: "#BA16AA",
  items: "#410410",
  question: "#F9A728",
  test: "#389389"
};

export default app => {
  if (process.env.LOG_LEARNOSITY_EVENTS) setupLoggers(app);
  addEventBreadcrumbs(app);
};

// prettier-ignore
const EVENT_TYPES = {
    assessment: ["focused", "item:changed", "item:changing", "item:goto", "item:load", "item:setAttemptedResponse", "item:unload", "item:warningOnChange", "items:fetch", "items:fetch:done", "section:changed", "test:finished:discard", "test:finished:save", "test:finished:submit", "test:pause", "test:reading:end", "test:reading:start", "test:ready", "test:resume", "test:save", "test:save:error", "test:save:success", "test:start", "test:submit", "test:submit:error", "test:submit:success", "test:proctor:exitAndSubmit", "test:proctor:goto", "test:proctor:paused", "test:proctor:resumed", "test:proctor:save", "test:proctor:terminated", "unfocused"],
    question: ["change", "changed", "beforeValidate", "validated", "masked", "playback:assetLoaded", "playback:complete", "playback:paused", "playback:resumed", "playback:started", "playback:stopped", "recording:paused", "recording:resumed", "recording:started", "recording:stopped"],
    feature: ["begin", "complete", "playback:started", "playback:assetLoaded", "playback:complete", "playback:paused", "playback:resumed", "playback:stopped"]
  };

/* Development Loggers */

function setupLoggers(itemsApp) {
  const assessmentLogger = new EventLogger(EVENT_TYPES.assessment, {
    prefix: "assessment",
    applyPrefix: "singles"
  });
  const questionLogger = new EventLogger(EVENT_TYPES.question, {
    prefix: "question",
    applyPrefix: "always"
  });
  const featureLogger = new EventLogger(EVENT_TYPES.feature, {
    prefix: "feature",
    applyPrefix: "always"
  });

  assessmentLogger.bindEvents(itemsApp);

  Object.values(itemsApp.questions()).forEach(question => {
    questionLogger.bindEvents(question);
  });
  Object.values(itemsApp.features()).forEach(feature => {
    featureLogger.bindEvents(feature);
  });
}

/* Sentry Breadcrumb Generator*/

function eventDisplay(eventString, eventCategory, applyPrefix) {
  const segments = eventString.split(":");

  if (applyPrefix === "always" || (applyPrefix === "singles" && !segments[1])) {
    const prefixed = segments.splice(0, -1, eventCategory);
    return segments.join(":");
  } else {
    return eventString;
  }
}

function breadcrumb(message, { level = "debug", ...data } = {}) {
  Sentry.addBreadcrumb({
    message,
    level,
    data,
    category: "learnosity"
  });
}

function addEventBreadcrumbs(itemsApp) {
  if (itemsApp) {
    EVENT_TYPES.assessment.forEach(eventString => {
      itemsApp.on(eventString, () => {
        breadcrumb(eventDisplay(eventString, "assessment", "singles"));
      });
    });

    EVENT_TYPES.question.forEach(eventString => {
      Object.values(itemsApp.questions()).forEach(question => {
        question.on(eventString, () => {
          breadcrumb(eventDisplay(eventString, "question", "always"));
        });
      });
    });

    EVENT_TYPES.feature.forEach(eventString => {
      Object.values(itemsApp.features()).forEach(feature => {
        feature.on(eventString, () => {
          breadcrumb(eventDisplay(eventString, "feature", "always"));
        });
      });
    });
  }
}

class EventLogger {
  constructor(events, { prefix = "learnosity", applyPrefix = "singles" } = {}) {
    this.prefix = prefix;
    this.applyPrefix = applyPrefix;
    this.events = events;
  }

  bindEvents(receiver) {
    this.events.forEach(eventName => {
      receiver.on(eventName, this.logger(eventName));
    });
  }

  logger(eventName) {
    const eventDisplay = this.eventDisplay(eventName);
    const [eventTargetDisplayName, ...eventTypeDisplaySegments] = eventDisplay.split(":");
    const eventTypeDisplayName = eventTypeDisplaySegments.join(":");

    return () => {
      console.log(
        "%c%s %c%s:%c%s",
        "color: #06579E; font-weight: bold;",
        "[Learnosity]",
        `color: ${TARGET_COLOR_MAP[eventTargetDisplayName]}; font-style: italic; font-weight: bold;"`,
        eventTargetDisplayName,
        "font-style: unset; color: unset; font-weight: unset;",
        eventTypeDisplayName
      );
    };
  }

  eventDisplay(eventType) {
    const segments = eventType.split(":");

    if (this.applyPrefix === "always" || (this.applyPrefix === "singles" && !segments[1])) {
      const prefixed = segments.splice(0, -1, this.prefix);
      return segments.join(":");
    } else {
      return eventType;
    }
  }
}

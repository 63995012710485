import Loggable from "../../Loggable";
import * as s from "./../util/selectors";
import * as c from "javascript/Learnosity/util/constants";

export default class ResponseDom extends Loggable {
  constructor(question) {
    super("learnosity - response dom");

    if (!question) { this.log("missing question data", { level: "warn" }); } // prettier-ignore

    this.question = question;
    this.node = s.selectInQuestion(question.getId(), c.RESPONSE_WRAPPER_SELECTOR);
  }

  prepare() {
    this.question.getOptions().forEach(
      (option => {
        const authoredSourceElement = option.getAuthoredSourceElement(); // this has the markup we need
        const optionElement = s.selectResponseOptionInQuestion(
          this.question.getId(),
          option.getValue()
        );

        if (!optionElement || !authoredSourceElement) { return; } // prettier-ignore

        if (authoredSourceElement.hasAudioOverride()) {
          this._addHaoData(
            optionElement,
            this._extractHaoFromAuthoredSource(authoredSourceElement)
          );
        }

        const optionContent = s.selectContentForResponseOption(optionElement);
        optionContent && (optionContent.innerHTML = authoredSourceElement.innerHTML);
      }).bind(this)
    );

    const questionElement = s.selectQuestion(this.question.getId());
    this._updateQuestionHaoData(questionElement, this.question.getOptions());
  }

  _updateQuestionHaoData(questionElement, options) {
    questionElement.classList.toggle(
      c.HAS_HAO_OPTIONS_CLASS,
      options.some(option => option.getAuthoredSourceElement().hasAudioOverride())
    );
  }

  _extractHaoFromAuthoredSource(sourceElementWithHAO) {
    return sourceElementWithHAO.querySelector(c.AUDIO_OVERRIDE_SRC_SELECTOR).dataset.src;
  }

  _addHaoData(optionElement, haoUrl) {
    const questionElement = s.selectQuestion(this.question.getId());
    questionElement.classList.add(c.HAS_HAO_OPTIONS_CLASS);

    optionElement.dataset.audioOverride = "true";
    optionElement.setAttribute("override", haoUrl);
  }
}

export default function setupScrollingPorts(ports) {
  ports.scrollTo.subscribe(selector => {
    window.requestAnimationFrame(() => {
      const element = document.querySelector(selector);
      if (element) {
        const boundingRect = element.getBoundingClientRect();
        const bottomOfElement = boundingRect.y + boundingRect.height;
        // scroll to the element if its bottom isn't visible given the currently visible portion
        // currently this doesn't take into account the possibility the element might be *above*
        // the scrolled area -- but that would be easy to add.
        if (window.scrollY + window.innerHeight < bottomOfElement) {
          document.body.scrollTo({ top: bottomOfElement, left: 0, behavior: "smooth" });
        }
      }
    });
  });
}

interface StudentActivityMessageListenerPorts {
  processWebActivityWindowMessage: PortToElm<{
    data: any;
    origin: string;
    studentActivityId: string;
  }>;
  listenForWebActivityWindowMessage: PortFromElm<string>;
}

export default function setupStudentActivityMessageListenerPorts(
  ports: StudentActivityMessageListenerPorts
): void {
  ports.listenForWebActivityWindowMessage.subscribe(studentActivityId => {
    window.addEventListener("message", function(event: MessageEvent): void {
      ports.processWebActivityWindowMessage.send({
        data: JSON.parse(event.data),
        origin: event.origin,
        studentActivityId
      });
    });
  });
}

import { DESTINATIONS } from "../Logger";
import RecordRTC from "recordrtc";

// This class manages the process of recording a video using the browser.
// Available settings:
// * recordingFPS: how many frames per second to record in video. The higher this gets, the
//                 smoother the video but the more space it requires.
// * facingMode: "user" (front) or "environment" (rear) camera
export default class VideoRecorder {
  constructor(settings) {
    this.settings = settings || {};
    this.settings.facingMode = this.settings.facingMode || "user";

    this.setStatus("loading");

    this.record = this.record.bind(this);
    this.init = this.init.bind(this);
  }

  setStatus(status, value = null) {
    this.status = status;
    if (this.settings.onStatusChange) {
      this.settings.onStatusChange({
        status,
        value
      });
    }
  }

  element() {
    return document.querySelector(this.settings.selector);
  }

  getPermittedCamera(mediaDevices) {
    const mediaOptions = {
      video: {
        facingMode: this.settings.facingMode,
        // matches iOS: https://espark.slack.com/archives/CAD5LR49G/p1549644877018100
        width: 480,
        height: 360
      },
      frameRate: this.settings.recordingFPS || 15,
      audio: true
    };

    return mediaDevices
      .getUserMedia(mediaOptions)
      .then(stream => {
        if (navigator.permissions && navigator.permissions.query) {
          return navigator.permissions.query({ name: "camera" }).then(permission => {
            const isGranted = permission.state === "granted";
            return isGranted || window.isFakeCamera ? stream : null;
          });
        } else {
          console.log("[Video Recording] Browser does not support permission checking");
          // 2021/09/30 AHK: Safari doesn't allow us to check permissions, so we have to assume that
          // if we get to this point in the app, it works. If not, it will fail downstream and the
          // student will move on.
          return stream;
        }
      })
      .catch(e => {
        console.warn("[Video Recording] Got error working with stream", e);
        // 2022/10/24 throw the error again to be handled normally
        throw e;
      });
  }

  init(
    mediaDevices = window.mockMediaDevices || navigator.mediaDevices,
    MutationObserver = window.MutationObserver
  ) {
    if (!this.mutationObserver) {
      this.mutationObserver = new MutationObserver(() => {
        const node = this.element();
        if (!node) {
          this.destroy();
        }
      });
      this.mutationObserver.observe(document.body, { childList: true, subtree: true });
    }

    return this.getPermittedCamera(mediaDevices)
      .then(stream => {
        if (this.status === "destroyed") {
          this.logMessage("VideoRecordingError: already destroyed, should not be initializing");
          return;
        }

        if (!stream) {
          return this.setStatus("camera-not-available", { on_init: true });
        }

        this.stream = stream;

        return this.liveCamera(stream).then(() => {
          navigator.mediaDevices.enumerateDevices().then(devices => {
            const numberOfCameras = devices.filter(d => d.kind == "videoinput").length;
            this.setStatus("ready", { numberOfCameras, on_init: true });
          });
        });
      })
      .catch(err => {
        console.warn("[Video Recording] Error in camera init", err);
        if (
          err.message.includes("Permission denied") ||
          err.message.includes("user denied permission")
        ) {
          this.setStatus("camera-not-available");
        } else {
          global.Sentry && global.Sentry.captureException(err);
          this.setStatus("errored", { on_init: true, error: err.message || err });
          this.logMessage("VideoRecordingError: getPermittedCamera failed", err);
        }
      });
  }

  liveCamera(stream) {
    const node = this.element();
    node.src = null;
    node.srcObject = stream;
    node.play();
    return new Promise((resolve, reject) => {
      node.addEventListener("playing", resolve);
      node.addEventListener("error", reject);
    });
  }

  recordingOptions() {
    const mimeType = ["video/mpeg", "video/webm;codecs=h264", "video/webm"].find(codec =>
      MediaRecorder.isTypeSupported(codec)
    );

    return {
      type: "video",
      recorderType: RecordRTC.MediaStreamRecorder,
      mimeType: mimeType,
      fileExtension: "mp4",

      // Callback every 1000ms
      timeSlice: 1000,
      onTimeStamp: timestamp => {
        this.setStatus("recording", { recordingDuration: timestamp - this.startedRecordingAt });
      }
    };
  }

  record() {
    if (this.status !== "ready") {
      this.logMessage("VideoRecordingError: trying to record when not at ready status");
      return;
    }

    this.recorder = RecordRTC(this.stream, this.recordingOptions());
    this.startedRecordingAt = Date.now();

    // the media recorder can sometimes receive an empty value as an error and crash; we need to
    // handle that.
    const originalOnError = this.recorder.onerror;
    this.recorder.onerror = error => {
      console.warn("[Video Recording] Got recorder error", error);
      error = error || {};
      error.name = error.name || "[No Error Provided]";
      this.logMessage("VideoRecordingError: RecordRTC.onerror", error);
      return originalOnError(error);
    };

    this.recorder.startRecording();
    this.setStatus("recording", { recordingDuration: 0 });
  }

  stop() {
    if (this.status !== "recording") {
      this.logMessage("VideoRecordingError: trying to stop when not in recording status");
      return Promise.reject("Cannot stop: not recording!");
    }

    return new Promise(resolve => {
      this.recorder.stopRecording(resolve);
    })
      .then(() => {
        const blob = this.recorder.getBlob();
        this.setStatus("recorded", { dataUrl: URL.createObjectURL(blob) });

        this.destroyRecorder();

        // pass on the blob so that we can upload it as directed
        return blob;
      })
      .catch(err => {
        this.logMessage("VideoRecordingError: stopRecording failed", err);
      });
  }

  reset() {
    if (this.status !== "recorded") {
      this.logMessage("VideoRecordingError: trying to reset when not in recorded status");
      return;
    }

    this.destroyRecorder();

    this.setStatus("ready");
  }

  flipCamera() {
    this.settings.facingMode = this.flippedFacingMode();
    this.init();
  }

  flippedFacingMode() {
    if (!this.stream) {
      return "user";
    }

    const videoTrack = this.stream.getVideoTracks()[0];
    if (!videoTrack) {
      return "user";
    }

    // For devices with two cameras, this will give us the current value, from which we can figure
    // out what to flip to. For single-camera devices like Macbooks, this will return null, in
    // which case we always set the user-facing camera.
    const currentFacingMode = videoTrack.getCapabilities().facingMode[0];
    return currentFacingMode === "user" ? "environment" : "user";
  }

  destroyRecorder() {
    if (!this.recorder) {
      return;
    }

    this.recorder.destroy();
    this.recorder = null;
  }

  destroyCamera() {
    if (!this.stream) {
      return;
    }
    this.stream.getTracks().forEach(track => track.stop());
  }

  destroy() {
    if (this.status === "destroyed") {
      return;
    }

    this.mutationObserver.disconnect();
    this.destroyRecorder();
    this.destroyCamera();

    if (this.settings.onDestroy) {
      this.settings.onDestroy();
    }

    this.logMessage("VideoRecordingDestroyed");
    this.setStatus("destroyed");
  }

  logMessage(message, error = null) {
    this.settings.logger.eventReceived({
      message,
      data: {
        error: (error && error.message) || error,
        student_quest_id: this.settings.studentQuestId
      },
      tags: [],
      destinations: [DESTINATIONS.REDSHIFT]
    });
  }
}

import Loggable from "javascript/Loggable";

/*
  During assessment initialization, MathJax inserts at least two DOM nodes (MathJax_Message, MatchJax_Hidden)
  that interfere with Elm's DOM diffing. We need to move those nodes to the bottom of the DOM so that Elm effectively
  ignores them.
*/

export default class MathJaxNodeObserver extends Loggable {
  static config = {
    attributes: false,
    childList: true,
    subtree: false
  };

  constructor() {
    super("learnosity mathjax");

    this.observer = new MutationObserver(mutationsList => {
      if (
        document.body.firstElementChild &&
        !document.body.firstElementChild.classList.contains("main")
      ) {
        this.log("Moving node...", { nodes: mutationsList.addedNodes });
        document.body.appendChild(document.body.firstElementChild);
      }
    });
  }

  observe() {
    this.log("Starting to observe for nodes added above our main app node.");
    this.observer.observe(document.body, MathJaxNodeObserver.config);
  }

  disconnect() {
    this.log("No longer checking for added nodes.");
    this.observer.disconnect();
  }
}

import VideoRecorder from "javascript/VideoRecording/VideoRecorder.js";
import Uploader from "javascript/VideoRecording/Uploader.js";
import PortsManager from "javascript/PortsManager";
import { DESTINATIONS } from "../Logger";
import VideoStorageManager from "javascript/VideoRecording/VideoStorageManager.js";
import Sentry from "javascript/Sentry/Wrapper";

export default function setupVideoRecordingPorts(ports, logger) {
  const logMessage = (message, studentQuestId = null, error = null) => {
    logger.eventReceived({
      message,
      data: {
        error: (error && error.message) || error,
        student_quest_id: studentQuestId
      },
      tags: [],
      destinations: [DESTINATIONS.REDSHIFT]
    });
  };

  ports.startVideoActivity.subscribe(([videoUploadUrlAsJSON, studentQuestId]) => {
    const videoUploadUrl = JSON.parse(videoUploadUrlAsJSON);
    const recorder = new VideoRecorder({
      selector: "#video",
      onStatusChange: ports.videoRecordingStatusUpdate.send,
      logger,
      studentQuestId
    });
    // delay slightly to ensure the element is on screen
    setTimeout(recorder.init, 100);

    const portManager = new PortsManager(ports)
      .subscribe("videoRecordingAction", action => {
        switch (action) {
          case "record":
            return recorder.record();
          case "stop":
            return recorder
              .stop()
              .then(blob => {
                VideoStorageManager.storeVideo(studentQuestId, blob)
                  .then(() => {
                    console.log("[Video Recording] Video persisted to indexedDB");
                    ports.storedStudentVideoPersisted.send(studentQuestId);
                  })
                  .catch(err => {
                    console.warn("[Video Recording] Error persisting recorded video", err);
                    logMessage("VideoRecordingError: storeVideo failed", studentQuestId, err);
                    Sentry.captureException(err);
                  });
              })
              .catch(err => {
                console.warn("[Video Recording] Error processing recorded video", err);
                logMessage("VideoRecordingError: recorder.stop failed", studentQuestId, err);
                Sentry.captureException(err);
              });
          case "flip-camera":
            return recorder.flipCamera();
          default:
            console.log(`[Video Recording] Received unknown videoRecordingAction '${action}'`);
        }
      })
      // TODO: this should receive both videoDataBlobUrl and uploadUrl, then it won't need to live as a sibling here
      .subscribe("uploadVideo", videoDataBlobUrl => {
        console.log("[Video Recording] Starting upload");
        return Uploader.upload(videoUploadUrl, videoDataBlobUrl)
          .then(ports.uploadVideoCompleted.send)
          .catch(err => {
            if (!Uploader.wasCanceled(videoUploadUrl)) {
              ports.uploadVideoFailed.send(err.message);
              logMessage("VideoRecordingError: Uploader.upload failed", studentQuestId, err);
            }
          });
      })
      .subscribe("cancelUploadVideo", videoDataBlobUrl => {
        VideoStorageManager.removeVideo(studentQuestId);
        Uploader.cancel(videoUploadUrl);
      });

    recorder.settings.onDestroy = () => portManager.unsubscribeAll();

    return recorder;
  });

  ports.replayRecordedStudentVideo.subscribe(async elementId => {
    const videoElement = document.getElementById(elementId);
    if (!videoElement) {
      console.warn(
        "[Video Recording] Was not able to find video element to replay student video, id:",
        elementId
      );
      logMessage("VideoRecordingError: replayRecordedStudentVideo elment not found");
      return;
    }

    try {
      videoElement.muted = false;
      videoElement.currentTime = 0;
      await videoElement.play();
    } catch (e) {
      console.warn("[Video Recording Playback] Error", e);
      logMessage("VideoRecordingError: replayRecordedStudentVideo playback error", null, e);
      Sentry.captureException(e, { extra: { action: "video_replay" } });
    }
  });

  ports.retrieveStoredStudentVideos.subscribe(() => {
    console.log("[Video Recording] Getting stored student videos");
    try {
      VideoStorageManager.getStoredVideos().then(studentQuestIds => {
        console.log("[Video Recording] Found stored videos for student quests:", studentQuestIds);
        ports.storedStudentVideosIdentified.send(studentQuestIds);
      });
    } catch (e) {
      console.warn("[Video Recording] Error retrieving stored videos", e);
      logMessage("VideoRecordingError: retrieveStoredStudentVideos failed", null, e);
      Sentry.captureException(e);
    }
  });

  ports.uploadStoredStudentVideo.subscribe(([studentQuestId, videoUploadUrlAsJSON]) => {
    const videoUploadUrl = JSON.parse(videoUploadUrlAsJSON);
    return VideoStorageManager.getVideo(studentQuestId).then(blob => {
      if (blob) {
        return Uploader.upload(videoUploadUrl, URL.createObjectURL(blob))
          .then(() => {
            ports.storedStudentVideoUploadComplete.send(studentQuestId);
          })
          .catch(err => {
            // we've seen that if a video gets deleted, a reference can remain in IndexDB that
            // can't be fetched and results in an infinite loop.
            if (err instanceof Uploader.UnableToFetchStoredVideo) {
              console.warn(
                "[Video Recording] Unable to retrieve stored video contents, removing video"
              );
              VideoStorageManager.removeVideo(studentQuestId);
              ports.storedStudentVideoCouldNotBeRetrieved.send(studentQuestId);
            } else {
              console.warn("[Video Recording] Stored video upload failure!", err);
              ports.storedStudentVideoUploadFailed.send([studentQuestId, JSON.stringify(err)]);
            }
            logMessage(
              "VideoRecordingError: uploadStoredStudentVideo Uploader.upload failed",
              studentQuestId,
              err
            );
          });
      } else {
        ports.storedStudentVideoNotFound.send(studentQuestId);
        logMessage("VideoRecordingError: uploadStoredStudentVideo not found", studentQuestId);
      }
    });
  });

  ports.removeStoredStudentVideo.subscribe(studentQuestId => {
    VideoStorageManager.removeVideo(studentQuestId).then(() =>
      ports.storedStudentVideoRemoved.send(studentQuestId)
    );
  });
}

import { Rendition } from "epubjs";
import AudioDOMManager from "./AudioDOMManager";
import AudioHighlighter from "./AudioHighlighter";
import EpubManager, { PageChangedUpdateData, PageData, PageErrorData } from "./EpubManager";
import EpubAudioParser from "./EpubAudioParser";

interface EbookConfiguration {
  origin: string;
  studentActivityId: string;
  iframeId: string;
  activityUrl: string;
  clientUrl: string;
}

interface EsparkIframeUpdatePorts {
  changeToPrevPage: PortFromElm<null>;
  changeToNextPage: PortFromElm<null>;
  pageHasRendered: PortToElm<string>;
  initEbookReader: PortFromElm<EbookConfiguration>;
  pageChanged: PortToElm<PageChangedUpdateData>;
  toggleAudio: PortFromElm<null>;
  displayChanged: PortToElm<PageChangedUpdateData>;
  pageErrored: PortToElm<PageErrorData>;
  exitActivity: PortFromElm<null>;
}

export default function setupEbookReader(ports: EsparkIframeUpdatePorts): void {
  ports.initEbookReader.subscribe(({ studentActivityId, activityUrl, clientUrl }) => {
    window.requestAnimationFrame(async () => {
      const epubManager = new EpubManager();
      const errorHandlerEmptyRendition = function(errorMsg: string, isCritical: boolean) {
        let emptyData: PageChangedUpdateData = {
          newSectionIndex: 0,
          isFirstPage: true,
          isLastPage: true,
          studentActivityId: studentActivityId,
          numberOfPagesDisplayed: 0
        };
        return { ...emptyData, error: errorMsg, isCriticalError: isCritical };
      };

      if (!activityUrl.includes("opf")) {
        ports.pageErrored.send(
          errorHandlerEmptyRendition("Invalid activity url:" + activityUrl + ".", true)
        );
        return;
      }

      const errorHandler = function(rendition: Rendition, errorMsg: string, isCritical: boolean) {
        ports.pageErrored.send(
          epubManager.pageErrorData(rendition, studentActivityId, errorMsg, isCritical)
        );
      };
      const pageRendered = function() {
        ports.pageHasRendered.send(studentActivityId);
      };

      const result = await epubManager
        .init(activityUrl, clientUrl, pageRendered)
        .catch((error: Error) => {
          console.error(error);
          errorHandlerEmptyRendition(error.message, true);
        });
      if (!result) {
        console.error("Result is empty.");
        errorHandlerEmptyRendition("Book result is empty.", true);
        return;
      }
      const rendition: Rendition = result.rendition;
      const errorHandlerWithRendition = function(errorMsg: string, isCritical: boolean) {
        errorHandler(rendition, errorMsg, isCritical);
      };

      const audioParser = new EpubAudioParser(errorHandlerWithRendition);
      const audioDOMManager = new AudioDOMManager(
        "ereader-audio-player-button",
        "ereader-audio",
        errorHandlerWithRendition
      );
      let audioHighlighter = new AudioHighlighter([], audioDOMManager, errorHandlerWithRendition);
      // Whenever the ebook changes its display, it could have changed which pages are visible.
      // We need to recalculate the audio on the page.
      //
      // We do not need to track the resize event -- relocated fires after the resizing is complete (whereas
      // resize may be fired multiple times as the page is being resized). We also do not need to track orientationchange,
      // because that also fires a relocated event.
      //
      // 2022/10/28 AHK: TODO: we should track the previous and new locations and compare -- if nothing has changed we should avoid
      // the potentially expensive recalculation of audio.
      epubManager.setOnRelocation(rendition, function() {
        const pageData = epubManager.getPageData(rendition);
        resetAudio(
          pageData,
          audioParser,
          audioDOMManager,
          audioHighlighter,
          errorHandlerWithRendition
        )
          .then(highlighter => {
            audioHighlighter = highlighter;
            ports.displayChanged.send(epubManager.pageChangeData(rendition, studentActivityId));
          })
          .catch((error: Error) => {
            console.error(error);
            errorHandlerWithRendition(error.message, false);
          });
      });

      ports.toggleAudio.subscribe(() => {
        audioHighlighter.toggleAudio();
      });

      const reportPageChanged = () => {
        ports.pageChanged.send(epubManager.pageChangeData(rendition, studentActivityId));
      };

      ports.changeToNextPage.subscribe(async () => {
        await disableAudio(audioDOMManager, audioHighlighter).catch((error: Error) => {
          console.error(error);
          errorHandlerWithRendition(error.message, false);
        });
        rendition.next().then(() => {
          reportPageChanged();
        });
      });

      ports.changeToPrevPage.subscribe(async () => {
        await disableAudio(audioDOMManager, audioHighlighter).catch((error: Error) => {
          console.error(error);
          errorHandlerWithRendition(error.message, false);
        });
        rendition.prev().then(() => {
          reportPageChanged();
        });
      });
      ports.exitActivity.subscribe(async () => {
        await audioHighlighter.disable().catch((error: Error) => {
          console.error(error);
          errorHandlerWithRendition(error.message, false);
        });
        epubManager.destroy(rendition);
      });
    });
  });
}
/**
 * cleanup to make sure that multiple highlighters don't run when pages change
 * @param manager
 * @param highlighter
 */
function disableAudio(manager: AudioDOMManager, highlighter: AudioHighlighter): Promise<boolean> {
  manager.disableAudio();
  return highlighter.disable();
}
/**
 * Create new audio highlighter, assuming the audio from the last page was disabled
 * @param pages
 * @param audioParser
 * @param audioDOMManager
 * @param onError
 * @returns
 */
function resetAudio(
  pages: PageData[],
  audioParser: EpubAudioParser,
  audioDOMManager: AudioDOMManager,
  audioHighlighter: AudioHighlighter,
  onError: (errorMsg: string, isCritical: boolean) => void
): Promise<AudioHighlighter> {
  return disableAudio(audioDOMManager, audioHighlighter).then(() => {
    return audioParser.parseAudioFromEpub(pages).then(epubAudio => {
      if (epubAudio.audio.length <= 0 || !audioDOMManager.getAudioElement()) {
        return new AudioHighlighter([], audioDOMManager, onError);
      }
      let audioHighlighter = new AudioHighlighter(epubAudio.audio, audioDOMManager, onError);
      const onLoaded = function() {
        audioHighlighter.resetAudio(0);
      };
      const canPlay = function() {
        audioDOMManager.enableAudio();
      };

      audioDOMManager.loadAudio(epubAudio, onLoaded, canPlay);
      return audioHighlighter;
    });
  });
}

import Sentry from "javascript/Sentry/Wrapper";
import * as dom from "javascript/util/dom";
import * as c from "javascript/Learnosity/util/constants";
import * as s from "javascript/Learnosity/util/selectors";

export const currentQuestion = itemsApp => {
  const currentItem = itemsApp.getCurrentItem();
  if (!currentItem) {
    // give us better information if there is no current item
    console.log("Learnosity error: no current item loaded 🤬");
    captureLearnosityException("FailedToIdentifyCurrentLearnosityItem", itemsApp);
    throw new Error("FailedToIdentifyCurrentLearnosityItem");
  }
  // needs to change this if our items may have more than one question.
  const questionData = currentItem.questions[0];
  const ref = questionData.response_id;
  return itemsApp.question(ref);
};

export const breadcrumb = (message, data = {}) => {
  Sentry.addBreadcrumb({
    message,
    data,
    category: "learnosity"
  });
};

export const captureLearnosityException = (err, itemsApp) => {
  const tagData = { lrnHasItemsApp: Boolean(itemsApp) };
  if (tagData.lrnHasItemsApp && itemsApp.getItems && typeof itemsApp.getItems === "function") {
    const items = itemsApp.getItems();
    const responses = itemsApp.getResponses();
    if (items && responses) {
      tagData.lrnItemCount = Object.keys(items).length;
      const responseCount = Object.keys(responses).length;
      // ratio because we want to see if errors are happening on 1/1, 5/5, 10/10 or on incomplete ratios.
      tagData.lrnResponseRatio = responseCount.toString() + "/" + tagData.lrnItemCount.toString();
    } else {
      tagData.lrnItemCount = 0;
    }
  }
  Sentry.captureException("Lrn: " + err, { tags: tagData });
};

export const hideQuizLoader = () => {
  const quizLoader = document.querySelector(c.SELECTOR.LOADER);

  if (dom.elemExists(quizLoader)) {
    const parentWrapper = quizLoader.parentElement;
    if (parentWrapper && parentWrapper.classList.contains(c.CLASS.LOADER_WRAPPER)) {
      parentWrapper.classList.add("is-hidden");
    }
  }
};

const mathJaxFormatEndedEvent = new Event("mathJaxFormatEnded", { cancelable: true });

const dispatchMathJaxFormattedEvent = elemId => {
  const element = document.getElementById(elemId);
  if (dom.elemExists(element)) element.classList.add(c.ES_MATHJAX_FORMATTED_SELECTOR);
  document.dispatchEvent(mathJaxFormatEndedEvent);
};

export function formatMathematicalExpressions(elementId) {
  if (global.MathJax) {
    const element = document.getElementById(elementId);
    // for some reason, MathJax is not rendering on its own, so we force it to render
    if (element && !element.querySelector(c.SELECTOR.MATHJAX_PREVIEW)) {
      global.MathJax.Hub.Queue(
        ["Typeset", MathJax.Hub, elementId],
        addTTSIgnoreToMathJaxDisplayElements,
        sanitizeMathJaxSourceElements,
        [dispatchMathJaxFormattedEvent, elementId]
      );
    }
  } else {
    // if this page doesn't use MathJax, immeediaetly dispatch the event
    dispatchMathJaxFormattedEvent(elementId);
  }
}

export function formatMathMathematicalExpressionsInAssessment() {
  formatMathematicalExpressions(c.ID.LEARNOSITY_CONTAINER);
  formatMathematicalExpressions(c.ID.DISTRACTOR_RATIONALE);
}

export function adjustLearnosityButtonHeight(getCurrentItem = () => {}) {
  try {
    const button = document.querySelector(c.SELECTOR.ACTION_BUTTON);
    const currentItemRef = getCurrentItem() && getCurrentItem().reference;
    const responseWrapper = s.selectInItem(currentItemRef, c.SELECTOR.RESPONSE_WRAPPER);
    const lrnQuestion = s.selectInItem(currentItemRef, c.SELECTOR.LRN_QUESTION);
    const lrnWrapper = document.getElementById(c.ESPARK_LEARNOSITY_CONTENT_WRAPPER_ID);
    const rationale = document.getElementById(c.ID.DISTRACTOR_RATIONALE);
    const topBarStudentName = document.querySelector(c.SELECTOR.TOP_BAR_STUDENT_NAME);
    const lrnPassage = s.selectInItem(currentItemRef, c.PASSAGE_CONTAINER_SELECTOR);
    const outerContainerPadding = 16;

    if (dom.elemExists(button) && dom.elemExists(responseWrapper)) {
      let baseHeight = responseWrapper.clientHeight;
      if (rationale) {
        baseHeight = baseHeight + rationale.clientHeight;
      }
      // outerContainerPadding is the padding on the outer container, so we want to subtract the top and bottom padding so the
      // appears as if its inside.
      const computedHeightOfButton = baseHeight - outerContainerPadding * 2;
      // adding passage height to learn question height
      const lrnQuestionHeight =
        lrnQuestion.clientHeight + (dom.elemExists(lrnPassage) ? lrnPassage.clientHeight : 0);
      const responseHeightVisibleInViewport = window.innerHeight - lrnQuestionHeight;
      const topBarStudentNameHeight = topBarStudentName.clientHeight;
      const minimumButtonHeightInPixels = 160;
      let buttonStyles;

      // Use the computed button height when the height of the button is smaller than the response container in view
      // otherwise if the computed button height is greater than the response container in view we want to ensure
      // the button does not grow larger the overall assessment container
      if (computedHeightOfButton < responseHeightVisibleInViewport) {
        buttonStyles = {
          height: `${computedHeightOfButton}px`,
          position: "absolute",
          right: `${outerContainerPadding}px`,
          top: "auto",
          bottom: `${outerContainerPadding}px`
        };
      } else {
        // If the question stimulus is in the viewport, the button needs to account for question stimulus's container height.
        // The height of the button needs to be reduced so it can fit fully in the response container.
        // If the new button is less than the minimumButtonHeightInPixels value then the
        // button height is it to the minimum button height defined
        if (lrnWrapper.scrollTop < lrnQuestionHeight - outerContainerPadding * 2) {
          let newButtonHeight = window.innerHeight - (lrnQuestionHeight - lrnWrapper.scrollTop);
          newButtonHeight =
            newButtonHeight > minimumButtonHeightInPixels
              ? newButtonHeight - (topBarStudentNameHeight + outerContainerPadding)
              : minimumButtonHeightInPixels;
          // Padding for the top of the button relative to bottom of question stimulus height.
          // Passage questions and multiple choice questions are styled differently so padding will be different
          let additionalTopPadding = dom.elemExists(lrnPassage) ? -10 : 7;
          buttonStyles = {
            height: `${newButtonHeight}px`,
            position: "absolute",
            right: `${outerContainerPadding}px`,
            top: `${lrnQuestionHeight + topBarStudentNameHeight + additionalTopPadding}px`,
            bottom: "0px"
          };
        }
        // Question stimulus is not in the viewport, button can grow the full height of the window with adjustments.
        // Button grows the height of the window minus height of the student name bar
        // and the padding on the outer container (top and bottom).
        else {
          buttonStyles = {
            height: `${window.innerHeight -
              (topBarStudentNameHeight + outerContainerPadding * 2)}px`,
            position: "fixed",
            // positioning the fixed version of the button
            right: `${30}px`,
            top: `${topBarStudentNameHeight + outerContainerPadding}px`,
            bottom: "0px"
          };
        }
      }

      transformButtonSizeAndPosition(button, buttonStyles);
    }
  } catch (e) {
    console.log("couldn't adjust button height");
  }
}

/*
# Handling question state transitions


Learnosity funnels all changes to a given question's state through the `question:changed` event. This is the hook where we determine what should be done, especially as to whether we want to sync the quiz state with elm.

We maintain and transition the state of the learnosity question by updating the metadata of the learnosity response. We store the validation state and the currently chosen answers.

```javascript
{ validationState : "" | "unanswered" | "notvalidated" | "validated",
  currentAnswers : String
}
```

The currentAnswers are saved whenever the validationState is updated. This is so we can detect when the selected answers have changed. We need to do this, as learnosity does not specify what changed when the `question:changed` event occurs.

When the `question:changed` event occurs, we either update the question's metadata, which will trigger a new `question:changed` event, or we synchronize the state with elm. This is done by an event-based recursion mechanism, relying on the fact that changing the metadata will trigger the `question:changed` handler to be handled by the new state.

The general decision logic for what happens when a question changes:

## question data is changed

In this case, we start by asking the high-level state of the question:

### The question is in the Validated state:

In this case, we know that the UI is in a disabled state, and the student cannot change the selected answers. The elm app, in this case, is showing the next button, which triggers either a Retry or a Go To Next Question.

### The question is in the Not Validated state:

In this case, we have a couple scenarios.

If the question has no response, generally occurring when the user drags a multiple choice answer back out into the bin, so there is no answer selected. In this case, we are in the same situation as when there is no answer at all, so we update the metadata to set the question back to `notanswered`.
This will trigger the `question:changed` event, to be handled by the `notanswered` state handler.

If the question has a response, then we look to see if the event has been triggered because the student has selected another answer. We check this by looking at the metadata to see if the current set of answers is the same as the saved, previous set of answers. If so, then we know that the student is still answering. Since we want to keep track of the current answers selected, we update the metadata's `currentAnswers` field, maintaining the state as `notvalidated`.
This will trigger the `question:changed` event, to be handled by the `notvalidated` state handler.

If the question has not changed the set of current answers, then we are in a static situation and synchronize the state to elm.

### The question is in the Not Answered state:

If the set of current answers has changed. If it has, then we know that the student has chosen an answer, so we need to switch to the notvalidated state.
This will trigger the `question:changed` event, to be handled by the `notvalidated` state handler.

If the set of current answers has not changed, then we are in a static state, so we synchronize the state to elm.

### The question is in an unknown state:

This can happen if the question is initially answers, and we do not have the metadata set. In this case, we set the question to the`notvalidated` state.
This will trigger the `question:changed` event, to be handled by the `notvalidated` state handler.


## Retrying

When a student hits the try button, then we need to move back to the unanswered state, as the student needs to select a different answer. In the `retryLearnosityQuestion` port subscription handler, we set the current question's state to `unanswered`.
*NOTE* It is important to understand here that there may be an answered selected, as retrying maintains the previously selected answer. However, even through their is an answer selected, we still consider this reverting to the base state of `unanswered`.
This will trigger the `question:changed` event, to be handled by the `unanswered` state handler.


# Synchronization Data

When synchronizing the state to elm, 3 learnosity structures are retrieved and compiled into an object which is transferred to and decoded by the elm app.

```javascript
{
  items: <result of itemsApp.getItems()>,
  responses: <result of itemsApp.getResponses()>,
  scores: <result of itemsApp.getScores()>
}
```

This structure is sent through a common port `learnosityAnswersStateChanged` to the elm app. This triggers the `LearnosityAnswersStateChanged` message on the elm side.
*/

const DEFAULT_UNSET_QUESTION_RESPONSE_VALUE = "";
const VALIDATION_STATE = {
  UNANSWERED: "unanswered",
  NOT_VALIDATED: "notvalidated",
  VALIDATED: "validated"
};

export const assessmentState = itemsApp => {
  return itemsApp.getActivity().state;
};

export const handleQuestionStateChanged = (question, itemsApp, ports) => {
  if (questionValidated(question)) {
    synchronizeLearnosityQuizStateToElm(itemsApp, ports);
  } else if (questionNotValidated(question)) {
    if (questionHasNoResponse(question)) {
      setResponseValidationStateUnanswered(question);
    } else if (questionResponsesChanged(question)) {
      setResponseValidationStateNotValidated(question);
    } else {
      synchronizeLearnosityQuizStateToElm(itemsApp, ports);
    }
  } else if (questionUnanswered(question)) {
    if (questionResponsesChanged(question)) {
      setResponseValidationStateNotValidated(question);
    } else {
      synchronizeLearnosityQuizStateToElm(itemsApp, ports);
    }
  } else {
    setResponseValidationStateNotValidated(question);
  }
};

export const learnosityItemsState = itemsApp => {
  const scores = itemsApp.getItemScores();
  const items = itemsApp.getItems();
  const responses = itemsApp.getResponses();
  let tags = itemsApp.getTags();
  // If there are no tags, Learnosity will return an empty Array, if there is more than one tag Learnosity returns an object
  tags = tags instanceof Array ? {} : tags;
  return { scores, items, responses, tags };
};

export const setResponseValidationStateValidated = question => {
  if (!question.getResponse()) {
    return;
  }
  question.response.setMetadata({
    validationState: VALIDATION_STATE.VALIDATED,
    currentAnswers: getQuestionResponseValue(question)
  });
};

export const showQuizQuestionResults = question => {
  const shouldShowCorrectAnswers = question.getQuestion().type === "mcq";
  question.validate({ showCorrectAnswers: shouldShowCorrectAnswers });
  question.disable();
};

export const loadQuizQuestionResultsIfAnswered = itemsApp => {
  const question = currentQuestion(itemsApp);
  if (questionHasBeenAnsweredAndNoRetriesRemain()) {
    showQuizQuestionResults(question);
  }
};

export const setResponseValidationStateUnanswered = question => {
  if (!question.getResponse()) {
    return;
  }
  question.response.setMetadata({
    validationState: VALIDATION_STATE.UNANSWERED,
    currentAnswers: getQuestionResponseValue(question)
  });
};

export const synchronizeLearnosityQuizStateToElm = (itemsApp, ports) => {
  const quizState = learnosityItemsState(itemsApp);
  ports.learnosityAnswersStateChanged.send(quizState);
};

// INTERNAL

const questionHasBeenAnsweredAndNoRetriesRemain = () => {
  const learnosityContentContainerV1 = document.getElementById("learnosity-content");
  const learnosityContentContainerV2 = document.getElementById(
    c.ESPARK_LEARNOSITY_CONTENT_WRAPPER_ID
  );
  return (
    (dom.elemExists(learnosityContentContainerV1) &&
      learnosityContentContainerV1.classList.contains("lrn-es-question-answered")) ||
    (dom.elemExists(learnosityContentContainerV2) &&
      learnosityContentContainerV2.classList.contains("lrn-es-question-answered"))
  );
};

const questionNotValidated = question => {
  return getQuestionValidationState(question) === VALIDATION_STATE.NOT_VALIDATED;
};

const questionUnanswered = question => {
  const validationState = getQuestionValidationState(question);
  return getQuestionValidationState(question) === VALIDATION_STATE.UNANSWERED;
};

// We convert the question value to a string in order to store it in the question's metadata. If
// the value later changes, we can compare the old data to the new data and see if it's changed.
// 2020/01/07 AHK: This value is not sent to Elm, so the format is safe to change if needed to
// handle other data types.
const convertQuestionResponseValueToString = responseValue => {
  if (responseValue === undefined || responseValue === null) {
    return DEFAULT_UNSET_QUESTION_RESPONSE_VALUE;
  } else if (responseValue instanceof Array) {
    return responseValue.filter(Boolean).toString();
  } else if (responseValue instanceof Object) {
    return JSON.stringify(responseValue);
  } else {
    return responseValue.toString();
  }
};

const getQuestionResponseValue = question => {
  if (!question.getResponse()) {
    return DEFAULT_UNSET_QUESTION_RESPONSE_VALUE;
  }

  const responseValue = question.getResponse().value;
  return convertQuestionResponseValueToString(responseValue);
};

const questionHasNoResponse = question => {
  return getQuestionResponseValue(question) === "";
};

const getQuestionCurrentAnswersInMetadata = question => {
  const responseMetadata = question.response.getMetadata();
  if (!responseMetadata) {
    return "";
  }
  return responseMetadata.currentAnswers;
};

const questionResponsesChanged = question => {
  return getQuestionResponseValue(question) !== getQuestionCurrentAnswersInMetadata(question);
};

const setResponseValidationStateNotValidated = question => {
  if (!question.getResponse()) {
    return;
  }
  question.response.setMetadata({
    validationState: VALIDATION_STATE.NOT_VALIDATED,
    currentAnswers: getQuestionResponseValue(question)
  });
};

const questionValidated = question => {
  return getQuestionValidationState(question) === VALIDATION_STATE.VALIDATED;
};

const getQuestionValidationState = question => {
  if (!question.getResponse()) {
    return VALIDATION_STATE.UNANSWERED;
  }
  const responseMetadata = question.response.getMetadata();
  if (!responseMetadata) {
    return VALIDATION_STATE.UNANSWERED;
  }
  return responseMetadata.validationState;
};

export function questionByRef(itemsApp, questionRef) {
  const questions = itemsApp.getQuestions();

  if (!questions) {
    Helpers.captureLearnosityException("getQuestions failed", itemsApp);
    throw new Error("getQuestions failed");
  }

  const questionResponseIDs = Object.keys(questions);
  const questionResponseID = questionResponseIDs.find(currentQuestionResponseID => {
    return questions[currentQuestionResponseID].metadata.sheet_reference == questionRef;
  });
  return itemsApp.question(questionResponseID);
}

export function rationaleAudioEnabled() {
  const rationaleElement = document.getElementById(c.ID.DISTRACTOR_RATIONALE);
  return (
    dom.elemExists(rationaleElement) &&
    rationaleElement.hasAttribute("data-audio-status") &&
    rationaleElement.getAttribute("data-audio-status") === "on"
  );
}

export function replaceTextNodesWithSpanNodes(element) {
  return Array.from(element.childNodes, node => node).map(currentChildNode => {
    if (currentChildNode.nodeType === Node.TEXT_NODE) {
      let newSpanNode = document.createElement("span");
      newSpanNode.innerHTML = currentChildNode.textContent;
      currentChildNode.replaceWith(newSpanNode);
      return newSpanNode;
    }
    return currentChildNode;
  });
}

// MathJax adds a div of the formatted math equation, we want to ignore it so it doesn't get read aloud by the TTS library
export function addTTSIgnoreToMathJaxDisplayElements() {
  const mathJaxNodes = document.querySelectorAll(`${c.SELECTOR.MATHJAX_DISPLAY}`);
  for (var i = 0; i < mathJaxNodes.length; i++) {
    mathJaxNodes[i].setAttribute("tts-ignore", "");
  }
}

export function sanitizeMathJaxSourceElements() {
  if (global.MathJax) {
    // The source elements is the element TTS will read aloud
    const mathJaxNodes = MathJax.Hub.getAllJax("MathDiv");
    for (let i = 0; i < mathJaxNodes.length; i++) {
      let mathJaxSourceNode = mathJaxNodes[i].SourceElement();
      mathJaxSourceNode.innerHTML = sanitizeLaTex(mathJaxSourceNode.textContent);
      // Source tags are wrapped in script HTML tags, they are not always read by TTS here we force it
      mathJaxSourceNode.setAttribute("tts-allow", "");
    }
  }
}

export function sanitizeLaTex(stringWithLatex) {
  return stringWithLatex
    .replace(/\\div/gi, " divided by ")
    .replace(/left|right|{|\[\}\]/gi, "")
    .replace(/\\/g, "")
    .replace(/\-/g, " minus ")
    .replace(/\+/g, " plus ")
    .replace(/\s\s+/g, " ");
}

export function getTextContentExcludingTTSIgnore(element) {
  return [].reduce.call(
    element.childNodes,
    function(acc, child) {
      return (
        acc +
        (child.nodeType === Node.TEXT_NODE ||
        (child.nodeType === Node.ELEMENT_NODE && !child.hasAttribute("tts-ignore"))
          ? child.textContent
          : "")
      );
    },
    ""
  );
}

function transformButtonSizeAndPosition(
  buttonElement,
  styleRules = { top: "auto", bottom: "auto", right: "auto", position: "absolute", height: "160px" }
) {
  buttonElement.style.top = styleRules.top;
  buttonElement.style.bottom = styleRules.bottom;
  buttonElement.style.right = styleRules.right;
  buttonElement.style.position = styleRules.position;
  buttonElement.style.height = styleRules.height;
}

// This function is added to click listeners on the drag and drop page
// Remove the active class for the drag and drop container if the element exists
// and the DOM element that initiated the event (e.target) is equal
// to the the DOM element the listener was attached to (this), this means a non draggable element was clicked.
function checkToRemoveEsparkActiveClassFromDragAndDropContainer(e) {
  const dragAndDropContainer = document.querySelector(c.LEARNOSITY_DRAG_DROP_CONTAINER_SELECTOR);
  if (!dom.elemExists(dragAndDropContainer)) return;
  if (e.target && e.target === this) {
    dragAndDropContainer.classList.remove(c.ESPARK_ACTIVE_DRAGGABLE_ITEM_CLASS);
  }
}

function checkToAddEsparkActiveClassToDragAndDropContainer(e) {
  const dragAndDropContainer = document.querySelector(c.LEARNOSITY_DRAG_DROP_CONTAINER_SELECTOR);
  if (!dom.elemExists(dragAndDropContainer)) return;
  // add active class if element clicked on does not have the Learnosity active class
  // and the drag and drop container does not have an active class
  if (
    !this.classList.contains(c.LEARNOSITY_ACTIVE_DRAGGABLE_ITEM_CLASS) &&
    !dragAndDropContainer.classList.contains(c.ESPARK_ACTIVE_DRAGGABLE_ITEM_CLASS)
  ) {
    dragAndDropContainer.classList.add(c.ESPARK_ACTIVE_DRAGGABLE_ITEM_CLASS);
  } else {
    dragAndDropContainer.classList.remove(c.ESPARK_ACTIVE_DRAGGABLE_ITEM_CLASS);
  }
}

export function addClickListenerForDragAndDropElements() {
  const dragAndDropContainerExcludingDraggableItems = document.querySelectorAll(
    `${c.LEARNOSITY_NON_DRAGGABLE_ITEMS_SELECTOR}`
  );
  const dragAndDropContainerDraggableItems = document.querySelectorAll(
    `${c.LEARNOSITY_DRAGGABLE_ITEMS_SELECTOR}`
  );

  if (dom.elemExists(dragAndDropContainerExcludingDraggableItems)) {
    for (var i = 0; i < dragAndDropContainerExcludingDraggableItems.length; i++) {
      dragAndDropContainerExcludingDraggableItems[i].addEventListener(
        "click",
        checkToRemoveEsparkActiveClassFromDragAndDropContainer
      );
    }
  }

  if (dom.elemExists(dragAndDropContainerDraggableItems)) {
    for (var i = 0; i < dragAndDropContainerDraggableItems.length; i++) {
      dragAndDropContainerDraggableItems[i].addEventListener(
        "click",
        checkToAddEsparkActiveClassToDragAndDropContainer
      );
    }
  }
}

export function removeClickListenerForDragAndDropElements() {
  const dragAndDropContainerExcludingDraggableItems = document.querySelectorAll(
    `${c.LEARNOSITY_NON_DRAGGABLE_ITEMS_SELECTOR}`
  );
  const dragAndDropContainerDraggableItems = document.querySelectorAll(
    `${c.LEARNOSITY_DRAGGABLE_ITEMS_SELECTOR}`
  );

  if (dom.elemExists(dragAndDropContainerExcludingDraggableItems)) {
    for (var i = 0; i < dragAndDropContainerExcludingDraggableItems.length; i++) {
      dragAndDropContainerExcludingDraggableItems[i].removeEventListener(
        "click",
        checkToRemoveEsparkActiveClassFromDragAndDropContainer
      );
    }
  }

  if (dom.elemExists(dragAndDropContainerDraggableItems)) {
    for (var i = 0; i < dragAndDropContainerDraggableItems.length; i++) {
      dragAndDropContainerDraggableItems[i].removeEventListener(
        "click",
        checkToAddEsparkActiveClassToDragAndDropContainer
      );
    }
  }
}

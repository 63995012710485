/*
  Constants for Learnosity-related string values.

  Place any constants in this file, but DOM related constants should be added to their appropriate grouping.
 */

// TODO: cleanup so none are using the object syntax after we have specs.
export const CLASS = {
  QUESTION_ANSWERED: "lrn-es-question-answered",
  LOADER_WRAPPER: "es-loader__wrapper"
};

export const SELECTOR = {
  IFRAME: "iframe.x-origin-frame",
  LOADER: ".es-loader--quiz",
  ACTION_BUTTON: ".es-lrn-button",
  RESPONSE_WRAPPER: ".lrn_response_wrapper",
  MATHJAX_PREVIEW: ".MathJax_Preview",
  LRN_AUDIO_PLAYER: ".lrn_audioplayer",
  LRN_QUESTION: ".lrn_question",
  LRN_CONTENT_WRAPPER: ".lrn_contentWrapper",
  MATHJAX_DISPLAY: ".MathJax",
  TOP_BAR_STUDENT_NAME: ".es-top-bar-student-name",
  LRN_APP_LAYOUT: ".app-layout"
};

export const ID = {
  DISTRACTOR_RATIONALE: "distractor-rationale-v2",
  LEARNOSITY_CONTAINER: "learnosity_assess",
  LEARNOSITY_CONTENT: "learnosity-content-v2",
  CONTENT_V1: "learnosity-content",
  MAIN_NAV: "navigation-student-name"
};

export const LEARNOSITY_LIBRARY_ITEMS_URL = "https://items.learnosity.com/?v2022.1.LTS";

export const LEARNOSITY_GENERAL_CONTENT_WRAPPER_CLASS = "lrn_contentWrapper";
export const LEARNOSITY_GENERAL_CONTENT_WRAPPER_SELECTOR = `.${LEARNOSITY_GENERAL_CONTENT_WRAPPER_CLASS}`;
export const AUDIO_OVERRIDE_SRC_SELECTOR = "[data-src]";
export const ESPARK_ASSESSMENT_QUESTION_PROMPT_CLASS = "espark-question-prompt";
export const HAS_ALWAYS_SPEAK_SELECTOR = ".lrn-es-feature-always-speak";
export const HAS_HAO_STIMULUS_CLASS = "lrn-es-has-hao-stimulus";
export const HAS_HAO_OPTIONS_CLASS = "lrn-es-has-hao-options";
export const STIMULUS_CONTENT_SELECTOR = ".lrn_stimulus_content > div";
export const STIMULUS_WRAPPER_SELECTOR = ".lrn_stimulus";
export const RESPONSE_WRAPPER_SELECTOR = ".lrn_response";
export const RESPONSE_WRAPPER_CLASSIFICATION_SELECTOR = ".lrn_classification";
export const RESPONSE_WRAPPER_ASSOCIATION_SELECTOR = ".lrn_association";
export const RESPONSE_WRAPPER_ORDER_LIST_SELECTOR = ".lrn_orderlist";
// Cloze questions === drag & drop
export const RESPONSE_WRAPPER_CLOZE_TEXT_SELECTOR = ".lrn_clozetext";
export const RESPONSE_WRAPPER_CHOICE_MATRIX_SELECTOR = ".lrn_choicematrix";
export const LEARNOSITY_DRAG_DROP_CONTAINER_SELECTOR = ".lrn-dragdrop-container";
export const LEARNOSITY_DRAGGABLE_ITEMS_SELECTOR = ".lrn_draggable";
export const LEARNOSITY_NON_DRAGGABLE_ITEMS_SELECTOR =
  ".lrn-dragdrop-container *:not(.lrn_draggable):not(.lrn_item):not(img)";
export const LEARNOSITY_ACTIVE_DRAGGABLE_ITEM_CLASS = ".lrn_active";
export const ESPARK_ACTIVE_DRAGGABLE_ITEM_CLASS = "es_active";

// TODO: Refactor out places where we use these values and create additional constants as appropriate.
export const ESPARK_LEARNOSITY_CONTENT_WRAPPER_ID = "learnosity-content-v2";
export const TTS_QUIZ_AUDIO_TEXT_CLASS = "quiz-audio-text";
export const STIMULUS_SELECTOR = ".lrn_stimulus_content";
export const MCQ_ANSWER_SELECTOR = ".lrn_mcqgroup .lrn-possible-answer";
export const RATIONALE_TEXT_SELECTOR = ".distractor-rationale--text";

export const QUIZ_AUDIO_TTS_ACTIVE_CLASS = "lrn-es-tts-active";
export const QUIZ_AUDIO_TTS_LOADING_CLASS = "lrn-es-tts-loading";
export const QUIZ_AUDIO_TTS_BUTTON_CLASS = "lrn-es-tts-button";
export const QUIZ_AUDIO_STIMULUS_TTS_BUTTON_CLASS = `lrn-es-stimulus-tts-button`;
export const QUIZ_AUDIO_PASSAGE_PARAGRAPH_SECTION_TTS_BUTTON_CLASS = "lrn-es-passage-paragraph-section-tts-button"; // prettier-ignore
export const QUIZ_AUDIO_PASSAGE_HEADING_SECTION_TTS_BUTTON_CLASS = "lrn-es-passage-heading-section-tts-button"; // prettier-ignore

export const PASSAGE_PARAGRAPH_SECTION_CLASS = "lrn-es-passage-paragraph-section";
export const PASSAGE_CONTAINER_SELECTOR = ".lrn_sharedpassage";
export const PASSAGE_PARAGRAPH_SELECTOR = ".lrn_sharedpassage p";
export const PASSAGE_HEADING_SECTION_CLASS = "lrn-es-passage-heading-section";
export const PASSAGE_HEADING_SELECTOR = ".lrn_sharedpassage .lrn-feature-heading";

export const FAST_CONNECTION_DELAY_MS = 500;
export const SLOW_CONNECTION_DELAY_MS = 200;

export const TTS_SPEECH_DELAY =
  window.navigator.connection && window.navigator.connection.rtt < FAST_CONNECTION_DELAY_MS
    ? FAST_CONNECTION_DELAY_MS
    : SLOW_CONNECTION_DELAY_MS;

export const TTS_INITIAL_LOADING_DELAY = TTS_SPEECH_DELAY + 500;
export const TTS_BETWEEEN_SENTENCE_LOADING_DELAY = TTS_INITIAL_LOADING_DELAY;
export const TTS_ACTIVE_CLASS = "tts-active";
export const TTS_ESPARK_CONTENT_SELECTOR = ".espark-content";
export const QUIZ_V2_SELECTOR = ".quiz-v2";
export const ES_MATHJAX_FORMATTED_SELECTOR = "es-mathjax-formatted";

import { BACKUP_AUDIO_NODE_ID, SOUND_ASSET_NODE_ID } from "./Audio/esparkTextToSpeech";
import get from "lodash/get";

/*
  We see intermittent audio errors related to CORS. It's difficult to pin down the
  root cause or determine if errors are the result of constantly changing browser
  standards.

  As of fall 2019, one observation is that our TTS library uses a library internally that
  plays via Web Audio and that this can trigger cors issues more frequently on some
  devices. Another observation is that forcing the use of html5 (audio nodes) across
  the board resulted in more failures to autoplay (play before user interaction) on
  safari browsers.

  To mitigate this issue we wrap and replace a select few native XHR request functions with
  modified versions so that we can intercept the web audio failures and manually fall
  back to playing from an html5 audio node.

  Because Sentry also wraps all XHR requsts, we need to run our code immediately, before
  we even import the Sentry library anywhere. We still want to capture errors from here
  so we add a special instance of our Sentry library on the window, window.XhrSentryInstance,
  after we import it to use in functions in this file.
 */

// as part of a debugging effort related to memory issues and audio failures we will count
// xhr calls, failures, and when we last loaded this object
const xhrCounts = { total: 0, errors: 0, start: new Date() };

const open = window.XMLHttpRequest.prototype.open;
const send = window.XMLHttpRequest.prototype.send;

function openReplacement(_method, url, _async, _user, _password) {
  this._url = url;
  return open.apply(this, arguments);
}

function sendReplacement(data) {
  this._data = data;
  if (this.onerror) {
    this._onerror = this.onerror;
  }
  if (this.onload) {
    this._onload = this.onload;
  }
  this.onload = onLoadReplacement;
  this.onerror = onErrorReplacement;
  return send.apply(this, arguments);
}

function onLoadReplacement() {
  xhrCounts.total++;
  // if we get a 4XX status then we want to track it
  if (this.status.toString()[0] === "4") {
    xhrCounts.errors++;
  }

  xhrCounts.minutesSinceStart = Math.round((new Date() - xhrCounts.start) / 60000);
  window.XhrSentryInstance.addBreadcrumb({
    message: "xhr request",
    category: "xhr",
    data: xhrCounts,
    level: "debug"
  });
  if (this._onload) {
    return this._onload.apply(this, arguments);
  }
}

function onErrorReplacement() {
  // This is a naive approach to determining if the url from the failed fetch was
  // an audio file. We can't use endsWith because of added query params.
  // TODO: update this check.
  if (this._url.includes(".mp3")) {
    try {
      // When the Web Audio fails, we attempt to play from an audio node here.
      // We recycle the same node for each time we have to use this strategy.
      // In our TTS code we reference this node and listen for events (playing/pause/ended)
      // so we can update our DOM button states. We also trigger events on the
      // node from our TTS code when another audio should interrupt and stop the playing
      // from this node.
      let audio = document.getElementById(BACKUP_AUDIO_NODE_ID);

      if (!audio) {
        const nodeForAppend = document.getElementById(SOUND_ASSET_NODE_ID) || document.body;

        audio = document.createElement("audio");
        audio.id = BACKUP_AUDIO_NODE_ID;
        nodeForAppend.appendChild(audio);
      }

      audio.src = this._url;
      audio.muted = false;

      const playPromise = audio.play();
      if (playPromise) {
        playPromise
          .then(() => {
            console.log("played from backup because of cors errors");
          })
          .catch(error => {
            console.warn("error playing cors error backup...", error);
          });
      }
    } catch (error) {
      console.warn("error attempting to play cors error backup...", error);
    }
  }

  if (this._onerror) {
    return this._onerror.apply(this, arguments);
  }
}

export default function hookIntoXhr() {
  window.XMLHttpRequest.prototype.open = openReplacement;
  window.XMLHttpRequest.prototype.send = sendReplacement;
}

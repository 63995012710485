import { isAllowedOrigin } from "../util/IframeCommunication";

type ChoiceTextAuthData = {
  iframeId: string;
  origin: string;
  authToken: string;
  sessionId: string;
};

interface EsparkIframeUpdatePorts {
  trackChoiceTextAvailable: PortToElm<string>;
  provideAuthInfoToChoiceText: PortFromElm<ChoiceTextAuthData>;
  startChoiceText: PortFromElm<string>;
  choiceTextComplete: PortToElm<null>;
  refreshActivity: PortToElm<null>;
  backToSubjectChoice: PortToElm<null>;
  popUpBottomBar: PortToElm<null>;
  choiceWritingErrored: PortToElm<string>;
  choiceTextErrored: PortToElm<string>;
  backToQuestPage: PortToElm<null>;
}

function listenForMessage(message: string, handler: (event: MessageEvent) => void) {
  const listener = function(event: MessageEvent): void {
    if (!isAllowedOrigin(event.origin) || !event.data?.message) {
      return;
    }

    if (event.data.message === message) {
      handler(event);
    }
  };

  window.addEventListener("message", listener);
  listener.unsubscribe = () => window.removeEventListener("message", listener);

  return listener;
}

/* TODO: if we start receiving other messages via postMessage, move this to a generalized handler in Elm */
export default function setupChoiceTextPorts(ports: EsparkIframeUpdatePorts): void {
  ports.startChoiceText.subscribe((): void => {
    const availableListener = listenForMessage("ChoiceTextAvailable", (event: MessageEvent) => {
      console.log("ChoiceTextAvailable", event.origin);
      ports.trackChoiceTextAvailable.send(event.origin);
      availableListener.unsubscribe();
    });

    const provideAuthInfo = ({ iframeId, origin, authToken, sessionId }: ChoiceTextAuthData) => {
      const iframe: HTMLIFrameElement | null = document.getElementById(
        iframeId
      ) as HTMLIFrameElement | null;
      if (!iframe || !iframe.contentWindow) {
        console.warn(
          "Unable to communicate with the ChoiceText! Iframe not found or not usable.",
          iframe,
          iframeId
        );
        return;
      }
      iframe.contentWindow.postMessage(
        { message: "eSpark Auth Info", authInfo: { authToken, sessionId } },
        origin
      );
    };

    ports.provideAuthInfoToChoiceText.subscribe(provideAuthInfo);

    const completionListener = listenForMessage("ChoiceTextComplete", () => {
      ports.choiceTextComplete.send(null);
      completionListener.unsubscribe();
      ports.provideAuthInfoToChoiceText.unsubscribe(provideAuthInfo);
    });

    const backToQuestPage = listenForMessage("BackToQuestPage", () => {
      ports.backToQuestPage.send(null);
    });

    const popUpBottomBarListener = listenForMessage("PopUpBottomBar", () => {
      ports.popUpBottomBar.send(null);
    });

    const choiceWritingErrored = listenForMessage("ChoiceWritingErrored", (event: MessageEvent) => {
      ports.choiceWritingErrored.send(event.data.error);
    });

    const choiceTextErrored = listenForMessage("ChoiceTextErrored", (event: MessageEvent) => {
      ports.choiceTextErrored.send(event.data.error);
    });

    const backToLibraryListener = listenForMessage("BackToSubjectChoice", () => {
      ports.backToSubjectChoice.send(null);
    });

    const refreshPage = listenForMessage("RefreshActivity", (event: MessageEvent) => {
      refreshPage.unsubscribe();

      //check event data is a number if not default to 1
      let retryCount: number = 1;
      if (Number.isFinite(event.data.retryCount)) {
        retryCount = parseInt(event.data.retryCount);
      }

      let url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      searchParams.set("retry", retryCount.toString());
      url.search = searchParams.toString();

      window.location.href = url.href;
    });

    const retrieveRetry = listenForMessage("RetrieveRetry", (event: MessageEvent) => {
      //AEA 8/9/23 This code will error out if we ever have more than one iframe on the page
      // Move it so elm can handle it and send the correct iframe id or update the query Selector
      let retrieveFrameId = document.querySelector("iframe")
        ? document.querySelector("iframe")?.id
        : "";
      let frameId: string = "";
      if (retrieveFrameId) {
        frameId = retrieveFrameId;
      }
      const iframe: HTMLIFrameElement | null = document.getElementById(
        frameId
      ) as HTMLIFrameElement | null;

      if (!iframe || !iframe.contentWindow) {
        console.warn(
          "Unable to communicate with the ChoiceText! Iframe not found or not usable.",
          iframe
        );
        return;
      }

      iframe.contentWindow.postMessage(
        {
          message: "RetrieveRetrySent",
          retry: new URLSearchParams(window.location.search).get("retry")
        },
        event.data.origin
      );
    });
  });
}

import URLSearchParams from "@ungap/url-search-params";
import setupLearnosityEventLogging from "javascript/Learnosity/eventLogging";
import * as Helpers from "javascript/Learnosity/helpers";
import * as WindowScroll from "javascript/WindowScroll";
import * as errorReporting from "javascript/Learnosity/errorReporting";
import MathJaxNodeObserver from "javascript/Learnosity/MathJaxNodeObserver";
import LearnosityEvents from "javascript/Learnosity/LearnosityEvents";
import LearnosityElmPorts from "javascript/Learnosity/LearnosityElmPorts";
import Assessment from "javascript/Learnosity/models/Assessment";
import AssessmentDom from "./dom/AssessmentDom";
import setupTTS, { stopIfPlaying } from "javascript/Learnosity/assessmentTTS";
import * as c from "javascript/Learnosity/util/constants";
import * as dom from "javascript/util/dom";

/******** IMPORTANT!! THIS IS FOR QUIZ V2 ***********/

const MAX_INITIALIZATION_ATTEMPT_COUNT = 3;

export default function setupShowLearnosityQuizV2Port({ lib, ports, tts }) {
  const mathjaxObserver = new MathJaxNodeObserver();

  // We currently block route changes after a quiz is completed in RouteCleanupComplete in elm so that the quiz cleanup can be performed.
  // Cleanup is accomplished with an elm call to ports.existQuizV2. The call must be followed to ports.exitQuizComplete in order to unblock the route change.
  // The exitQuizV2 port subscription has been moved here to ensure it gets called when a Learnosity activity fails to load.
  // This is because on failure to load an activity we never get to the readyListener, which is where the Learnosity ports are subscribed.
  // The window.requestAnimationFrame is to ensure we call exitQuizComplete after the Learnosity ports are unsubscribed.
  ports.exitQuizV2.subscribe(() => {
    window.requestAnimationFrame(() => {
      ports.exitQuizComplete.send(null);
    });
  });

  ports.showLearnosityQuizV2.subscribe(learnosityOptionsJSONString => {
    console.log("%c >>> showingLearnosityQuiz V2", "color: blue; font-weight: bold");
    mathjaxObserver.observe();

    let itemsApp, learnosityElmPorts;
    const options = JSON.parse(learnosityOptionsJSONString);
    const studentActivityId = options.request.student_activity_id;

    const params = new URLSearchParams(window.location.search);
    const retryPageLoadParam = params.get("retry") || "0";
    const initializationAttemptCount = parseInt(retryPageLoadParam) + 1;

    const errorHandler = captureErrorFunction => {
      ifAssessmentInDOM(
        () => {
          if (initializationAttemptCount >= MAX_INITIALIZATION_ATTEMPT_COUNT) {
            mathjaxObserver.disconnect();
            Helpers.hideQuizLoader();
            captureErrorFunction();
          }
          ports.handleActivityError.send(studentActivityId);
        },
        () => {
          // It's important to always disconnect the mathjax observer on page navigation so that
          // we're not constantly checking for mathjax nodes on pages where that isn't relevant.
          mathjaxObserver.disconnect();
          if (learnosityElmPorts) {
            console.log("not running the error handler");
            console.log("BUT ports were defined");
            learnosityElmPorts.unsubscribe();
          }
        }
      );
    };

    // Since Elm port calls runs asynchronously, we aren't guaranteed that Elm has had time to render the
    // necessary element for mounting Learnosity before we tell learnosity to initialize.
    // Elm renders on the animation frame, so the common practice at this time is to run any
    // dom-dependent code on the next animation frame.
    window.requestAnimationFrame(() => {
      let ttsSpeakCalled = false;
      lib.ensureReady(
        () => {
          itemsApp = LearnosityItems.init(options, {
            errorListener: err => {
              errorHandler(() =>
                errorReporting.captureInitFailure(err, options, MAX_INITIALIZATION_ATTEMPT_COUNT)
              );
            },
            readyListener: () => {
              ifAssessmentInDOM(
                () => {
                  setupLearnosityEventLogging(itemsApp);

                  const scrollAndResizeEventHandler = () => {
                    ifAssessmentInDOM(
                      () => {
                        Helpers.formatMathMathematicalExpressionsInAssessment(
                          c.ID.DISTRACTOR_RATIONALE
                        );
                        Helpers.adjustLearnosityButtonHeight(itemsApp.getCurrentItem);
                      },
                      () => console.log("not running scroll event handler")
                    );
                  };

                  // TTS click handler is exclusively for the rationale. It enables tts for the click event on the rationale.
                  const ttsClickHandler = () => {
                    const rationaleTextElement = document.querySelector(c.RATIONALE_TEXT_SELECTOR);
                    if (Helpers.rationaleAudioEnabled() && dom.elemExists(rationaleTextElement)) {
                      rationaleTextElement.classList.add(c.TTS_QUIZ_AUDIO_TEXT_CLASS);
                      rationaleTextElement.addEventListener("click", event => {
                        stopIfPlaying(tts);
                        window.requestAnimationFrame(() => {
                          // window.requestAnimationFrame gets requested multiple times, we want to prevent chorus speak
                          if (!tts.isCurrentlyPlaying && !ttsSpeakCalled) {
                            ttsSpeakCalled = true;
                            tts.speak({ elements: rationaleTextElement });
                          }
                        });
                        ttsSpeakCalled = false;
                      });
                    }
                    return tts;
                  };

                  const learnosityEvents = new LearnosityEvents(itemsApp, ttsClickHandler);
                  learnosityEvents.listen({
                    ports,
                    studentActivityId,
                    scrollAndResizeEventHandler,
                    ttsClickHandler
                  });

                  const onExitQuizHandler = () => {
                    mathjaxObserver.disconnect();
                    WindowScroll.removeEventListeners(scrollAndResizeEventHandler);
                  };

                  // We declare this in the outer scope so we can unsubscribe in the event of a failure or retry.
                  learnosityElmPorts = new LearnosityElmPorts({
                    ports,
                    app: itemsApp,
                    onExitQuiz: onExitQuizHandler
                  });
                  learnosityElmPorts.subscribe();

                  window.requestAnimationFrame(() => {
                    ifAssessmentInDOM(
                      () => {
                        const assessment = new Assessment(itemsApp);
                        const assessmentDom = new AssessmentDom(assessment);
                        assessmentDom.prepare();
                        setupTTS(tts);
                        window.requestAnimationFrame(() => {
                          tts.retagContent();
                          Helpers.hideQuizLoader();
                        });
                      },
                      () => {
                        mathjaxObserver.disconnect();
                        learnosityElmPorts.unsubscribe();
                        Helpers.hideQuizLoader();
                      }
                    );
                  });
                },
                () => console.log("not running the ready listener")
              );
            }
          });
        },
        () => errorHandler(errorReporting.captureReadyFailure)
      );
    });
  });
}

function ifAssessmentInDOM(callback, ifNotInDOM = () => {}) {
  if (dom.elemExists(document.getElementById(c.ID.LEARNOSITY_CONTENT))) {
    callback();
  } else {
    ifNotInDOM();
  }
}

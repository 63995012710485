import Sentry from "javascript/Sentry/Wrapper";
import DebugData from "javascript/Monitor/DebugData";
import DebugDataStorage from "javascript/Monitor/DebugDataStorage";
import camelCase from "lodash/camelCase";

export default function setupMonitoringPorts(ports) {
  ports.storeDebugData.subscribe(debugDataString => {
    const rawData = JSON.parse(debugDataString);
    const debugData = new DebugData(rawData.data);

    DebugDataStorage.set(debugData.forLocalStorage());
  });
}

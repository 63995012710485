import { getAuthHeaderFromLocalStorage } from "./getAuthHeaderFromLocalStorage";

interface AuthFetchedParams {
  method?: string;
  headers?: Record<string, string>;
  body?: string;
}

declare global {
  interface Window {
    deviceIdentifier: string | undefined;
    esparkClientVersion: string | undefined;
  }
}

// occasionally we need to make authorized requests from Javascript to the backend, not through Elm
export default function authedFetch(url: string, params: AuthFetchedParams): Promise<Response> {
  const storedToken = JSON.parse(getAuthHeaderFromLocalStorage() || "null");
  if (!storedToken) {
    // this never resolves == we never make the call
    return new Promise(() => {});
  }

  const updatedParams: AuthFetchedParams = { ...params };

  // inject appropriate data into the headers
  updatedParams.headers = updatedParams.headers || {};
  updatedParams.headers["X-Espark-Access-Token"] = storedToken.authToken.access_token;
  updatedParams.headers["content-type"] = "application/json";
  if (window.deviceIdentifier) {
    updatedParams.headers["X-MAC-Address"] = window.deviceIdentifier;
  }
  if (window.esparkClientVersion) {
    updatedParams.headers["X-Client-Version"] = window.esparkClientVersion;
  }

  return window.fetch(url, updatedParams);
}

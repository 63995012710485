const LOG_TYPE = {
  BREADCRUMBS: "breadcrumbs",
  CONSOLE: "console",
  ALL: "all"
};

const LOG_ENV = {
  production: LOG_TYPE.BREADCRUMBS,
  staging: LOG_TYPE.ALL,
  development: LOG_TYPE.CONSOLE,
  default: LOG_TYPE.CONSOLE
};

const CONSOLE_DEFAULT = "log";

export default class Loggable {
  constructor(category) {
    this.category = category;
    this.log = this.log.bind(this);
    this.logFor = this.logFor.bind(this);
    this.console = this.console.bind(this);
    this.breadcrumb = this.breadcrumb.bind(this);
  }

  log(message = "", kind = CONSOLE_DEFAULT, data = {}) {
    this.logFor(message, kind, data);
  }

  logFor(message = "", kind = CONSOLE_DEFAULT, data = {}) {
    switch (LOG_TYPE[LOG_ENV[process.env.NODE_ENV]]) {
      case LOG_TYPE.BREADCRUMBS:
        this.breadcrumb(message, data);
        break;
      case LOG_TYPE.CONSOLE:
        this.console(message, { kind, ...data });
        break;
      case LOG_TYPE.ALL:
        this.breadcrumb(message, data);
        this.console(message, { kind, ...data });
        break;
      default:
        this.console(message, { kind, ...data });
    }
  }

  console(message, data) {
    const { kind, level, ...context } = data;
    const consoleLogFn = console[kind] || console[level] || console.log;

    if (Object.keys(context).length) {
      consoleLogFn(`${this.category}: ${message}`, context);
    } else {
      consoleLogFn(`${this.category}: ${message}`);
    }
  }

  breadcrumb(message, data) {
    const { level = "debug", kind: _kind, ...context } = data;

    Sentry.addBreadcrumb({
      message,
      level,
      data: context,
      category: this.category
    });
  }
}

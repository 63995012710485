// This code stops the double-click event to prevent in-app zoom on touch screens
export function stopDoubleTapZoom() {
  document.addEventListener(
    "dblclick",
    function(event) {
      event.preventDefault();
    },
    { passive: false }
  );
}

import Sentry from "javascript/Sentry/Wrapper";

export function captureInitFailure(err, learnosityOptions, attemptCount) {
  Sentry.captureMessage(
    `Skipping Learnosity assessment after ${attemptCount} initialization attempts`,
    {
      extra: {
        learnosityOptions,
        error: err
      },
      level: "warn",
      tags: {
        lrnErrorCode: err.code,
        learnosityError: err.msg,
        component: "Learnosity"
      }
    }
  );
}

export function captureReadyFailure() {
  Sentry.captureMessage(`Learnosity.js failed to prepare DOM with necessary iframe`, {
    level: "error",
    tags: {
      component: "Learnosity"
    }
  });
}

export function captureLoadFailure() {
  Sentry.captureMessage(`Learnosity.js failed to load`, {
    level: "error",
    tags: {
      component: "Learnosity"
    }
  });
}

import AudioDOMManager from "./AudioDOMManager";

export interface AudioClip {
  textId: string;
  text: Element | null;
  clipBegin: number;
  clipEnd: number;
  duration: number | null;
}
/**
 * Manages the audio clip creation and highlighting functionality
 */
export default class AudioHighlighter {
  private isPlaying: boolean;
  private isEnabled: boolean;
  private currentWord: number;
  private audio: Array<AudioClip>;
  domManager: AudioDOMManager;
  private handleError: (errorMsg: string, isCritical: boolean) => void;
  constructor(
    audioAllPages: Array<AudioClip>,
    domManager: AudioDOMManager,
    onError: (errorMsg: string, isCritical: boolean) => void
  ) {
    this.isPlaying = false;
    this.isEnabled = true;
    this.currentWord = 0;
    this.audio = audioAllPages || [];
    this.domManager = domManager;
    this.handleError = onError;
  }

  isHighlighting() {
    return this.isPlaying;
  }
  toggleAudio(): Promise<boolean> {
    /**
     * KEM was seeing a repeating error (10/01/2022) where the toggleAudio would be sent rapidly twice, meaning the audio would play and then pause.
     * Trying a solution to see if forcing the correct play/pause states instead of inverting the isPlaying will work consistently.
     */
    if (!this.isEnabled) {
      return Promise.resolve(false);
    }
    if (this.isPlaying) {
      return this.pause();
    } else {
      return this.play();
    }
  }
  disable(): Promise<boolean> {
    if (!this.isEnabled) {
      return Promise.resolve(false);
    }
    this.isEnabled = false;
    return this.pause();
  }

  private play(): Promise<boolean> {
    if (!this.domManager.getAudioElement()) {
      this.domManager.disableAudio();
      return Promise.resolve(false);
    }
    if (this.isPlaying) {
      return Promise.resolve(true);
    }
    //starts the highlighting process with the global current word passed in to start
    if (this.audio.length > 0) {
      return this.domManager.playAudio().then(playing => {
        if (playing) {
          this.isPlaying = true;
          this.highlightWord(this.currentWord);
        }
        return playing;
      });
    }
    console.error("ERROR: The audio has not finished loading.");
    this.handleError("The audio has not finished loading.", false);
    return Promise.resolve(false);
  }
  private pause(): Promise<boolean> {
    if (!this.domManager.getAudioElement()) {
      this.domManager.disableAudio();
      return Promise.resolve(false);
    }
    if (!this.isPlaying) {
      return Promise.resolve(true);
    }
    this.isPlaying = false;
    return Promise.resolve(this.domManager.pauseAudio());
  }

  /**
   * reset the audio to a given index
   * @param audio
   * @param audioElement
   * @param wordIndex
   */
  resetAudio(wordIndex: number) {
    if (!this.isEnabled) {
      return;
    }
    this.pause();
    this.domManager.setCurrentTime(this.audio[wordIndex].clipBegin);
    this.currentWord = wordIndex;
  }
  /**
   * To highlight text with audio, get the text reference from the current AudioClip, and set the attributes.
   * Each par tag has a text with source and the audio which has been parsed into AudioClip during the initialization for each page.
   * Then set a timeout for the next word.
   */
  private highlightWord(wordIndex: number) {
    //when paused, the this.isPlaying will be set to false
    //but we don't want to clear the highlight, so they can see where they are
    //if navigating mid-play, we want to return and stop highlighting.  The audio will be reset
    if (!this.isEnabled || !this.isPlaying) {
      return;
    }
    //stop highlighting at the end of the page(s)
    if (wordIndex >= this.audio.length) {
      const lastWord = this.audio[wordIndex - 1].text;
      if (lastWord) {
        this.domManager.clearHighlight(lastWord);
      }
      this.resetAudio(0);
      return;
    }
    //remove highlight of last word
    if (wordIndex > 0) {
      const lastWord = this.audio[wordIndex - 1].text;
      if (lastWord) {
        this.domManager.clearHighlight(lastWord);
      }
    }

    const currentClip: AudioClip = this.audio[wordIndex];
    if (!currentClip || !currentClip.text) {
      console.error("ERROR: audio clip not found for:" + wordIndex);
      this.handleError("Highlighting but audio clip not found for:" + wordIndex + ".", false);
      this.resetAudio(wordIndex - 1);
      return;
    }
    //this needs to happen via set attribute because the css setting from the espark side gets overwritten for the pages
    this.domManager.highlightText(currentClip.text);
    this.currentWord = wordIndex;

    //audio was getting out of sync with highlights so I'm trying to use the current time instead of the calculated duration
    let waitEnd = this.audio[wordIndex + 1]
      ? this.audio[wordIndex + 1].clipBegin
      : currentClip.clipEnd;
    let currentTime = this.domManager.getCurrentTime();
    if (typeof currentTime === "undefined" || currentTime < 0) {
      console.error("ERROR: audio does not have a current time set:" + currentTime);
      this.handleError("Audio does not have a current time set:" + currentTime + ".", false);
      this.resetAudio(0);
      return;
    }

    let timeToWait = 1000 * (waitEnd - currentTime);
    if (timeToWait < 0) {
      console.error(
        "ERROR: Invalid time for next word" + timeToWait + "," + this.domManager.getCurrentTime()
      );
      this.handleError(
        "Invalid time for next word" + timeToWait + "," + this.domManager.getCurrentTime() + ".",
        false
      );
      //backup to keep the highlighting working smoothly, it assumes somethings wrong with the audio and resets the audio to the current word
      if (currentClip.duration) {
        timeToWait = currentClip.duration;
      } else {
        console.error(
          "ERROR: This word doesn't have duration and current time is invalid:" + currentClip.text
        );
        this.handleError(
          "This word doesn't have duration and current time is invalid:" + currentClip.text + ".",
          false
        );
        return;
      }
      this.domManager.setCurrentTime(currentClip.clipBegin);
    }
    //setup timer for when word is done being read, and increment the current word
    const myTimeout = setTimeout(() => {
      this.highlightWord(++wordIndex);
    }, timeToWait || 0);
  }
}

const NUMBER_OF_REPETITIONS = 6;
const REPETITION_INTERVAL = 5000;

export default function setupGameActivityBrowserHealthPorts(ports) {
  ports.logGameActivityBrowserHealth.subscribe(studentActivityId => {
    logRepeatedly(studentActivityId);
  });
}

async function logRepeatedly(studentActivityId) {
  let repetitionsRemaining = NUMBER_OF_REPETITIONS;
  while (repetitionsRemaining-- > 0) {
    logGameActivityBrowserHealth(studentActivityId);
    await new Promise(resolve => setTimeout(resolve, REPETITION_INTERVAL));
  }
}

function logGameActivityBrowserHealth(studentActivityId) {
  const heapSizeInfo = getHeapSizeInfo();
  return global.logger.eventReceived(
    {
      message: "GameActivityBrowserHealthCheck",
      student_activity_id: studentActivityId,
      js_webgl_exists: webGLContextExists(),
      ...heapSizeInfo
    },
    "javascript"
  );
}

function webGLContextExists() {
  const canvas = document.createElement("canvas");
  const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  return !!(gl && gl instanceof WebGLRenderingContext);
}

function getHeapSizeInfo() {
  let memoryInfo = {};
  if (window.performance && window.performance.memory) {
    memoryInfo = window.performance.memory;
  }
  return {
    js_heap_size_limit: memoryInfo.jsHeapSizeLimit || null,
    js_heap_size_total: memoryInfo.totalJSHeapSize || null,
    js_heap_size_used: memoryInfo.usedJSHeapSize || null
  };
}

import { ElmPorts } from "../Types/ElmPorts";
import { Howl, HowlErrorCallback } from "howler";
// @ts-ignore
import { DESTINATIONS } from "../Logger";
// @ts-ignore
import Sentry from "../Sentry/Wrapper";

type Ports = ElmPorts<{
  subscribe: {
    playInteractionSound: [string, boolean];
    stopInteractionSound: string;
  };
  send: {};
}>;

type Logger = {
  eventReceived: (e: Object) => void;
};

export default function setupSoundPorts(ports: Ports, logger: Logger) {
  ports.playInteractionSound.subscribe(([soundSrc, usingHtmlAudio]) => {
    const howl = new Howl({
      src: [soundSrc],
      html5: usingHtmlAudio
    });

    const stopFunction = (srcToStop: string) => {
      if (srcToStop === soundSrc) {
        howl.stop();
        clearStopListener();
      }
    };
    const captureException: HowlErrorCallback = (_soundId: number, error: unknown) => {
      console.warn("[Sound] Error caught", error);
      Sentry.captureException(error, {
        extra: { soundSrc }
      });
      logger.eventReceived({
        message: "App Audio Error",
        data: {
          error: (error as Error).message || JSON.stringify(error)
        },
        tags: [],
        destinations: [DESTINATIONS.REDSHIFT]
      });
      clearStopListener();
    };
    const clearStopListener = () => {
      ports.stopInteractionSound.unsubscribe(stopFunction);
    };

    ports.stopInteractionSound.subscribe(stopFunction);
    howl.on("end", clearStopListener);
    howl.on("playerror", captureException);
    howl.on("loaderror", captureException);
    howl.play();
  });
}

export function errorOnTTSSpeakRetagAndPlay(error) {
  global.logger.eventReceived(
    {
      message: "TTS Speak Error: Retag and Play",
      err: error,
      event_type: "tts_speak_error"
    },
    "tts"
  );
}

export function errorOnTTSSpeakPlayFromNode(error) {
  global.logger.eventReceived(
    {
      message: "TTS Speak Error: Play from Node",
      err: error,
      event_type: "tts_speak_error"
    },
    "tts"
  );
}
export function errorOnTTSSpeakPlaySelector(error) {
  global.logger.eventReceived(
    {
      message: "TTS Speak Error: Play Selector",
      err: error,
      event_type: "tts_speak_error"
    },
    "tts"
  );
}
export function errorOnTTSSpeakPlayFromText(error) {
  global.logger.eventReceived(
    {
      message: "TTS Speak Error: Play From Text",
      err: error,
      event_type: "tts_speak_error"
    },
    "tts"
  );
}
export function errorOnTTSSpeakPlayUrl(error) {
  global.logger.eventReceived(
    {
      message: "TTS Speak Error: Play URL",
      err: error,
      event_type: "tts_speak_error"
    },
    "tts"
  );
}
export function errorOnTTSQuizClickToSpeak(error) {
  global.logger.eventReceived(
    {
      message: "TTS Speak Error: Click to Speak",
      err: error,
      event_type: "tts_speak_error"
    },
    "tts"
  );
}

import { getAuthHeaderFromLocalStorage } from "javascript/util/getAuthHeaderFromLocalStorage";
import authedFetch from "javascript/util/authedFetch";
import pickBy from "lodash/pickBy";

import Sentry from "javascript/Sentry/Wrapper";

export const DESTINATIONS = {
  REDSHIFT: "Redshift",
  SENTRY_ERROR: "SentryError",
  SENTRY_MESSAGE: "SentryMessage"
};

/*
  This utility logs business-level student actions so for later use in analytics and
  debugging. As Elm messages come into the application, we batch them up and send them
  to Redshift via the backend.
*/
/*
  To record logs to the server from your DEV environment you need to adjust your DEV server:
  - Find this same note on the server in the firehose_client.rb file
  - Make sure your settings.yml says `aws: analytics:  stub_responses: false` or does not have stub_responses line
  - Restart your local server with the env value `FIREHOSE=1`
  - Restart your local client with with the env value `LOG_MESSAGE_EVENTS_DEV=1`
  - Fire the logs you want to test in your client or server
  - In metabase, the logs should show up in a dev table called `public.test_import`
    - sample query: `select * from public.test_import where occurred_at > dateadd(day, -1, CURRENT_DATE) and (message='App Startup')`
*/
export default class Logger {
  constructor() {
    this.eventQueue = [];
    this.uploadAttemptCount = 0;
    this.uploadFailedAttemptCount = 0;
    this.uploadFailedAttemptTotal = 0;
    this.enabled = true;
    this.authenticated = false;
  }

  eventReceived(logData, category = "elm") {
    let { message, tags = {}, destinations, ...data } = logData;

    destinations = destinations || [DESTINATIONS.REDSHIFT];

    if (this.enabled && message && destinations.includes(DESTINATIONS.REDSHIFT)) {
      this.eventQueue.push({ message, ...tags, ...data, occurred_at: Date.now() });
    }

    const sentryData = pickBy(
      {
        category,
        tags,
        data: pickBy(data, d => d)
      },
      d => d
    );

    // always send messages as breadcrumbs
    Sentry.addBreadcrumb({ message, ...sentryData });
    if (destinations.includes(DESTINATIONS.SENTRY_MESSAGE)) {
      Sentry.captureMessage(message, sentryData);
      if (window.environment === "development") {
        console.log(`SENTRY MESSAGE: ${message}`, { tags, data, destinations });
      }
    }
    if (destinations.includes(DESTINATIONS.SENTRY_ERROR)) {
      Sentry.captureException(new Error(message), sentryData);
      if (window.environment === "development") {
        console.warn(`SENTRY ERROR: ${message}`, { tags, data, destinations });
      }
    }
  }

  isAuthenticated() {
    if (!this.authenticated) {
      const storedToken = JSON.parse(getAuthHeaderFromLocalStorage());
      if (storedToken) {
        this.authenticated = true;
      }
    }
    return this.authenticated;
  }

  uploadMessages() {
    if (!this.isAuthenticated()) {
      // the logPlatformData call will generate an event before we log in
      // so we return here to leave the unsenable messages on the queue
      return;
    }

    // use splice to ensure that we don't lose messages at the transition
    const messagesToSend = this.eventQueue.splice(0, this.eventQueue.length);
    if (messagesToSend.length == 0) {
      return new Promise(() => {});
    }

    const body = JSON.stringify({ events: messagesToSend, batch_sent_at: Date.now() });
    // loosely, if we have over ~5 MB of data, warn
    if (body.length > 5000000) {
      Sentry.captureMessage("Large message logging data upload", {
        extra: { size: body.length },
        tags: { component: "Logger" }
      });
    }

    this.uploadAttemptCount++;
    return authedFetch(`${global.config.serverBaseUrl}/api/v5/logging/batch`, {
      body: body,
      method: "POST"
    })
      .then(response => {
        if (!response.ok) {
          this.uploadFailed(response.body || "Error uploading Logger", messagesToSend);
        } else {
          this.uploadFailedAttemptCount = 0;
          Sentry.addBreadcrumb({
            message: "uploaded messages",
            level: "info",
            category: "message logger"
          });
        }
      })
      .catch(e => {
        this.uploadFailed(e, messagesToSend);
      });
  }

  uploadFailed(err, messagesToSend) {
    this.uploadFailedAttemptCount++;
    this.uploadFailedAttemptTotal++;
    if (this.uploadFailedAttemptCount > 3) {
      this.stopListening();
    }
    // if the messages didn't make it, put them back on the queue at the front to preserve order
    this.eventQueue.splice(0, 0, ...messagesToSend);
    Sentry.captureException(err, {
      tags: {
        component: "Logger",
        caught: "true",
        attemptCount: this.uploadAttemptCount,
        failedCount: this.uploadFailedAttemptCount,
        sessionFailedCount: this.uploadFailedAttemptTotal
      }
    });
  }

  startListening(ports, interval) {
    ports.logMessage.subscribe(data => {
      this.eventReceived(JSON.parse(data));
    });

    ports.silenceLogger.subscribe(() => {
      this.stopListening();
    });

    Sentry.addBreadcrumb({
      message: "starting to listen",
      category: "message logger"
    });

    // Send messages every 10 seconds in production. Event logging to RedShift is disabled in development, by default.
    // Run the app with `LOG_MESSAGE_EVENTS_DEV` environment variable set to true to log in development.
    const shouldLog = process.env.LOG_MESSAGE_EVENTS_DEV || process.env.NODE_ENV !== "development";
    if (shouldLog) {
      this._interval = setInterval(() => this.uploadMessages(), interval || 10000);
    }
  }

  stopListening() {
    this.enabled = false;
    clearInterval(this._interval);
  }
}

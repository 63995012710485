const APP_TIMER_LOCALSTORAGE_KEY = "v5-app-timer-key";
import Sentry from "javascript/Sentry/Wrapper";

const AppTimerStorage = {
  setupPorts: ports => {
    ports.storeAppTimer.subscribe(AppTimerStorage.persistAppTimer);
  },

  persistAppTimer: maybeStoredAppTimer => {
    localStorage.setItem(APP_TIMER_LOCALSTORAGE_KEY, JSON.stringify(maybeStoredAppTimer));
  },

  getAppTimer: () => {
    const storedData = localStorage.getItem(APP_TIMER_LOCALSTORAGE_KEY);
    try {
      if (storedData) {
        const timerData = JSON.parse(storedData);
        // when we initialize we want the fresh currentTime
        return { ...timerData, currentTime: Date.now() };
      }
    } catch (e) {
      Sentry.captureException(e, {
        level: "warning",
        tags: { component: "AppTimerStorage", caught: "true" },
        extra: { storedData }
      });
    }

    return null;
  }
};

export default AppTimerStorage;

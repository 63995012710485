import debounce from "lodash/debounce";
import * as c from "javascript/Learnosity/util/constants";
import { replaceTextNodesWithSpanNodes } from "javascript/Learnosity/helpers.js";
import * as dom from "javascript/util/dom";
import {
  BACKUP_AUDIO_NODE_ID,
  SOUND_ASSET_NODE_ID,
  AUDIO_DISABLED_SELECTOR,
  AUDIO_ENABLED_SELECTOR
} from "../Audio/esparkTextToSpeech";

// Some Learnosity questions have audio features that apply regardless of whether
// the student has audio enabled. This function is responsible providing the selectors we will use
// for querying dom elements that use tts on click.
const generateAudioContentSelector = (
  selectorWhenEnabled,
  selectorWhenDisabled,
  additionalSelectors
) =>
  [
    `${AUDIO_ENABLED_SELECTOR} ${selectorWhenEnabled}`,
    selectorWhenDisabled && `${AUDIO_DISABLED_SELECTOR} ${selectorWhenDisabled}`
  ]
    .concat(additionalSelectors)
    .filter(Boolean)
    .join(", ");

export default function setupTTS(tts) {
  // The `create[x]` functions all mutate the DOM to add audio buttons and group clickable content.

  // When setupTTS is called we have ensured that the assessment is in the DOM. But we still need to wait for Learnosity
  // to load their content in the page and since we want to query that content we need to check to see if it exists.
  // To do this, we query the .app_layout. Once we know div.app_layout is on the page we know the stimulus and responses are ready to br queried.
  const maxQueryDomAttempts = 5;
  let queryDomAttempts = 0;
  const addTTSClickHandlerInterval = setInterval(() => {
    queryDomAttempts = queryDomAttempts + 1;
    const appLayout = document.querySelector(c.SELECTOR.LRN_APP_LAYOUT);

    if (dom.elemExists(appLayout) || queryDomAttempts === maxQueryDomAttempts) {
      const stimuliWithAudio = createStimulusAudioSections();
      const passageSpansWithAudio = createPassageSpanAudioSections();
      const passageHeadingsWithAudio = createPassageHeadingPlayAllSections(passageSpansWithAudio);

      // The `addResponseClickToSpeakAudio` function will make the text within the response sections clickable audio.
      const multipleChoiceResponsesWithClickAudio = addMultipleChoiceResponseClickToSpeakAudio();
      const nonMultipleChoiceResponsesWithClickAudio = addNonMultipleChoiceResponseClickToSpeakAudio();
      addDraggableTTSHandler();

      [
        ...stimuliWithAudio,
        ...passageSpansWithAudio,
        ...passageHeadingsWithAudio,
        ...nonMultipleChoiceResponsesWithClickAudio,
        ...multipleChoiceResponsesWithClickAudio
      ].forEach(addTTSClass);

      addTTSEventListeners();
      clearInterval(addTTSClickHandlerInterval);
    }
  }, 500);

  /*
    Transforms the markup and adds a click event listener for passage heading sections.
    Clicking on anywhere in the passage heading section should trigger play for itself, followed
    by playing through each next section until the end, or stopped by the user.

    *Before*
    div.lrn_sharedpassage
      h3.lrn-feature-heading

    *After*
    div.lrn_sharedpassage
      div
        button
        h3.lrn-feature-heading
  */
  function createPassageHeadingPlayAllSections(passageSpansWithAudio) {
    const passageHeadings = document.querySelectorAll(generateAudioContentSelector(c.PASSAGE_HEADING_SELECTOR)); // prettier-ignore

    return Array.from(passageHeadings, passageHeadingElement => {
      const passageHeadingSection = document.createElement("div");
      passageHeadingSection.classList.add(c.PASSAGE_HEADING_SECTION_CLASS);

      const range = document.createRange();
      range.selectNode(passageHeadingElement);
      range.surroundContents(passageHeadingSection);

      const button = createTTSAudioButtonElement(
        c.QUIZ_AUDIO_PASSAGE_HEADING_SECTION_TTS_BUTTON_CLASS
      );

      passageHeadingSection.prepend(button);
      passageHeadingSection.addEventListener(
        "click",
        generateClickToSpeakHandler(tts, [
          passageHeadingSection,
          ...Array.from(passageSpansWithAudio)
        ])
      );

      return passageHeadingSection;
    });
  }

  /*
    Transforms the markup and adds a click event listener for each passage paragraph section.
    Clicking on anywhere in the paragraph section should trigger play for that section (or
    stop if currently playing that section).

    *Before*
    div
      div
        p
        p

    *After*
    div
      div
        section
          button
          p
        section
          button
          p

  */
  function createPassageSpanAudioSections() {
    const paragraphs = document.querySelectorAll(
      generateAudioContentSelector(`${c.PASSAGE_PARAGRAPH_SELECTOR}`)
    );

    return Array.from(paragraphs, paragraphElement => {
      const paragraphSection = document.createElement("section");
      paragraphSection.classList.add(c.PASSAGE_PARAGRAPH_SECTION_CLASS);

      const range = document.createRange();
      range.selectNode(paragraphElement);
      range.surroundContents(paragraphSection);

      const hasTextContent = paragraphSection.textContent !== "";
      if (hasTextContent) {
        const button = createTTSAudioButtonElement(c.QUIZ_AUDIO_PASSAGE_PARAGRAPH_SECTION_TTS_BUTTON_CLASS); // prettier-ignore
        paragraphSection.prepend(button);
        paragraphSection.addEventListener(
          "click",
          generateClickToSpeakHandler(tts, paragraphSection, [c.QUIZ_AUDIO_TTS_ACTIVE_CLASS])
        );
      }

      return paragraphSection;
    });
  }

  /*
    Transforms the markup and adds a click event listener for each stimulus prompt section.
    Clicking on anywhere in the stimulus plays the content for the entire stimulus (or stops if
    currently playing the stimulus).

    *Before*
    div.lrn_stimulus_content
      div
        p

    *After*
    div.lrn_stimulus_content
      button
      div
        p

  */
  function createStimulusAudioSections() {
    const stimulusContents = document.querySelectorAll(
      generateAudioContentSelector(
        c.STIMULUS_SELECTOR,
        `.${c.HAS_HAO_STIMULUS_CLASS} ${c.STIMULUS_SELECTOR}`,
        [`${c.HAS_ALWAYS_SPEAK_SELECTOR} ${c.STIMULUS_SELECTOR}`]
      )
    );

    return Array.from(stimulusContents, stimulusElement => {
      const button = createTTSAudioButtonElement(c.QUIZ_AUDIO_STIMULUS_TTS_BUTTON_CLASS);

      // Sometimes the stimulus in Learnosity is just a text node, this causes issues when TTS is active
      // In the call below we check if the stimulus' element has child nodes that are text nodes, if so we replace them with paragraph nodes
      replaceTextNodesWithSpanNodes(stimulusElement);

      if (stimulusElement.children.length > 1) {
        // Per the comments above, we should only have one child node in the stimulus
        const wrapper = document.createElement("div");
        Array.from(stimulusElement.children, node => node).forEach(childNode => {
          wrapper.appendChild(childNode);
        });
        stimulusElement.appendChild(wrapper);
      }

      stimulusElement.prepend(button);
      stimulusElement.addEventListener("click", generateClickToSpeakHandler(tts, stimulusElement));

      return stimulusElement;
    });
  }

  /*
    Adds a click event listener for each multiple choice option in the response sections.
    Clicking on anywhere in the option plays the content for that option (or stops, if currently
    playing that option).
  */
  function addMultipleChoiceResponseClickToSpeakAudio() {
    const multipleChoiceAnswers = document.querySelectorAll(
      generateAudioContentSelector(
        c.MCQ_ANSWER_SELECTOR,
        `.${c.HAS_HAO_OPTIONS_CLASS} ${c.MCQ_ANSWER_SELECTOR}`,
        [
          `${c.HAS_ALWAYS_SPEAK_SELECTOR} ${c.MCQ_ANSWER_SELECTOR}`,

          // Sometimes we have question answers that do not contain text
          // but instead only an image with an alias. TTS will only announce
          // these if they are the root element passed to `speak`, which is the
          // element the event listener is bound to.
          `${c.MCQ_ANSWER_SELECTOR} img[alias]`
        ]
      )
    );

    return Array.from(multipleChoiceAnswers, answerElement => {
      answerElement.addEventListener(
        "click",
        generateClickToSpeakHandler(tts, answerElement, [c.QUIZ_AUDIO_TTS_ACTIVE_CLASS])
      );

      return answerElement;
    });
  }

  /*
    Adds a click event listener for each classification, cloze text, association, order list question type response sections.
    Clicking only on the text itself will play the audio. We want this only for non multiple choice questions.
  */
  function addNonMultipleChoiceResponseClickToSpeakAudio() {
    const classificationResponseWrappers = document.querySelectorAll(
      generateAudioContentSelector(
        `${c.RESPONSE_WRAPPER_CLASSIFICATION_SELECTOR} ${c.RESPONSE_WRAPPER_SELECTOR}`
      )
    );
    const clozeTextResponseWrappers = document.querySelectorAll(
      generateAudioContentSelector(
        `${c.RESPONSE_WRAPPER_CLOZE_TEXT_SELECTOR} ${c.RESPONSE_WRAPPER_SELECTOR}`
      )
    );
    const associationResponseWrappers = document.querySelectorAll(
      generateAudioContentSelector(
        `${c.RESPONSE_WRAPPER_ASSOCIATION_SELECTOR} ${c.RESPONSE_WRAPPER_SELECTOR}`
      )
    );
    const orderListResponseWrappers = document.querySelectorAll(
      generateAudioContentSelector(
        `${c.RESPONSE_WRAPPER_ORDER_LIST_SELECTOR} ${c.RESPONSE_WRAPPER_SELECTOR}`
      )
    );

    const choiceMatrixResponseWrappers = document.querySelectorAll(
      generateAudioContentSelector(
        `${c.RESPONSE_WRAPPER_CHOICE_MATRIX_SELECTOR} ${c.RESPONSE_WRAPPER_SELECTOR}`
      )
    );

    const responseSectionWrappersList = [
      classificationResponseWrappers,
      clozeTextResponseWrappers,
      associationResponseWrappers,
      orderListResponseWrappers,
      choiceMatrixResponseWrappers
    ];

    let responseElemsWithTTSClickHandlers = [];
    responseSectionWrappersList.forEach(responseSectionWrappers => {
      if (responseSectionWrappers && responseSectionWrappers.length > 0) {
        responseSectionWrappers.forEach(responseSectionWrapperElement => {
          addTTSClickHandler(responseSectionWrapperElement, tts);
          responseElemsWithTTSClickHandlers.push(responseSectionWrapperElement);
        });
      }
    });

    return responseElemsWithTTSClickHandlers;
  }

  function addDraggableTTSHandler() {
    const responseArea = document.querySelector(".lrn_response");
    if (!responseArea) {
      return;
    }

    const draggableHandler = event => {
      // If we can act on the draggable itself, that's most accurate for what to read -- otherwise
      // look to see if the event occurred inside a drop zone.
      const bestTarget =
        event.target.closest(".lrn_draggable") ||
        event.target.closest(".lrn_cloze_response") ||
        event.target.closest(".lrn_possibilityList");
      if (bestTarget) {
        // When an element is being dragged, a temporary DOM element is moving around the page that
        // is different from the element after it's dropped. Hence we wait a moment to query for
        // the final element, so that we do not pick an element not in the DOM.
        setTimeout(() => {
          // if it's been dropped into a zone, filter down to only the dragged element
          const draggedElement = bestTarget.classList.contains("lrn_draggable")
            ? bestTarget
            : bestTarget.querySelector(".lrn_draggable");
          if (draggedElement) {
            tts.speak({ elements: [draggedElement] });
          }
        }, 10);
      }
    };

    responseArea.addEventListener("mouseup", draggableHandler);
    responseArea.addEventListener("touchend", draggableHandler);
  }

  // ******************************************************************
  // TTS event listeners

  function addTTSEventListeners() {
    // References to speech objects
    let collectionGeneratedFromElement;
    let currentlyGeneratedFromElement; // Keep track for managing button states for chunks of content
    let autoplaySpeeches; // Keep track so that we can re-request speech if autoplay fails
    let backupAudioGeneratedNode;

    // Timeouts
    let collectionLoadingIndicatorTimeout;
    let speechLoadingIndicatorTimeout;

    // State
    let isLoading = false;
    let isStopping = false;
    let isEnding = false;
    let isAttemptingAutoplay = false;
    let isPlayingFromBackup = false;

    let audioBackupNode = document.getElementById(BACKUP_AUDIO_NODE_ID);
    const nodeForAppend = document.getElementById(SOUND_ASSET_NODE_ID) || document.body;

    if (!audioBackupNode) {
      audioBackupNode = document.createElement("audio");
      audioBackupNode.id = BACKUP_AUDIO_NODE_ID;
      nodeForAppend.appendChild(audioBackupNode);
    }

    audioBackupNode.addEventListener("playing", () => {
      isPlayingFromBackup = true;
      isLoading = false;
      if (currentlyGeneratedFromElement) {
        backupAudioGeneratedNode = currentlyGeneratedFromElement;
        backupAudioGeneratedNode.classList.add(c.QUIZ_AUDIO_TTS_ACTIVE_CLASS);
        const nodeAudioContent = backupAudioGeneratedNode.querySelector(
          c.TTS_ESPARK_CONTENT_SELECTOR
        );
        if (nodeAudioContent) {
          nodeAudioContent.classList.add(c.TTS_ACTIVE_CLASS);
        }
      }
    });

    audioBackupNode.addEventListener("pause", () => {
      if (!isStopping && isPlayingFromBackup) {
        tts.stop();
      }
      isPlayingFromBackup = false;

      if (backupAudioGeneratedNode) {
        const nodeAudioContent = backupAudioGeneratedNode.querySelector(
          c.TTS_ESPARK_CONTENT_SELECTOR
        );
        if (nodeAudioContent) {
          nodeAudioContent.classList.remove(c.TTS_ACTIVE_CLASS);
        }
        backupAudioGeneratedNode = undefined;
      }
    });

    audioBackupNode.addEventListener("ended", () => {
      if (isPlayingFromBackup) {
        tts.stop();
        isPlayingFromBackup = false;
        if (backupAudioGeneratedNode) {
          const nodeAudioContent = backupAudioGeneratedNode.querySelector(
            c.TTS_ESPARK_CONTENT_SELECTOR
          );
          if (nodeAudioContent) {
            nodeAudioContent.classList.remove(c.TTS_ACTIVE_CLASS);
          }
          backupAudioGeneratedNode = undefined;
        }
      }
    });

    tts.on("speechcollection-started", ({ speeches }) => {
      isStopping = false;
      isEnding = false;

      // FIXME: CA - follow-up and determine if this was fixing something that is still necessary
      // and remove or add something appropriate.
      if (global.Howler && global.Howler.state === "suspended") {
        Howler._autoResume();
      }
      clearAllLoadingAndActive();

      // The first time we get a speechcollection-started event we want to save the reference to
      // the collection so that we can use it to trigger a retry with the same content if we
      // hit an interaction-required event
      autoplaySpeeches = autoplaySpeeches || speeches;
      isLoading = true;

      const firstSpeech = speeches[0];

      if (firstSpeech) {
        collectionGeneratedFromElement = getGeneratedFromElementForSpeech(firstSpeech);
        currentlyGeneratedFromElement = collectionGeneratedFromElement;

        // We want to delay, slightly, applying the loading class on the container of the content
        // so that we don't flash it for a split second even on fast networks.
        collectionLoadingIndicatorTimeout = setTimeout(() => {
          // Ideally the timeout is cleared or isLoading is now `false` set from `speech-started` and we won't
          // end up having to add the loading indicator
          if (isLoading && collectionGeneratedFromElement) {
            collectionGeneratedFromElement.classList.add(c.QUIZ_AUDIO_TTS_LOADING_CLASS);
          }
        }, c.TTS_INITIAL_LOADING_DELAY);
      }
    });

    tts.on("speech-started", ({ speech }) => {
      if (!speech) { return; } // prettier-ignore

      if (isStopping) {
        tts.stop();
        return;
      }

      if (isPlayingFromBackup) {
        isPlayingFromBackup = false;
        audioBackupNode.pause();
      }

      isLoading = false;

      // Since loading is added after a slight delay to avoid flashing of the icon before the first sentence, we need to clear this so
      // that the loading class isn't applied right after we try to remove it.
      // NOTE: clearning between sentence timeouts are handled in `audio-changed`
      clearTimeout(collectionLoadingIndicatorTimeout);

      // We want to clear any loading indicators that occured between sentences, but we do not want to clear active indicators because
      // we want the active indicator (i.e., the stop icon on the button) to remain across sentences.
      clearAllLoadingIndicators();

      const speechGeneratedFromElement = getGeneratedFromElementForSpeech(speech);

      if (speechGeneratedFromElement) {
        // When we're between speeches that are not generated from the same element, but are part of the same speech collection,
        // we want to clear the active state on the previous element.
        // This happens, for example, during autoplay for passages due to passing a selector to the tts library that queries collection of elements that
        // represent paragraphs
        if (speechGeneratedFromElement !== currentlyGeneratedFromElement) {
          clearAllActive();
          currentlyGeneratedFromElement = speechGeneratedFromElement;
        }
        currentlyGeneratedFromElement.classList.add(c.QUIZ_AUDIO_TTS_ACTIVE_CLASS);
      }
    });

    // We're `playing` over an entire speechcollection from the first speech throuugh the last speech, or until stopped
    // We're `speaking` only when we're actually emitting sound.
    // When this triggers between sentences, we need to use the speaking state:
    //
    // speaking [true ->] false to potentially add a loading animation if it's delayed and
    // speaking [false ->] true to remove that loading animation if it was added.
    tts.on("audio-changed", ({ speaking: isSpeaking, playing: isPlaying }) => {
      // If we're no longer playing (and thus, also no longer speaking), then we just hit the
      // speechcollection-ended event and handled anything related to finishing a speech collection there.
      if (!isPlaying) { return; } // prettier-ignore

      // FIXME: We're making use of `isEnding` because sometimes we're getting an audio-changed where
      // playing = true, speaking = false after a speechcollection ends. This shouldn't happen and results
      // in the loading indicator being applied when there is nothing playing unless we override the core logic
      // and always clear the loading state when isEnding = true.
      if (isSpeaking || isEnding) {
        isLoading = false;

        clearTimeout(speechLoadingIndicatorTimeout);
        clearTimeout(collectionLoadingIndicatorTimeout);
        clearAllLoadingIndicators();
      } else {
        isLoading = true;

        // Keep the reference so we can clear it and avoid seeing the loading animation if we start speaking
        // before the delay.
        speechLoadingIndicatorTimeout = setTimeout(() => {
          if (isLoading && currentlyGeneratedFromElement) {
            currentlyGeneratedFromElement.classList.add(c.QUIZ_AUDIO_TTS_LOADING_CLASS);
          }
        }, c.TTS_BETWEEEN_SENTENCE_LOADING_DELAY);
      }
    });

    tts.on("speechcollection-ended", () => {
      isEnding = true;

      clearTimeout(speechLoadingIndicatorTimeout);
      clearTimeout(collectionLoadingIndicatorTimeout);

      clearAllLoadingAndActive();
    });

    tts.on("stop-audio", () => {
      isStopping = true;
      // In general, it makes sense to remove loading/active indicators when we stop playing. However, when
      // we're retrying for autoplay, this event is emitted by other handlers, internal to the TTS library.
      // This avoids flashing icon changes by checking if we're in the middle of a retry loop.
      if (!isAttemptingAutoplay) {
        clearAllLoadingAndActive();
      }

      if (isPlayingFromBackup && audioBackupNode) {
        audioBackupNode.pause();
      }
    });
  }
}

// Helpers
export function addTTSClickHandler(answerElement, tts) {
  // Using mousedown rather than click prevents speaking when a dragged element is dropped into
  // another area
  answerElement.addEventListener("mousedown", event => {
    stopIfPlaying(tts);
    tts.speak({ elements: event.target });
  });
  return answerElement;
}

export function stopIfPlaying(tts) {
  if (tts.isCurrentlyPlaying) {
    tts.stop();
  }
}

// TODO: CA 10/7/19: Follow-up and determine if this is still needed vs just using `speech.generatedFromElement`
// Requires manually testing audio question types.
const getGeneratedFromElementForSpeech = speech => {
  if (!speech || !speech.generatedFromElement) { return } //prettier-ignore

  let element = speech.generatedFromElement;
  if (
    element &&
    element.parentElement &&
    element.parentElement.classList.contains("espark-question-prompt")
  ) {
    element = element.parentElement.parentElement;
  }
  return element;
};

const addTTSClass = element => {
  element.classList.add(c.TTS_QUIZ_AUDIO_TEXT_CLASS);
};

const clearAllActive = () => {
  const activeElements = document.getElementsByClassName(c.QUIZ_AUDIO_TTS_ACTIVE_CLASS);
  Array.from(activeElements, removeClass(c.QUIZ_AUDIO_TTS_ACTIVE_CLASS));
};

const clearAllLoadingIndicators = () => {
  const loadingElements = document.getElementsByClassName(c.QUIZ_AUDIO_TTS_LOADING_CLASS);
  Array.from(loadingElements, removeClass(c.QUIZ_AUDIO_TTS_LOADING_CLASS));
};

const clearAllLoadingAndActive = () => {
  clearAllActive();
  clearAllLoadingIndicators();
};

const createTTSAudioButtonElement = buttonTypeClass => {
  const button = document.createElement("button");
  const icon = document.createElement("i");

  button.appendChild(icon);
  button.classList.add(c.QUIZ_AUDIO_TTS_BUTTON_CLASS);

  if (buttonTypeClass) {
    button.classList.add(buttonTypeClass);
  }

  return button;
};

// Util for the helper functions
const removeClass = className => element => {
  element.classList.remove(className);
};

function generateClickToSpeakHandler(
  tts,
  elements,
  checkClasses = [c.QUIZ_AUDIO_TTS_ACTIVE_CLASS, c.QUIZ_AUDIO_TTS_LOADING_CLASS]
) {
  const fn = function() {
    if (checkClasses.some(className => this.classList.contains(className))) {
      tts.stop();
    } else {
      stopIfPlaying(tts);
      tts.speak({ elements });
    }
  };

  return debounce(fn, 500, { leading: true, trailing: false });
}

import debounce from "lodash/debounce";

import * as dom from "javascript/util/dom";
import * as c from "javascript/Learnosity/util/constants";

const CONTENT_NODE = document.body;

export const windowInfo = () => ({
  viewportHeight: window.innerHeight,
  viewportWidth: window.innerWidth,
  navigationWidth: ifLoaded(document.getElementById(c.ID.MAIN_NAV), nav => nav.offsetWidth, 0),
  height: CONTENT_NODE.scrollHeight,
  scrollTop: window.scrollY,
  scrollPosition: getScrollPosition(CONTENT_NODE)
});

export const scrollContainerTo = scrollYPos => {
  window.scroll({
    top: scrollYPos,
    left: CONTENT_NODE.offsetLeft,
    behavior: "smooth"
  });
};

/**
 * We keep track of the mapping between the original handler, and the
 * debounced handler that we create for binding to the event. This way
 * user's of this API can call "removeEventListeners" with the same function,
 * and don't need to know about the debouncing happening under the hood.
 */
const originalToDebouncedHandlerMap = new Map();

export const addEventListeners = handler => {
  const debouncedHandler = debounce(() => handler(), 60);

  window.addEventListener("scroll", debouncedHandler);
  window.addEventListener("resize", debouncedHandler);
  const contentObserver = tryBindingResizeObserver(debouncedHandler);

  originalToDebouncedHandlerMap.set(handler, { debouncedHandler, contentObserver });

  return debouncedHandler;
};

export const removeEventListeners = handler => {
  const debouncedHandlerInfo = originalToDebouncedHandlerMap.get(handler);
  originalToDebouncedHandlerMap.delete(handler);

  if (!debouncedHandlerInfo) return;

  const { debouncedHandler, contentObserver } = debouncedHandlerInfo;

  window.removeEventListener("scroll", debouncedHandler);
  window.removeEventListener("resize", debouncedHandler);

  if (contentObserver) contentObserver.disconnect();
};

function tryBindingResizeObserver(handler) {
  try {
    const contentObserver = new ResizeObserver(handler);
    contentObserver.observe(CONTENT_NODE);

    return contentObserver;
  } catch (e) {
    // Browser does not support ResizeObserver, so nothing to return.
    return null;
  }
}

function getScrollPosition(content) {
  const scrollTop = window.scrollY;
  const maxScrollTop = content.scrollHeight - window.innerHeight;

  if (scrollTop === 0) {
    return "top";
  } else if (scrollTop >= maxScrollTop - 1) {
    return "bottom";
  } else {
    return "middle";
  }
}

function ifLoaded(maybeElem, callback, defaultResult) {
  if (dom.elemExists(maybeElem)) {
    return callback(maybeElem);
  } else {
    return defaultResult;
  }
}

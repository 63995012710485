import IosNativeInterface from "javascript/IosNativeInterface.js";

// Ports that can talk to iOS
function setupNativeInterfacePorts(ports) {
  ports.openInBrowser.subscribe(url => {
    IosNativeInterface.openInBrowser(url);
  });
}

export default function setupIos(ports, platform) {
  if (!platform.inEsparkIosApp) {
    return;
  }

  setupNativeInterfacePorts(ports);
}

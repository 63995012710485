import { QuestionPreview } from "@espark/evaluate-client";
import { FetchFunctionType, TTS } from "./commonTypes";

let instance: EvaluatePreviewComponent | null = null;
const COMPONENT_RETRY_LIMIT = 5;

export default class EvaluatePreviewComponent {
  fetchFunction: FetchFunctionType;
  tts: any;
  audioEnabled: boolean;
  componentRetryCount = 0;
  retryCallbackId: ReturnType<typeof window.setTimeout> | null = null;

  constructor(fetchFunction: FetchFunctionType, tts: TTS, audioEnabled: boolean) {
    this.fetchFunction = fetchFunction;
    this.audioEnabled = audioEnabled || true;
    this.tts = tts;
    if (instance) {
      console.log("Removing existing instance");
      this.removeInstance();
    }

    instance = this;
    this.componentRetryCount = 0;
    this.displayEvaluateComponent();
  }

  static returnInstance() {
    return instance;
  }

  removeInstance() {
    // This is called in two situations:
    // 1) before navigating away from the component page (see componentRemove port call),
    //    in which case we need to clean up any DOM side effects before navigating.
    // 2) when we get a second call to instantiate the component (see the constructor above),
    //    in which case we want to remove the old one.
    const existingEvaluateContainer = document.querySelector(".evaluate-container");

    if (existingEvaluateContainer) {
      console.log("Removing existing evaluate container");
      existingEvaluateContainer.remove();
    }
    if (instance && instance.retryCallbackId) {
      // shut down any timed retry callbacks on this instance
      clearTimeout(instance.retryCallbackId);
    }
    instance = null;
  }

  displayEvaluateComponent(): void {
    const componentContainer = document.querySelector(".component-container");

    if (!componentContainer) {
      this.handleDisplayRetry();
      return;
    }

    console.log("Displaying evaluate component");
    let evaluateContainer = document.createElement("div");

    evaluateContainer.setAttribute("class", "evaluate-container");
    evaluateContainer.setAttribute("style", "min-height: inherit;");
    componentContainer.appendChild(evaluateContainer);

    const queryString = window.location.search;
    const reviewMode = new URLSearchParams(queryString).get("review");
    new QuestionPreview({
      target: evaluateContainer,
      props: {
        fetchFunction: this.fetchFunction,
        filter: queryString,
        audioEnabled: this.audioEnabled,
        tts: this.tts,
        reviewMode: !!reviewMode
      }
    });
    // Helpers.hideQuizLoader();
  }

  handleDisplayRetry(): void {
    if (this.componentRetryCount < COMPONENT_RETRY_LIMIT) {
      this.retryCallbackId = (window.setTimeout(
        () => this.displayEvaluateComponent(),
        100 + this.componentRetryCount * 100
      ) as unknown) as ReturnType<typeof window.setTimeout>; // fixes bug in setTimeout type definition
      console.log("no container found, retry: " + this.componentRetryCount.toString());
      this.componentRetryCount++;
    } else {
      console.log(
        `no container found, Giving up after ${this.componentRetryCount.toString()} attempts`
      );
    }
    return;
  }
}

import toPairs from "lodash/toPairs";

const DEBUG_SESSION_LOCALSTORAGE_KEY = "v5-session-debug-data";

export default class DebugDataStorage {
  static set(data) {
    try {
      localStorage.setItem(DEBUG_SESSION_LOCALSTORAGE_KEY, data);
    } catch (e) {
      console.error("Invalid debug data", e);
    }
  }

  static get() {
    const storedData = localStorage.getItem(DEBUG_SESSION_LOCALSTORAGE_KEY) || JSON.stringify({});
    try {
      return JSON.parse(storedData);
    } catch (e) {
      console.error("Unparseable debug data", e);
      return {};
    }
  }

  static getAsObject() {
    const { user, tags = [], extra = [] } = DebugDataStorage.get();

    return {
      user,
      tags: toObject(tags),
      extra: toObject(extra)
    };
  }

  static getAndMerge({ user = {}, extra = {}, tags = [] } = {}) {
    const { user: storedUser, tags: storedTags, extra: storedExtra } = DebugDataStorage.get();

    const newUser = user;
    const newTags = toPairs(tags);
    const newExtra = toPairs(extra);

    return {
      user: { ...(storedUser || {}), ...newUser },
      tags: [...(storedTags || []), ...newTags],
      extra: [...(storedExtra || []), ...newExtra]
    };
  }
}

function toObject(pairs) {
  return pairs.reduce((obj, pair) => {
    const [key, value] = pair;

    obj[key] = value;

    return obj;
  }, {});
}

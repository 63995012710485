/*
 * 2022/10/14 AHK: this is needed for as long as we support the deprecated screen for the old
 * eSpark iOS app. All functionality has been stripped out other than what is necessary to send
 * students to the web version.
 */
export default {
  isIpad: function() {
    // if the user has set "request desktop versions, the iOS string may not appear
    // and it doesn't in our app either
    return navigator.userAgent.match(/(iPad|iPhone)/i) !== null || this.isEsparkIosApp();
  },

  isEsparkIosApp: function() {
    // Within our app, we have access to messageHandlers for native interface functions defined by
    // the native code. These don't exist in Mobile Safari.
    return Boolean(
      window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers["set_resume_url"]
    );
  },

  // call the native method with the passed in params. Params will be encoded for you into the URL:
  // IosNativeInterface.callNativeMethod("methodName", "foo", "bar") becomes
  // espark://methodName/foo/bar
  // (with foo and bar encoded appropriately for URLs)
  callNativeMethod: function() {
    var args, method, params, part, url;

    method = arguments[0];
    args = arguments.length >= 2 ? Array.prototype.slice.call(arguments, 1) : [];

    var that = this;
    function callOldMethod() {
      params = (function() {
        var _i, _len, _results;
        _results = [];
        for (_i = 0, _len = args.length; _i < _len; _i++) {
          part = args[_i];
          _results.push(encodeURIComponent(part));
        }
        return _results;
      })().join("/");

      url = "espark://" + method + "/" + params;
      return that.communicateWithNativeApp(url);
    }

    if (window.esparkClientVersion) {
      const versionComponents = window.esparkClientVersion.split(".");
      const majorVersion = parseInt(versionComponents[0]);

      const supportsNewHandler =
        majorVersion > 5 || (majorVersion == 5 && parseInt(versionComponents[1]) > 0);
      const supportsWebkitMessageHandling =
        window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[method];
      if (supportsNewHandler && supportsWebkitMessageHandling) {
        return window.webkit.messageHandlers[method].postMessage(JSON.stringify(args));
      } else {
        callOldMethod();
      }
    } else {
      callOldMethod();
    }
  },

  openInBrowser: function(url) {
    this.callNativeMethod("open_in_safari", url);
  },

  communicateWithNativeApp: function(url) {
    var iframe;
    if (this.isIpad()) {
      iframe = document.createElement("IFRAME");
      iframe.setAttribute("src", url);
      document.documentElement.appendChild(iframe);
      iframe.parentNode.removeChild(iframe);
      return (iframe = null);
    } else if (process.env.LOG_IOS_APP_URLS) {
      console.log("iOS app would have run URL", url);
    }
  }
};

// UserAuth handles all Javascript-based user authentication tasks.
// We'd call this Auth, except that j-toker uses that namespace (for now).
// import Auth from "javascript/auth.js";
import APP_TIMER_LOCALSTORAGE_KEY from "javascript/AppTimerStorage";
import {
  getAuthHeaderFromLocalStorage,
  CREDENTIALS_LOCALSTORAGE_KEY
} from "./util/getAuthHeaderFromLocalStorage";

export function setupAuthPorts(ports) {
  function notifyElmUserSignedOut() {
    // Elm doesn't like ports that don't take a message, so we have to send null
    ports.userSignedOut.send(null);
  }

  // subscribe to Elm Messagse
  ports.signOut.subscribe(fromUserAction => {
    localStorage.removeItem(CREDENTIALS_LOCALSTORAGE_KEY);
    localStorage.removeItem(APP_TIMER_LOCALSTORAGE_KEY);

    notifyElmUserSignedOut();
  });

  // Set and get data about the user session
  ports.storeUserCredentials.subscribe(credentialsString => {
    localStorage.setItem(CREDENTIALS_LOCALSTORAGE_KEY, credentialsString);
  });

  ports.requestStoredUserCredentials.subscribe(() => {
    ports.storedUserCredentialsRetrieved.send(getAuthHeaderFromLocalStorage());
  });
}

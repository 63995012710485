export default class DebugData {
  constructor({ user = {}, tags = [], extra = [] } = {}) {
    this.user = user;
    this.extra = new Pairs(createPairs(extra));

    const tagPairs = new Pairs(createPairs(tags));
    this.tags = Pairs.combine(tagPairs, networkDataPairs());
  }

  forLocalStorage() {
    return JSON.stringify({
      user: this.user,
      tags: this.tags.asArrays(),
      extra: this.extra.asArrays()
    });
  }

  forIosDeviceStorage() {
    return JSON.stringify({
      user: this.user,
      tags: this.tags.asObject(),
      extra: this.extra.asObject()
    });
  }
}

class Pair {
  static fromArray([key, value]) {
    return new Pair(key, value);
  }

  constructor(key, value) {
    this.key = key;
    this.value = value;
  }

  isEmpty() {
    return this.value === null || this.value === undefined;
  }
}

class Pairs {
  static combine(pairs1, pairs2) {
    return new Pairs([...pairs1.pairs, ...pairs2.pairs]);
  }

  constructor(pairs = []) {
    this.pairs = pairs;
  }

  asArrays() {
    return this.pairs.map(pair => [pair.key, pair.value]);
  }

  asObject() {
    return this.pairs.reduce((obj, pair) => {
      obj[pair.key] = pair.value;

      return obj;
    }, {});
  }
}

function networkDataPairs() {
  const navigator = window.navigator || {};
  const connection = navigator.connection || {};

  return new Pairs(
    createPairs([
      ["network_downlink", connection.downlink],
      ["network_effective_type", connection.effectiveType],
      ["network_round_trip_time", connection.rtt],
      ["network_type", connection.type],
      ["network_is_online", navigator.onLine]
    ])
  );
}

function createPairs(items) {
  return items
    .map(item => item && Pair.fromArray(item))
    .filter(Boolean)
    .filter(pair => !pair.isEmpty());
}

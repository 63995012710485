import TTS from "@espark/tts";
import * as c from "../Learnosity/util/constants";
import Sentry from "javascript/Sentry/Wrapper";
import * as ttsErrorLogger from "./ttsErrorLogging";
export const BACKUP_AUDIO_NODE_ID = "tts-backup-audio";
export const SOUND_ASSET_NODE_ID = "sound-assets";
export const AUDIO_ENABLED_SELECTOR = ".audio-enabled";
export const AUDIO_DISABLED_SELECTOR = ".audio-disabled";

export function setupEsparkTTSPorts(
  ports,
  { hostedAudioFileBaseUrl, ttsS3Bucket, ttsApiEndpoint }
) {
  const ttsInitializedAt = Date.now();

  const ttsCounts = {
    start: new Date(),
    total: 0,
    delaysOver10Seconds: 0,
    delaysOver30Seconds: 0,
    delaysOver90Seconds: 0
  };

  const synthesisOptions = {
    engine: "polly",
    // 2022/02/14 AHK: the s3StorageBucket can be removed once we upgrade to TTS 0.10.0 or higher
    s3StorageBucket: ttsS3Bucket,
    hostedAudioFileBaseUrl: hostedAudioFileBaseUrl,
    synthesisEndpoint: ttsApiEndpoint,
    voice: "Matthew",
    pollyEngine: "neural",
    // 2021/03/23 AHK: ssmlProcessor is doing more work than SSML -- should probably be renamed
    // something like `preprocessor`
    ssmlProcessor: (text, voice) => {
      text = text
        // substitute reserved characters with appropriate HTML entities
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&apos;")
        // handle math fractions
        .replace(/frac([A-Z0-9\ +\-()]+?)}(.+?)}/g, "$1 over $2");

      // conversational is only currently available for two voices
      if (voice == "Matthew" || voice == "Joanna") {
        return `<speak><amazon:domain name="conversational"><prosody rate="90%">${text}</prosody></amazon:domain></speak>`;
      } else {
        return `<speak><prosody rate="90%">${text}</prosody></speak>`;
      }
    }
  };

  const ttsOptions = {
    delayBetweenSegmentsInMs: 100,
    synthesis: synthesisOptions,
    aliasContentSelectors: ["[alias]"],
    overrideContentSelectors: ["[data-audio-override]"],
    allowContentSelectors: [
      ".listen-control-button",
      ".espark-text-prompt:not(.listen-button)",
      ".espark-text-passage-content"
    ],
    ignoreContentSelectors: [
      ".elm-overlay",
      ".listen-button-container + [alias]",
      ".sr-only",
      ".do-not-read",
      // content that we want to disable from being read from the Learnosity editor
      "[ssml='sub'][alias=' ']",
      // Learnosity answer buttons after validation
      "input[disabled] + label",
      "[data-audio-override-url] [ssml]"
    ]
  };

  const tts = new TTS(ttsOptions);

  if (process.env.NODE_ENV !== "production") {
    window.tts = tts;
  }

  function stopTTS() {
    // See xhrHook.js for details on audio backup.
    const backupAudio = document.getElementById(BACKUP_AUDIO_NODE_ID);

    if (backupAudio && !backupAudio.paused) {
      backupAudio.muted = true;
      backupAudio.pause();
    }
    tts.stop();
  }

  function ttsPortWrapper(fn) {
    return function() {
      // stop any currently-playing audio
      stopTTS();

      const startTime = Date.now();
      tts.once("speech-started", function() {
        // in order for us to understand how much time it takes for audio to play, track (for audio
        // events that successfully play) how long the gap is between us triggering a play and audio
        // being emitted
        // Per HowlerSpeaker.ts in the TTS library, "speech-started" represents the audio play
        // event, when audio is audible.
        //
        // NOTE: this will only track the latency for the first speech in a collection. (We're
        // currently not tracking this data for sentences following that first one since we'd need
        // to do some math to track when the speech ends and the next begins and subtracting the
        // delayBetweenSegmentsInMs.)
        //
        // As a first pass, we're going to see if we can get enough data just with the first
        // element.
        const duration = Date.now() - startTime;
        ports.trackTimeFromStartToTTSPlay.send(duration);

        ttsCounts.total++;
        if (duration > 10000) {
          ttsCounts.delaysOver10Seconds++;
        }
        if (duration > 30000) {
          ttsCounts.delaysOver30Seconds++;
        }
        if (duration > 90000) {
          ttsCounts.delaysOver90Seconds++;
        }

        Sentry.addBreadcrumb({
          message: "TTSPlayDuration",
          category: "TTS",
          data: {
            duration: duration,
            ...ttsCounts
          },
          level: "debug"
        });
      });

      fn.apply(undefined, arguments);
    };
  }

  function voiceForNodes(nodeList) {
    const nodeWithVoiceData = nodeList[0] && nodeList[0].closest("[data-tts-voice]");
    return nodeWithVoiceData && nodeWithVoiceData.dataset.ttsVoice;
  }

  ports.triggerTTSRetagContent.subscribe(() => {
    window.requestAnimationFrame(() => {
      tts.retagContent();
    });
  });

  ports.triggerTTSRetagAndPlay.subscribe(
    ttsPortWrapper(([containerSelector, voice]) => {
      window.requestAnimationFrame(() => {
        // when the page has changed, the ELm app will trigger a retagging of all the content to see
        // what's now playable/not playable
        tts.retagContent();

        const elements = document.querySelectorAll(containerSelector);
        tts
          .speak({ elements, voice: voiceForNodes(elements) || voice })
          .catch(ttsErrorLogger.errorOnTTSSpeakRetagAndPlay);
      });
    })
  );

  ports.triggerTTSPlayFromNode.subscribe(
    ttsPortWrapper(([containerNode, voice]) => {
      tts
        .speak({ elements: containerNode, voice: voiceForNodes([containerNode]) || voice })
        .catch(ttsErrorLogger.errorOnTTSSpeakPlayFromNode);
    })
  );

  ports.triggerTTSPlaySelector.subscribe(
    ttsPortWrapper(([selectors, voice]) => {
      //TODO: Wrap this up into Learnosity's own TTS handler, it's odd to have these TTS Ports know about MathJax - SS 4/13/2020
      // If we are using MathJax on the page, only speak after mathJax is formatted
      if (global.MathJax) {
        document.addEventListener(
          "mathJaxFormatEnded",
          () => speakOnTriggerTTSPlaySelector(selectors, voice),
          {
            once: true,
            passive: true,
            capture: true
          }
        );
      } else {
        speakOnTriggerTTSPlaySelector(selectors, voice);
      }
    })
  );

  ports.triggerTTSPlayFromText.subscribe(
    ttsPortWrapper(([text, voice]) => {
      tts.speak({ text, voice }).catch(ttsErrorLogger.errorOnTTSSpeakPlayFromText);
    })
  );

  ports.triggerTTSPlayUrl.subscribe(
    ttsPortWrapper(url => {
      tts.speak({ url }).catch(ttsErrorLogger.errorOnTTSSpeakPlayUrl);
    })
  );

  ports.resetTTS.subscribe(() => {
    stopTTS();
    tts.reset();
  });

  ports.switchToHtmlAudio.subscribe(() => {
    tts.updateConfiguration({
      ...ttsOptions,
      synthesis: { ...synthesisOptions, useHtmlAudio: true }
    });
  });

  tts.on("parser-errored", data => {
    Sentry.captureException("TTS Parser Error", {
      level: "warning",
      extra: data.map(record => {
        return {
          paragraph: JSON.stringify(record.paragraph),
          errors: JSON.stringify(record.errors)
        };
      })
    });
  });

  tts.on("error-context-added", data => {
    Sentry.addBreadcrumb({
      category: "TTS",
      data: {
        context: data
      },
      level: "debug"
    });
  });

  tts.on("synthesis-errored", ({ error, data, speech }) => {
    console.warn("Audio synthesis errored!", error, data);
    Sentry.captureMessage("Speech synthesis error", { error, level: "warning" });

    ports.ttsSpeechSynthesisErrored.send({
      speechId: speech.id,
      currentTime: Date.now(),
      error: JSON.stringify(error),
      additionalData: data
    });
  });

  tts.on("audio-errored-after-synthesis", ({ error, data, speech }) => {
    console.warn("Audio errored after synthesis!", error, data);

    ports.ttsAudioErroredAfterSynthesis.send({
      speechId: speech.id,
      currentTime: Date.now(),
      error: JSON.stringify(error),
      additionalData: data
    });
  });

  tts.on("audio-errored", ({ speech, error, isKnownUnlocked }) => {
    console.warn("Audio playback errored!", error, speech, isKnownUnlocked);
    ports.ttsSpeechPlaybackErrored.send({
      speechId: speech.id,
      currentTime: Date.now(),
      error: JSON.stringify(error),
      isKnownUnlocked: isKnownUnlocked
    });
  });

  tts.on("audio-unlocked-after-audio-error", ({ speech, error }) => {
    console.warn("Audio playback errored because locked!", error, speech);
    ports.ttsSpeechPlaybackErroredLocked.send({
      speechId: speech.id,
      currentTime: Date.now(),
      error: JSON.stringify(error)
    });
  });

  tts.on("audio-succeed-after-unlocked", ({ speech }) => {
    console.log("Audio succeeded after unlock!", speech);
    ports.ttsSpeechPlaybackSucceedAfterUnlock.send({
      speechId: speech.id,
      currentTime: Date.now()
    });
  });

  tts.on("audio-finished-loading-after-audio-error", ({ speech, error }) => {
    ports.ttsSpeechLoadedAfterPlayError.send({
      speechId: speech.id,
      currentTime: Date.now(),
      error: JSON.stringify(error)
    });
  });

  tts.on("speech-requested", ({ speech }) => {
    ports.ttsSpeechRequested.send({
      speechId: speech.id,
      currentTime: Date.now()
    });
  });

  tts.on("speech-started", playedEvent => {
    console.log("Speech started with HTML5?", playedEvent.usingHtmlAudio);
    ports.ttsSpeechPlayed.send({
      speechId: playedEvent.speech.id,
      currentTime: Date.now(),
      duration: playedEvent.duration
    });
  });

  tts.on("speech-ended", endedEvent => {
    ports.ttsSpeechEnded.send({
      speechId: endedEvent.speech.id,
      currentTime: Date.now()
    });
  });

  tts.on("speech-stopped", stoppedEvent => {
    ports.ttsSpeechStopped.send({
      speechId: stoppedEvent.speech.id,
      currentTime: Date.now()
    });
  });

  function speakOnTriggerTTSPlaySelector(selectors, voice) {
    const elements = document.querySelectorAll(selectors);
    tts
      .speak({ elements, voice: voiceForNodes(elements) || voice })
      .catch(ttsErrorLogger.errorOnTTSSpeakPlaySelector);
  }

  return tts;
}

import Sentry from "javascript/Sentry/Wrapper";
import * as WindowScroll from "javascript/WindowScroll";

// We track the window focus to know whether to fire notifications.
// Note: in Chrome, whether focus fires seems to depend on whether the user has interacted with the
// page since the last time it gained focus.
export default function setupWindowManagementPorts(ports) {
  window.addEventListener("focus", () => ports.windowFocusChanged.send(true));
  window.addEventListener("blur", () => ports.windowFocusChanged.send(false));

  WindowScroll.addEventListeners(() => {
    ports.windowInfoLoaded.send(WindowScroll.windowInfo());
  });

  function reportWindowRegainedOrLostFocus() {
    if (document.visibilityState === "visible") ports.windowRegainedFocus.send(true);
    else ports.windowLostFocus.send(true);
  }

  ports.scrollContainerTo.subscribe(positionY => WindowScroll.scrollContainerTo(positionY));

  ports.openExternalWindow.subscribe(url => {
    if (window._CYPRESS) {
      console.log("In Cypress, otherwise would open window", url);
      return;
    }

    window.addEventListener("visibilitychange", reportWindowRegainedOrLostFocus);

    const newWindow = window.open(url);
    // If window.open fails for some reason, then we will get a null.
    // We can't really do anything with it, so we just return.
    if (newWindow === null) return;

    ports.externalWindowOpened.send(url);
    // for Javascript reasons we can't just pass `newWindow.close` as a function reference, it has to
    // be invoked this way to work properly
    const closeFn = urlToClose => {
      if (urlToClose === url) {
        if (newWindow) {
          newWindow.close();
          ports.externalWindowClosed.send(url);
        } else {
          Sentry.captureException(
            new Error("Tried to close external window, but window reference was null."),
            {
              tags: { caught: "true" },
              extra: { urlToClose }
            }
          );
        }
        ports.closeExternalWindow.unsubscribe(closeFn);
        window.removeEventListener("visibilitychange", reportWindowRegainedOrLostFocus);
      }
    };
    ports.closeExternalWindow.subscribe(closeFn);
  });

  ports.updateWindowTitle.subscribe(title => {
    window.document.title = title;
  });
}
